export const selectSuccessors = (core: cytoscape.Core): boolean => {
  core
    .$(':selected')
    .successors()
    .select()
  return true
}

export const selectPredecessors = (core: cytoscape.Core): boolean => {
  core
    .$(':selected')
    .predecessors()
    .select()
  return true
}

export const deselectEdges = (core: cytoscape.Core): boolean => {
  core.$('edge:selected').deselect()
  return true
}

export const deselectNodes = (core: cytoscape.Core): boolean => {
  core.$('node:selected').deselect()
  return true
}
