import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { useRematchDispatch } from '../../hooks/useRematchDispatcher'
import { RootDispatch } from '../../store'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { LineEndShape, CommandName } from '../../types'
import CommandBtn from '../controls/CommandBtn'

//#region styles
const Container = styled.div`
  display: flex;
  flex-direction: row;
`
//#endregion

interface Props {
  shape: LineEndShape
  arrowCommand: CommandName
  circleCommand: CommandName
  squareCommand: CommandName
  noneCommand: CommandName
}
const ShapePickerMenu: React.FC<Props> = ({
  shape,
  arrowCommand,
  circleCommand,
  squareCommand,
  noneCommand,
}) => {
  const { leavePickEndShape } = useRematchDispatch(
    (d: RootDispatch) => d.editor,
  )
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, leavePickEndShape)
  return (
    <Container ref={ref}>
      <CommandBtn command={arrowCommand} invert={shape === 'triangle'} />
      <CommandBtn command={circleCommand} invert={shape === 'circle'} />
      <CommandBtn command={squareCommand} invert={shape === 'square'} />
      <CommandBtn command={noneCommand} invert={shape === 'none'} />
      <CommandBtn command="cancel pick end shape" />
    </Container>
  )
}

export default ShapePickerMenu
