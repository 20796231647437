import cytoscape from 'cytoscape'
import { UndoRedo, getUR } from '../common/graph'
import { EleDataSnapshotMap } from './undoRedoCommands'

let ur: UndoRedo
let ele: cytoscape.CollectionReturnValue
let originalData: EleDataSnapshotMap

const start = (core: cytoscape.Core, nodeId: string) => {
  ur = getUR(core)
  ele = core.$id(nodeId)
  const data = ele.data()
  originalData = {
    [nodeId]: {
      data: {
        title: data.title,
        width: data.nodeWidth || data.width,
        height: data.nodeHeight || data.height,
      },
    },
  }
  ele.addClass('editing')
}

const set = (title: string, width: number, height: number) => {
  ele.data({
    title,
    width,
    height,
  })
}

const end = () => {
  ele.removeClass('editing')
  if (ele.data('title') !== originalData[ele.id()].data.title) {
    ur.do('set data', {
      firstTime: true,
      eles: ele,
      lastState: originalData,
    })
  }
}
export { start, set, end }
