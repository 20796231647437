import {
  isNodeStyle,
  FontSize,
  HAlign,
  isEdgeStyle,
  CommandName,
  NodeStyle,
  Point,
  GNNode,
} from '../types'
import { commandConfigs } from '../config/commands'
import React from 'react'
import nanoid from 'nanoid'
import { Lightness, theme } from '../config/theme'

let isMac: boolean
export const getIsMac = () => {
  if (typeof isMac === 'undefined')
    isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0
  return isMac
}

export const getCommandConfig = (command: CommandName) => {
  const { action, icon: Icon } = commandConfigs[command]
  return {
    action,
    icon: Icon && <Icon />,
  }
}

export const delay = (ms: number) => new Promise(r => setTimeout(r, ms))
export const lastItem = <T,>(list: T[]): T | null => {
  const n = list.length
  if (n === 0) return null
  return list[n - 1]
}

// copied from react-contenteditable
export function focusAtEnd(el: HTMLElement) {
  // Place the caret at the end of the element
  const target = document.createTextNode('')
  el.appendChild(target)
  // do not move caret if element was not focused
  const isTargetFocused = document.activeElement === el
  if (target !== null && target.nodeValue !== null && isTargetFocused) {
    const sel = window.getSelection()
    if (sel !== null) {
      const range = document.createRange()
      range.setStart(target, target.nodeValue.length)
      range.collapse(true)
      sel.removeAllRanges()
      sel.addRange(range)
    }
    if (el instanceof HTMLElement) el.focus()
  }
}

export const getRangeRect = (): DOMRect | null => {
  const selection = document.getSelection()
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0)
    return range.getBoundingClientRect()
  }
  return null
}

const getTextColorClass = (color: string): string => `c-${color.substr(1)}`
const getEdgeColorClass = (color: string): string => `e-${color.substr(1)}`
const getTextSizeClass = (size: FontSize): string => `text-${size}`
const getTextAlignClass = (align: HAlign): string => `text-${align}`

export const getNodeTextClasses = (data: unknown): string => {
  if (isNodeStyle(data)) {
    return [
      getTextColorClass(data.textColor),
      getTextSizeClass(data.textSize),
      getTextAlignClass(data.textAlign),
    ].join(' ')
  } else {
    console.error(`invalid node style`)
    console.error(data)
    return ''
  }
}

export const getEdgeTextClasses = (data: unknown): string => {
  if (isEdgeStyle(data)) {
    return [
      getTextColorClass(data.textColor),
      getTextSizeClass(data.textSize),
      getTextAlignClass('center'),
      getEdgeColorClass(data.edgeColor),
    ].join(' ')
  } else {
    console.error(`invalid edge style`)
    console.error(data)
    return ''
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getEditingEleClass = (data: any): string | null => {
  return data.editing ? 'editing' : null
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUnfocusClass = (data: any): string | null => {
  return data.unfocus ? 'unfocus' : null
}

export const getManualNodeWidth = (data: NodeStyle): string => {
  // if (data.nodeWidth !== undefined && data.nodeHeight !== undefined) {
  // return `style="width: ${data.nodeWidth}px; height: ${data.nodeHeight}px;"`
  if (data.nodeWidth !== undefined) {
    return `style="width: ${data.nodeWidth}px;"`
  }
  return ''
}

export const validateImage = (f: File): boolean => {
  const validTypes = ['image/jpeg', 'image/png', 'image/gif']
  if (validTypes.indexOf(f.type) === -1) return false

  const maxSizeInBytes = 10e6 // 10MB
  if (f.size > maxSizeInBytes) return false

  return true
}

export const getImgSize = async (f: File): Promise<Point> => {
  return new Promise(resolve => {
    const img = new Image()
    img.src = window.URL.createObjectURL(f)
    img.onload = () => {
      resolve({ x: img.naturalWidth, y: img.naturalHeight })
    }
  })
}

export const createNewNode = (n?: {
  data?: Partial<GNNode['data']>
  position?: Partial<GNNode['position']>
}): GNNode => ({
  data: {
    id: nanoid(),
    title: '',
    width: 16,
    height: 32,
    textColor: theme.hues.gray[Lightness.LIGHT],
    nodeColor: theme.hues.gray[Lightness.DARK],
    borderColor: theme.hues.gray[Lightness.DARK],
    textAlign: 'left',
    textSize: 's',
    ...n?.data,
  },
  position: {
    x: 0,
    y: 0,
    ...n?.position,
  },
})

export const enumKeys = <O extends object, K extends keyof O = keyof O>(
  obj: O,
): K[] => Object.keys(obj).filter(k => Number.isNaN(+k)) as K[]

export const stringEnumIncludes = <O extends object>(
  e: O,
  value: string,
): boolean => (Object.values(e) as string[]).includes(value)
