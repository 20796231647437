import { useEffect } from 'react'

export const useFreezeScroll = (ref: React.RefObject<HTMLElement>) => {
  useEffect(() => {
    const current = ref.current
    if (current) {
      const resetScroll = () => {
        const { scrollLeft, scrollTop } = current
        if (scrollLeft !== 0 || scrollTop !== 0)
          current.scroll(-scrollLeft, -scrollTop)
      }
      current.addEventListener('scroll', resetScroll)
      return () => {
        current.removeEventListener('scroll', resetScroll)
      }
    }
  }, [ref])
}
