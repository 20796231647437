import React, { useMemo, useState, useLayoutEffect } from 'react'
import { Rect, VAlign, HAlign, Point } from '../types'
import {
  emptyRect,
  toRect,
  shrinkRect,
  getDistanceToClampRect,
  addPoints,
} from '../common/geometry'
import { ResizeObserver } from 'resize-observer'

const getAnchorPoint = (rect: Rect, hAlign: HAlign, vAlign: VAlign): Point => {
  let x, y
  switch (hAlign) {
    case 'left':
      x = rect.x
      break
    case 'center':
      x = rect.x + rect.w / 2
      break
    case 'right':
      x = rect.x + rect.w
      break
  }
  switch (vAlign) {
    case 'top':
      y = rect.y
      break
    case 'bottom':
      y = rect.y + rect.h
      break
  }
  return { x, y }
}

interface UseClampedPositionOptions {
  ref: React.RefObject<HTMLElement>
  viewportRect: Rect
  selectionRect: Rect
  vAlign?: VAlign
  hAlign?: HAlign
  padding?: number
  offset?: number
}
export const useClampedPosition = (
  options: UseClampedPositionOptions,
): React.CSSProperties => {
  const { ref, viewportRect: editorRect, selectionRect } = options
  const hAlign = options.hAlign || 'center'
  const vAlign = options.vAlign || 'top'
  const padding = options.padding || 16
  const offset = options.offset || 0

  const anchor = useMemo(() => {
    return getAnchorPoint(selectionRect, hAlign, vAlign)
  }, [selectionRect, hAlign, vAlign])

  const [position, setPosition] = useState<Point>(anchor)
  const [rect, setRect] = useState<Rect>(emptyRect())
  const safeRect = useMemo(
    () =>
      shrinkRect(
        {
          x: 0,
          y: 0,
          w: editorRect.w,
          h: editorRect.h,
        },
        {
          left: padding,
          right: padding,
          top: padding,
          bottom: padding + 72, // offset for viewport controls
        },
      ),
    [editorRect, padding],
  )

  useLayoutEffect(() => {
    const current = ref.current
    if (current) {
      const ro = new ResizeObserver(e => {
        setRect(toRect(e[0].target.getBoundingClientRect()))
      })
      ro.observe(current)
      return () => {
        ro.unobserve(current)
      }
    }
  }, [ref, setRect])

  useLayoutEffect(() => {
    let { x, y } = anchor
    if (hAlign === 'center') x -= rect.w / 2
    if (hAlign === 'right') x -= rect.w
    if (vAlign === 'top') y -= rect.h

    if (vAlign === 'top') y -= offset
    else y += offset

    const targetRect: Rect = {
      x,
      y,
      w: rect.w,
      h: rect.h,
    }
    const d = getDistanceToClampRect(targetRect, safeRect)
    setPosition(addPoints(targetRect, d))
  }, [anchor, rect, safeRect, hAlign, vAlign, offset, setPosition])

  const style = useMemo(
    () => ({
      transform: `translate(${position.x}px, ${position.y}px)`,
    }),
    [position],
  )
  return style
}
