import styled from '../config/theme'
export const NoteMenuButton = styled.div`
  padding: 4px 8px;
  cursor: pointer;
  border-radius: ${props => props.theme.borderRadius};
  &:hover {
    color: white;
    background: ${props => props.theme.colors.highlight};
  }
  & + & {
    margin-top: 4px;
  }
`
