import { init, RematchDispatch, RematchRootState } from '@rematch/core'
import immerPlugin from '@rematch/immer'

import { models, RootModel } from './models'

export const store = init({
  plugins: [immerPlugin()],
  models,
})

export type Store = typeof store
export type RootDispatch = RematchDispatch<RootModel>
export type RootState = RematchRootState<RootModel>
