import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { useRematchDispatch } from '../../hooks/useRematchDispatcher'
import { RootDispatch, RootState } from '../../store'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useSelector } from 'react-redux'
import CommandBtn from '../controls/CommandBtn'

//#region styles
const Container = styled.div`
  display: flex;
  flex-direction: row;
`
//#endregion

const TextAlignMenu: React.FC = () => {
  const { textAlign } = useSelector((s: RootState) => s.selection)
  const { leavePickTextAlign } = useRematchDispatch(
    (d: RootDispatch) => d.editor,
  )
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, leavePickTextAlign)
  return (
    <Container ref={ref}>
      <CommandBtn command="pick left" invert={textAlign === 'left'} />
      <CommandBtn command="pick center" invert={textAlign === 'center'} />
      <CommandBtn command="pick right" invert={textAlign === 'right'} />
      <CommandBtn command="cancel pick text align" />
    </Container>
  )
}

export default TextAlignMenu
