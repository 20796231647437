import React from 'react'
import styled from '../config/theme'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import Tooltip from 'rc-tooltip'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & * {
    margin-right: 8px;
  }
`

// const Name = styled.span`
//   color: ${props => props.theme.colors.subtext};
//   user-select: none;
// `

const Avatar = styled.img`
  margin-left: 8px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const UserInfo: React.FC = () => {
  const { user } = useSelector((s: RootState) => s.auth)
  return (
    <Container>
      {/* <Name>{user?.name}</Name> */}
      <Tooltip
        arrowContent={null}
        mouseEnterDelay={0.1}
        mouseLeaveDelay={0}
        placement="left"
        trigger={['hover']}
        overlay={'Log out'}
      >
        <Avatar
          referrerPolicy="no-referrer"
          src={user?.avatar}
          onClick={() => {
            firebase.auth().signOut()
            window.location.assign('/')
          }}
        />
      </Tooltip>
    </Container>
  )
}

export default UserInfo
