import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { CommandName } from '../../types'
import { ReactComponent as LineStyleNormalIcon } from '../../icons/line-style-normal.svg'
import { ReactComponent as LineStyleThickIcon } from '../../icons/line-style-thick.svg'
import { ReactComponent as LineStyleDashIcon } from '../../icons/line-style-dash.svg'
import CommandBtn from './CommandBtn'

const COMMAND: CommandName = 'line style'
const CommandLineStyleBtn: React.FC = () => {
  const { lineStyle } = useSelector((s: RootState) => s.selection)
  const icon = useMemo(() => {
    switch (lineStyle) {
      case null:
      case 'normal':
        return <LineStyleNormalIcon />
      case 'dash':
        return <LineStyleDashIcon />
      case 'thick':
        return <LineStyleThickIcon />
    }
  }, [lineStyle])
  return <CommandBtn command={COMMAND}>{icon}</CommandBtn>
}

export default CommandLineStyleBtn
