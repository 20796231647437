import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { selectCurrentContext } from '../../models/editor'
import BtnGroup from './BtnGroup'
import CommandBtn from './CommandBtn'
import { useTheme } from 'emotion-theming'
import { Theme } from '../../config/theme'
import { css } from '@emotion/core'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 16px;
  bottom: 16px;
  & > div + div {
    margin-left: 16px;
  }
`

const ViewportControls: React.FC = () => {
  const context = useSelector(selectCurrentContext)
  const theme = useTheme<Theme>()
  const zoom = useSelector((s: RootState) => s.viewport.zoom)
  const zoomDisplay = `${Math.floor(zoom * 100)}%`
  const panBtn = useMemo(() => {
    return context === 'panning' ? (
      <CommandBtn command={'leave pan mode'} invert />
    ) : (
      <CommandBtn command={'enter pan mode'} />
    )
  }, [context])
  return (
    <Container>
      <BtnGroup>{panBtn}</BtnGroup>
      <BtnGroup>
        <CommandBtn command={'zoom out'} />
        <CommandBtn
          command={'reset zoom'}
          innerCss={css`
            font-size: 1.1em;
            min-width: 60px;
            line-height: ${theme.sizes.buttonSize};
            text-align: center;
          `}
        >
          {zoomDisplay}
        </CommandBtn>
        <CommandBtn command={'zoom in'} />
      </BtnGroup>
      <BtnGroup>
        <CommandBtn command={'zoom to selection'} />
        <CommandBtn command={'focus selection'} />
      </BtnGroup>
    </Container>
  )
}

export default ViewportControls
