import { RematchDispatcher } from '@rematch/core'
import { ResizeObserver } from 'resize-observer'
import { useEffect, useCallback } from 'react'
import { throttle } from 'lodash'
import { Rect } from '../types'
import { toRect } from '../common/geometry'
import { ResizeObserverEntry } from 'resize-observer/lib/ResizeObserverEntry'

export const useWatchSize = (
  ref: React.RefObject<HTMLElement>,
  set: RematchDispatcher<Rect, void>,
): void => {
  const setRect = useCallback(
    throttle((entries: ResizeObserverEntry[]) => {
      const rect = toRect(entries[0].target.getBoundingClientRect())
      set(rect)
    }, 16),
    [set],
  )

  useEffect(() => {
    const ele = ref.current
    if (ele) {
      const ro = new ResizeObserver(setRect)
      ro.observe(ele)
      return () => {
        ro.unobserve(ele)
      }
    }
  }, [ref, setRect])
}
