import cytoscape from 'cytoscape'
import { EleDataSnapshotMap } from './undoRedoCommands'
import { getUR } from '../common/graph'
import { InvalidCommandStateError, EdgeData } from '../types'

let core: cytoscape.Core | undefined
let edges: cytoscape.CollectionReturnValue | undefined
let originalData: EleDataSnapshotMap

const getSelectedDataSnapshot = (
  eles: cytoscape.CollectionReturnValue,
): EleDataSnapshotMap => {
  return eles.reduce((acc, ele) => {
    const id = ele.id()
    acc[id] = {
      data: {
        bend: ele.data('bend'),
        ctrlDist: ele.data('ctrlDist'),
        ctrlWeight: ele.data('ctrlWeight'),
      },
    }
    return acc
  }, {} as EleDataSnapshotMap)
}

const start = (_core: cytoscape.Core) => {
  core = _core
  edges = core.$('edge:selected')
  originalData = getSelectedDataSnapshot(edges)
  edges.data({ bend: true })

  core.boxSelectionEnabled(false)
  core.autoungrabify(true)
  core.autounselectify(true)
}

const set = ({
  ctrlDist,
  ctrlWeight,
}: Pick<EdgeData, 'ctrlDist' | 'ctrlWeight'>) => {
  if (!edges) throw new InvalidCommandStateError()
  if (edges.data)
    edges.data({
      ctrlDist,
      ctrlWeight,
    })
}

const reset = () => {
  if (!edges) throw new InvalidCommandStateError()
  if (edges.data)
    edges.data({ bend: undefined, ctrlDist: '0', ctrlWeight: '0.5' })
}

const end = () => {
  if (!core || !originalData || !edges) throw new InvalidCommandStateError()
  const ur = getUR(core)
  ur.do('set data', {
    firstTime: true,
    eles: edges,
    lastState: originalData,
  })

  core.boxSelectionEnabled(true)
  core.autounselectify(false)
  core.autoungrabify(false)
}

export { start, set, reset, end }
