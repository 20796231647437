import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { useRematchDispatch } from '../../hooks/useRematchDispatcher'
import { RootDispatch, RootState } from '../../store'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useSelector } from 'react-redux'
import CommandBtn from '../controls/CommandBtn'

//#region styles
const Container = styled.div`
  display: flex;
  flex-direction: row;
`
//#endregion

const TextSizeMenu: React.FC = () => {
  const { textSize } = useSelector((s: RootState) => s.selection)
  const { leavePickTextSize } = useRematchDispatch(
    (d: RootDispatch) => d.editor,
  )
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, leavePickTextSize)
  return (
    <Container ref={ref}>
      <CommandBtn command="pick small" invert={textSize === 's'} />
      <CommandBtn command="pick medium" invert={textSize === 'm'} />
      <CommandBtn command="pick large" invert={textSize === 'l'} />
      <CommandBtn command="pick extra large" invert={textSize === 'xl'} />
      <CommandBtn command="cancel pick text size" />
    </Container>
  )
}

export default TextSizeMenu
