import React from 'react'
import styled from '@emotion/styled'
import BtnGroup from './BtnGroup'
import SaveBtn from './SaveBtn'
import CommandBtn from './CommandBtn'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 16px;
  bottom: 16px;
  & > div + div {
    margin-left: 16px;
  }
`

const DocumentMenu: React.FC = () => {
  const { showKeybinds } = useSelector((s: RootState) => s.editor)
  return (
    <Container>
      <BtnGroup>
        <SaveBtn />
        <CommandBtn command={'export'} />
        <CommandBtn command={'add nodes'} />
        <CommandBtn command={'undo'} />
        <CommandBtn command={'redo'} />
        <CommandBtn command={'toggle show keybinds'} invert={showKeybinds} />
      </BtnGroup>
    </Container>
  )
}

export default DocumentMenu
