import { forEach, nth } from 'lodash'
import {
  ContextName,
  SelectionType,
  CommandBinding,
  KeyBinding,
  HAlign,
  FontSize,
  EdgeRelationship,
  LineEndShape,
  LineStyle,
  EdgeData,
  Point,
} from '../types'
import { History } from 'history'
import { commandConfigs } from '../config/commands'
import { RootDispatch, RootState } from '../store'
import { contextConfigs } from '../config/contexts'
import cytoscape from 'cytoscape'
import {
  createCore,
  requireCore,
  getUR,
  getCB,
  resetCore,
  lockCore,
  unlockCore,
  requireAuth,
} from '../common/graph'
import Mousetrap, { ExtendedKeyboardEvent } from 'mousetrap'
import { Hue, Lightness } from '../config/theme'
import {
  exportGraph,
  isStoredState,
  StoredState,
  selectStoredState,
  saveNote,
  saveNoteTitle,
  getUserData,
  createNote,
  getNoteData,
  deleteNote,
  getPublicNoteUid,
  makeNotePublic,
  makeNotePrivate,
  uploadFile,
} from '../common/storage'
import { AddRemoveArg, EleDataSnapshotMap } from '../commands/undoRedoCommands'
import * as addNodes from '../commands/addNodes'
import * as focusQueue from '../commands/focusQueue'
import * as pickColor from '../commands/pickColor'
import * as pickAlign from '../commands/pickAlign'
import * as pickTextSize from '../commands/pickTextSize'
import * as connectNodes from '../commands/connectNodes'
import * as redirectEdges from '../commands/redirectEdges'
import * as styleClipboard from '../commands/styleClipboard'
import * as pickEndShape from '../commands/pickEndShape'
import * as pickLineStyle from '../commands/pickLineStyle'
import * as adjustCurve from '../commands/adjustCurve'
import * as clipNode from '../commands/clipNode'
import { selectNoteMetadata } from './auth'
import { getImgSize, createNewNode } from '../common/util'
import {
  forceLayout,
  columnLayout,
  rowLayout,
  treeLayout,
  gridLayout,
  expandLayout,
} from '../graphLayouts'
import {
  splitSelectedNode,
  joinSelectedNodes,
  splitNodeEdit,
} from '../nodeManipulation'
import {
  selectSuccessors,
  selectPredecessors,
  deselectNodes,
  deselectEdges,
} from '../graphSelection'
import { focusSelection } from '../nodeFocus'

//#region contexts and keybind functions
export const bind = (kb: KeyBinding, action: Function): void => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fn = (e: ExtendedKeyboardEvent) => {
    e.preventDefault()
    if (e.repeat) return false
    // console.log(`[${combo} ${e.type}]`)
    action()
  }
  if (kb.type === 'keypress') Mousetrap.bind(kb.combo, fn)
  else Mousetrap.bind(kb.combo, fn, kb.type)
  if (kb.combo.includes('meta')) {
    const winCombo = kb.combo.replace(/meta/g, 'ctrl')
    if (kb.type === 'keypress') Mousetrap.bind(winCombo, fn)
    else Mousetrap.bind(winCombo, fn, kb.type)
  }
}
export const unbind = (kb: KeyBinding): void => {
  if (kb.type === 'keypress') Mousetrap.unbind(kb.combo)
  else Mousetrap.unbind(kb.combo, kb.type)
  if (kb.combo.includes('meta')) {
    const winCombo = kb.combo.replace(/meta/g, 'ctrl')
    if (kb.type === 'keypress') Mousetrap.unbind(winCombo)
    else Mousetrap.unbind(winCombo, kb.type)
  }
}
export const selectCurrentContext = (s: RootState) =>
  nth(s.editor.contextStack, -1) || 'empty'
export const selectPreviousContext = (s: RootState) =>
  nth(s.editor.contextStack, -2) || 'empty'
export const getVariantCommands = (
  context: ContextName,
  variant: SelectionType | null,
): CommandBinding[] => {
  const config = contextConfigs[context]
  if (variant) {
    const variantCommands = config.variants[variant]
    if (variantCommands) return variantCommands
  }
  return []
}
export const getAllCommands = (
  context: ContextName,
  variant: SelectionType | null,
): CommandBinding[] => {
  const config = contextConfigs[context]
  return [...config.commands, ...getVariantCommands(context, variant)]
}
export const bindCommands = (
  bindings: CommandBinding[],
  d: RootDispatch,
): void => {
  forEach(bindings, cb => {
    if (cb.keyBinding)
      bind(cb.keyBinding, () => {
        commandConfigs[cb.command].action(d)
      })
  })
}
export const bindFullContext = (
  name: ContextName,
  variant: SelectionType,
  d: RootDispatch,
): void => {
  bindCommands(getAllCommands(name, variant), d)
}
export const bindVariant = (
  name: ContextName,
  variant: SelectionType,
  d: RootDispatch,
): void => {
  bindCommands(getVariantCommands(name, variant), d)
}
export const unbindCommands = (bindings: CommandBinding[]): void => {
  forEach(bindings, cb => {
    if (cb.keyBinding) unbind(cb.keyBinding)
  })
}
export const unbindVariant = (
  name: ContextName,
  variant: SelectionType,
): void => {
  unbindCommands(getVariantCommands(name, variant))
}
// cb param is to override default action of just pushing the context
// to the stack (ex: if the other action already does that itself)
export const enterContext = (
  name: ContextName,
  variant: SelectionType,
  d: RootDispatch,
  cb?: (context: ContextName) => void,
): void => {
  Mousetrap.reset()
  bindFullContext(name, variant, d)
  if (!cb) d.editor.pushContext(name)
  else cb(name)
}
export const leaveContext = (
  variant: SelectionType,
  contextStack: ContextName[],
  d: RootDispatch,
): void => {
  Mousetrap.reset()
  const nextContext = nth(contextStack, -2)
  if (nextContext) bindFullContext(nextContext, variant, d)
  d.editor.popContext()
}
//#endregion

interface EditorState {
  core: cytoscape.Core | null
  initialized: boolean
  contextStack: ContextName[]
  shouldNotifySave: boolean
  addMore: boolean
  showKeybinds: boolean
  dirty: boolean
  shouldNotifyLogin: boolean
  title: string
  viewMode: boolean
  nid: string | null
  isEditingTitle: boolean
  editingTitle: boolean
  isPublic: boolean
}
const initialState: EditorState = {
  core: null as cytoscape.Core | null,
  initialized: false,
  contextStack: ['empty'] as ContextName[],
  shouldNotifySave: false,
  addMore: false,
  showKeybinds: true,
  dirty: false,
  shouldNotifyLogin: false,
  title: 'Untitled',
  viewMode: false,
  nid: null as string | null,
  isEditingTitle: false,
  editingTitle: false,
  isPublic: false,
}

export interface EditorModel {
  state: EditorState
  reducers: {
    doneInitializing: (
      s: EditorState,
      p: { core: cytoscape.Core; context: ContextName },
    ) => EditorState
    pushContext: (s: EditorState, p: ContextName) => EditorState
    popContext: (s: EditorState) => EditorState
    setShouldNotifySave: (s: EditorState, p: boolean) => EditorState
    setAddMore: (s: EditorState, p: boolean) => EditorState
    toggleShowKeybinds: (s: EditorState) => EditorState
    setDirty: (s: EditorState) => EditorState
    setClean: (s: EditorState) => EditorState
    setShouldNotifyLogin: (s: EditorState, p: boolean) => EditorState
    setTitle: (s: EditorState, p: string) => EditorState
    setViewModeValue: (s: EditorState, p: boolean) => EditorState
    setNid: (s: EditorState, p: string | null) => EditorState
    setIsEditingTitle: (s: EditorState, p: boolean) => EditorState
    setIsPublic: (s: EditorState, p: boolean) => EditorState
  }
  effects: (
    d: RootDispatch,
  ) => {
    createImageNode: (
      { f, p }: { f: File; p: Point },
      s: RootState,
    ) => Promise<void>
    setViewMode: (p: boolean, s: RootState) => Promise<void>
    noop: () => Promise<void>
    copy: (_: unknown, s: RootState) => Promise<void>
    paste: (_: unknown, s: RootState) => Promise<void>
    copyStyle: (_: unknown, s: RootState) => Promise<void>
    pasteStyle: () => Promise<void>
    importGraph: (json: unknown) => Promise<void>
    loadGraphData: (p: StoredState, s: RootState) => Promise<void>
    exportGraph: (_: unknown, s: RootState) => Promise<void>
    clearNote: (_: unknown, s: RootState) => Promise<void>
    deleteNote: (history: History, s: RootState) => Promise<void>
    save: (_: unknown, s: RootState) => Promise<void>
    updateTitle: (title: string, s: RootState) => Promise<void>
    createNewNote: (history: History, s: RootState) => Promise<void>
    makeNotePublic: (_: unknown, s: RootState) => Promise<void>
    makeNotePrivate: (_: unknown, s: RootState) => Promise<void>
    openNote: (
      { nid, history }: { nid: string; history: History },
      s: RootState,
    ) => Promise<void>
    initGraph: (payload: HTMLElement, s: RootState) => Promise<void>
    enterPanningMode: (_: unknown, s: RootState) => Promise<void>
    leavePanningMode: (_: unknown, s: RootState) => Promise<void>
    clearSelection: (_: unknown, s: RootState) => Promise<void>
    enterEditTextMode: (_: unknown, s: RootState) => Promise<void>
    focusNextNode: (_: unknown, s: RootState) => Promise<void>
    leaveEditTextMode: (_: unknown, s: RootState) => Promise<void>
    enterPickNodeColor: (_: unknown, s: RootState) => Promise<void>
    enterPickBorderColor: (_: unknown, s: RootState) => Promise<void>
    enterPickTextColor: (_: unknown, s: RootState) => Promise<void>
    enterPickEdgeColor: (_: unknown, s: RootState) => Promise<void>
    leavePickColor: (_: unknown, s: RootState) => Promise<void>
    tryHue: (payload: Hue, s: RootState) => Promise<void>
    tryLightness: (payload: Lightness, s: RootState) => Promise<void>
    pickHue: (payload: Hue, s: RootState) => Promise<void>
    pickLightness: (payload: Lightness, s: RootState) => Promise<void>
    addNewNode: (_: unknown, s: RootState) => Promise<void>
    enterAddNodesMode: (p: EdgeRelationship, s: RootState) => Promise<void>
    grabNewNode: () => Promise<void>
    cancelAddNodes: () => Promise<void>
    dropNewNode: (_: unknown, s: RootState) => Promise<void>
    leaveAddNodesMode: (_: unknown, s: RootState) => Promise<void>
    enterConnectNodesMode: (p: EdgeRelationship, s: RootState) => Promise<void>
    grabNewEdges: () => Promise<void>
    cancelConnectNodes: () => Promise<void>
    commitConnectNodes: (_: unknown, s: RootState) => Promise<void>
    leaveConnectNodesMode: (_: unknown, s: RootState) => Promise<void>
    enterRedirectEdgesMode: (p: EdgeRelationship, s: RootState) => Promise<void>
    cancelRedirectEdges: () => Promise<void>
    commitRedirectEdges: () => Promise<void>
    leaveRedirectEdgesMode: (_: unknown, s: RootState) => Promise<void>
    enterPickTextAlign: (_: unknown, s: RootState) => Promise<void>
    pickTextAlign: (align: HAlign) => Promise<void>
    leavePickTextAlign: (_: unknown, s: RootState) => Promise<void>
    enterPickTextSize: (_: unknown, s: RootState) => Promise<void>
    pickTextSize: (size: FontSize) => Promise<void>
    leavePickTextSize: (_: unknown, s: RootState) => Promise<void>
    enterPickEndShape: (p: EdgeRelationship, s: RootState) => Promise<void>
    pickEndShape: (p: LineEndShape) => Promise<void>
    leavePickEndShape: (_: unknown, s: RootState) => Promise<void>
    enterPickLineStyle: (_: unknown, s: RootState) => Promise<void>
    pickLineStyle: (p: LineStyle) => Promise<void>
    leavePickLineStyle: (_: unknown, s: RootState) => Promise<void>
    enterAdjustCurveMode: (_: unknown, s: RootState) => Promise<void>
    adjustCurve: (p: Pick<EdgeData, 'ctrlDist' | 'ctrlWeight'>) => Promise<void>
    resetAdjustCurve: (_: unknown, s: RootState) => Promise<void>
    leaveAdjustCurveMode: (_: unknown, s: RootState) => Promise<void>
    deleteSelected: (_: unknown, s: RootState) => Promise<void>
    undo: (_: unknown, s: RootState) => Promise<void>
    redo: (_: unknown, s: RootState) => Promise<void>
    toggleManualSize: (_: unknown, s: RootState) => Promise<void>
    forceLayout: (_: unknown, s: RootState) => Promise<void>
    columnLayout: (_: unknown, s: RootState) => Promise<void>
    rowLayout: (_: unknown, s: RootState) => Promise<void>
    treeLayout: (_: unknown, s: RootState) => Promise<void>
    gridLayout: (_: unknown, s: RootState) => Promise<void>
    enterLayoutMode: (_: unknown, s: RootState) => Promise<void>
    leaveLayoutMode: (_: unknown, s: RootState) => Promise<void>
    expandLayout: (_: unknown, s: RootState) => Promise<void>
    contractLayout: (_: unknown, s: RootState) => Promise<void>
    splitNode: (_: unknown, s: RootState) => Promise<void>
    joinNodes: (_: unknown, s: RootState) => Promise<void>
    selectSuccessors: (_: unknown, s: RootState) => Promise<void>
    selectPredecessors: (_: unknown, s: RootState) => Promise<void>
    deselectNodes: (_: unknown, s: RootState) => Promise<void>
    deselectEdges: (_: unknown, s: RootState) => Promise<void>
    enterModifySelectionMode: (_: unknown, s: RootState) => Promise<void>
    leaveModifySelectionMode: (_: unknown, s: RootState) => Promise<void>
    focusSelection: (_: unknown, s: RootState) => Promise<void>
    clipNode: (text: string, s: RootState) => Promise<void>
    splitNodeEdit: (_: unknown, s: RootState) => Promise<void>
    bookmarkNode: (
      bookmark: { text: string; url: string },
      s: RootState,
    ) => Promise<void>
    openLink: (_: unknown, s: RootState) => Promise<void>
  }
}
export const editor: EditorModel = {
  state: initialState,
  reducers: {
    doneInitializing: (
      s: EditorState,
      p: { core: cytoscape.Core; context: ContextName },
    ): EditorState => {
      s.core = p.core
      s.contextStack.push(p.context)
      s.initialized = true
      return s
    },
    pushContext: (s: EditorState, p: ContextName): EditorState => {
      s.contextStack.push(p)
      return s
    },
    popContext: (s: EditorState): EditorState => {
      s.contextStack.pop()
      return s
    },
    setShouldNotifySave: (s: EditorState, p: boolean): EditorState => {
      s.shouldNotifySave = p
      return s
    },
    setAddMore: (s: EditorState, p: boolean): EditorState => {
      s.addMore = p
      return s
    },
    toggleShowKeybinds: (s: EditorState): EditorState => {
      s.showKeybinds = !s.showKeybinds
      return s
    },
    setDirty: (s: EditorState): EditorState => {
      s.dirty = true
      return s
    },
    setClean: (s: EditorState): EditorState => {
      s.dirty = false
      return s
    },
    setShouldNotifyLogin: (s: EditorState, p: boolean): EditorState => {
      s.shouldNotifyLogin = p
      return s
    },
    setTitle: (s: EditorState, p: string): EditorState => {
      s.title = p
      return s
    },
    setViewModeValue: (s: EditorState, p: boolean): EditorState => {
      s.viewMode = p
      return s
    },
    setNid: (s: EditorState, p: string | null): EditorState => {
      s.nid = p
      return s
    },
    setIsEditingTitle: (s: EditorState, p: boolean): EditorState => {
      s.isEditingTitle = p
      return s
    },
    setIsPublic: (s: EditorState, p: boolean): EditorState => {
      s.isPublic = p
      return s
    },
  },
  effects: (d: RootDispatch) => ({
    async createImageNode({ f, p }: { f: File; p: Point }, s: RootState) {
      const core = requireCore(s)
      const uid = requireAuth(s, 'to upload an image')
      const imgSize = await getImgSize(f)
      const newNode = createNewNode({
        data: {
          title: 'uploading...',
          width: imgSize.x,
          height: imgSize.y,
          imageWidth: imgSize.x,
          imageHeight: imgSize.y,
        },
      })
      const node = core.add(newNode)
      node.renderedPosition({ x: p.x, y: p.y })
      uploadFile(
        uid,
        f,
        progress => {
          node.data('title', `${progress}%`)
        },
        () => {
          node.remove()
        },
        url => {
          // console.log(`image uploaded to ${url}`)
          node.data('title', url)
          node.addClass('image')
        },
      )
    },
    async setViewMode(p: boolean, s: RootState) {
      d.editor.setViewModeValue(p)
      const core = requireCore(s)
      if (p) {
        lockCore(core)
        enterContext('view mode', s.selection.type, d)
      } else {
        unlockCore(core)
        const context = selectCurrentContext(s)
        if (context === 'view mode')
          leaveContext(s.selection.type, s.editor.contextStack, d)
      }
    },
    async noop() {
      console.log('noop')
    },
    async copy(_: unknown, s: RootState) {
      const core = requireCore(s)
      const cb = getCB(core)
      cb.copy(core.$(':selected'))
    },
    async paste(_: unknown, s: RootState) {
      const core = requireCore(s)
      const ur = getUR(core)
      ur.do('paste', {
        firstTime: true,
        eles: core.collection(),
      } as AddRemoveArg)
    },
    async copyStyle(_: unknown, s: RootState) {
      styleClipboard.copy(requireCore(s))
    },
    async pasteStyle() {
      styleClipboard.paste()
      setTimeout(() => {
        d.selection.updateSelectionRect()
      }, 0)
    },
    async importGraph(json: unknown) {
      if (isStoredState(json)) {
        d.editor.loadGraphData(json)
      } else {
        alert('Not a valid Graphnote file')
        console.error('Unable to import, invalid json file')
      }
    },
    async loadGraphData(p: StoredState, s: RootState) {
      const core = requireCore(s)
      core.batch(() => {
        core.add(p.elements)
        core.zoom(p.zoom)
        core.pan(p.pan)
      })
    },
    async exportGraph(_: unknown, s: RootState) {
      exportGraph(s)
    },
    async clearNote(_: unknown, s: RootState) {
      const core = requireCore(s)
      core.$('*').select()
      d.editor.deleteSelected()
    },
    async deleteNote(history: History, s: RootState) {
      // console.log(`delete note`)
      if (!s.auth.user)
        throw new Error('tried to update title with no user logged in')
      const { uid } = s.auth.user
      const { nid } = s.editor
      if (!nid) throw new Error('tried to update title with no note id')

      // get the note metas except the one we're about to delete
      const noteMetas = selectNoteMetadata(s).filter(meta => meta.id !== nid)
      let nextNid
      let nextTitle = 'Untitled'
      let isPublic = false
      const core = requireCore(s)
      resetCore(core)
      if (noteMetas.length > 0) {
        nextNid = noteMetas[0].id
        nextTitle = noteMetas[0].title
        const noteData = await getNoteData(uid, nextNid)
        d.editor.loadGraphData(noteData)
        isPublic = (await getPublicNoteUid(nextNid)) !== null
      } else {
        nextNid = await createNote(uid, nextTitle, {
          elements: [],
          zoom: 1,
          pan: { x: 0, y: 0 },
        })
      }
      d.editor.setNid(nextNid)
      d.editor.setTitle(nextTitle)
      d.editor.setIsPublic(isPublic)
      d.editor.setViewMode(false)
      history.push(`/note/${nextNid}`)

      await deleteNote(uid, nid, nextNid)
      const userData = await getUserData(uid)
      d.auth.setUserNotes(userData.notes)
    },
    async save(_: unknown, s: RootState) {
      if (s.auth.user) {
        // console.log(`save for ${s.auth.user.uid}`)
        const data = selectStoredState(s)
        const { uid } = s.auth.user
        const nid = s.editor.nid
        if (!nid) throw new Error(`trying to save note without a ${nid}`)
        await saveNote(uid, nid, data)
        d.editor.setShouldNotifySave(true)
        d.editor.setClean()
      } else {
        // console.log(`not logged in`)
        d.editor.setShouldNotifyLogin(true)
      }
    },
    async updateTitle(title: string, s: RootState) {
      // console.log(`update note title to ${title}`)
      if (!s.auth.user)
        throw new Error('tried to update title with no user logged in')
      const { uid } = s.auth.user
      const { nid } = s.editor
      if (!nid) throw new Error('tried to update title with no note id')
      // update the note metadata
      await saveNoteTitle(uid, nid, title)
      // refetch all the user's data
      const userData = await getUserData(uid)
      // update the state
      d.auth.setUserNotes(userData.notes)
      d.editor.setTitle(title)
      d.editor.setIsEditingTitle(false)
    },
    async createNewNote(history: History, s: RootState) {
      // console.log(`create new note`)
      if (!s.auth.user)
        throw new Error('tried to update title with no user logged in')
      const { uid } = s.auth.user

      const core = requireCore(s)
      resetCore(core)

      const nextTitle = 'Untitled'
      const nextNid = await createNote(uid, nextTitle, {
        elements: [],
        zoom: 1,
        pan: { x: 0, y: 0 },
      })
      d.editor.setNid(nextNid)
      d.editor.setTitle(nextTitle)
      d.editor.setIsPublic(false)
      d.editor.setViewMode(false)
      history.push(`/note/${nextNid}`)

      const userData = await getUserData(uid)
      d.auth.setUserNotes(userData.notes)
    },
    async makeNotePublic(_: unknown, s: RootState) {
      if (!s.auth.user)
        throw new Error('tried to update title with no user logged in')
      const { uid } = s.auth.user
      const { nid } = s.editor
      if (!nid) throw new Error('tried to update title with no note id')
      makeNotePublic(uid, nid)
      d.editor.setIsPublic(true)
    },
    async makeNotePrivate(_: unknown, s: RootState) {
      const { nid } = s.editor
      if (!nid) throw new Error('tried to update title with no note id')
      makeNotePrivate(nid)
      d.editor.setIsPublic(false)
    },
    async openNote(
      { nid, history }: { nid: string; history: History },
      s: RootState,
    ) {
      // console.log(`open note ${nid}`)
      if (!s.auth.user)
        throw new Error('tried to open note with no user logged in')
      const { uid } = s.auth.user

      const core = requireCore(s)
      resetCore(core)

      const noteMeta = s.auth.noteMetaById[nid]
      if (!noteMeta)
        throw new Error(
          'tried to load a note that we are missing the metadata for',
        )
      const noteData = await getNoteData(uid, nid)
      const isPublic = (await getPublicNoteUid(nid)) !== null
      d.editor.loadGraphData(noteData)
      d.editor.setNid(nid)
      d.editor.setTitle(noteMeta.title)
      d.editor.setIsPublic(isPublic)
      d.editor.setViewMode(false)
      history.push(`/note/${nid}`)
    },
    async initGraph(payload: HTMLElement, s: RootState) {
      // const storedState = getStateFromStorage()
      const core = await createCore({
        // storedState,
        container: payload,
        updateSelection: d.selection.updateSelection,
        updateSelectionRect: d.selection.updateSelectionRect,
        updateSelectionData: d.selection.updateSelectionData,
        clearSelectionRect: () => {
          d.selection.setRect(null)
        },
        setZoom: d.viewport.setZoom,
        setPan: d.viewport.setPan,
        save: d.editor.save,
        setDirty: d.editor.setDirty,
        addNewNode: () => {
          d.editor.addNewNode()
        },
      })
      enterContext('graph selection', s.selection.type, d, context => {
        d.editor.doneInitializing({ core, context })
      })
      // if (s.editor.showKeybinds !== storedState.showKeybinds)
      //   d.editor.toggleShowKeybinds()
    },
    async enterPanningMode(_: unknown, s: RootState) {
      enterContext('panning', s.selection.type, d)
    },
    async leavePanningMode(_: unknown, s: RootState) {
      leaveContext(s.selection.type, s.editor.contextStack, d)
    },
    async clearSelection(_: unknown, s: RootState) {
      const core = requireCore(s)
      core.$(':selected').unselect()
    },

    //#region text editor
    async enterEditTextMode(_: unknown, s: RootState) {
      enterContext('edit text', s.selection.type, d)
    },
    async focusNextNode(_: unknown, s: RootState) {
      if (focusQueue.next(requireCore(s))) {
        d.textEditor.setDoBlur(true)
        setTimeout(() => {
          d.textEditor.setDoFocus(true)
        }, 20)
      } else {
        d.textEditor.setDoBlur(true)
      }
    },
    async leaveEditTextMode(_: unknown, s: RootState) {
      leaveContext(s.selection.type, s.editor.contextStack, d)
    },
    //#endregion

    //#region color picker
    async enterPickNodeColor(_: unknown, s: RootState) {
      enterContext('pick node color', s.selection.type, d)
      pickColor.start(
        (rs: RootState) => rs.selection.nodeColor || 'white',
        'nodeColor',
        s,
      )
    },
    async enterPickBorderColor(_: unknown, s: RootState) {
      enterContext('pick border color', s.selection.type, d)
      pickColor.start(
        (rs: RootState) => rs.selection.borderColor || 'white',
        'borderColor',
        s,
      )
    },
    async enterPickTextColor(_: unknown, s: RootState) {
      enterContext('pick text color', s.selection.type, d)
      pickColor.start(
        (rs: RootState) => rs.selection.textColor || 'white',
        'textColor',
        s,
      )
    },
    async enterPickEdgeColor(_: unknown, s: RootState) {
      enterContext('pick edge color', s.selection.type, d)
      pickColor.start(
        (rs: RootState) => rs.selection.edgeColor || 'white',
        'edgeColor',
        s,
      )
    },
    async leavePickColor(_: unknown, s: RootState) {
      pickColor.end()
      leaveContext(s.selection.type, s.editor.contextStack, d)
    },
    async tryHue(payload: Hue, s: RootState) {
      pickColor.setHue(payload, s)
    },
    async tryLightness(payload: Lightness, s: RootState) {
      pickColor.setLightness(payload, s)
    },
    async pickHue(payload: Hue, s: RootState) {
      pickColor.setHue(payload, s)
      d.editor.leavePickColor()
    },
    async pickLightness(payload: Lightness, s: RootState) {
      pickColor.setLightness(payload, s)
      d.editor.leavePickColor()
    },
    //#endregion

    //#region add nodes
    async addNewNode(_: unknown, s: RootState) {
      if (
        s.editor.contextStack.length === 2 &&
        s.editor.contextStack[1] === 'graph selection'
      ) {
        d.editor.enterAddNodesMode()
        setTimeout(() => {
          d.editor.dropNewNode()
        }, 20)
      }
    },
    async enterAddNodesMode(p: EdgeRelationship, s: RootState) {
      addNodes.start(p, requireCore(s))
      enterContext('add nodes', s.selection.type, d)
      d.editor.grabNewNode()
    },
    async grabNewNode() {
      addNodes.grabNewNode(d.editor.dropNewNode)
    },
    async cancelAddNodes() {
      addNodes.removeNewNode()
      d.editor.leaveAddNodesMode()
    },
    async dropNewNode(_: unknown, s: RootState) {
      addNodes.commitNewNode(requireCore(s))
      if (s.editor.addMore) d.editor.grabNewNode()
      else d.editor.leaveAddNodesMode()
    },
    async leaveAddNodesMode(_: unknown, s: RootState) {
      const core = requireCore(s)
      leaveContext(s.selection.type, s.editor.contextStack, d)
      const newNodes = addNodes.end(() => {
        // 2nd layer hack to accomodate previous hack
        // we need this to satisfy the triggerRefocus flag
        // in the NodeTextEditor when programatically setting focus
        d.textEditor.setDoBlur(true)
        setTimeout(() => {
          d.textEditor.setDoFocus(true)
        }, 20)
      })
      focusQueue.set(core, newNodes)
    },
    //#endregion

    //#region connect nodes
    async enterConnectNodesMode(p: EdgeRelationship, s: RootState) {
      connectNodes.start(p, requireCore(s))
      enterContext('connect nodes', s.selection.type, d)
      d.editor.grabNewEdges()
    },
    async grabNewEdges() {
      connectNodes.grabNewEdges(d.editor.commitConnectNodes)
    },
    async cancelConnectNodes() {
      connectNodes.cancel()
      d.editor.leaveConnectNodesMode()
    },
    async commitConnectNodes(_: unknown, s: RootState) {
      connectNodes.commit()
      if (s.editor.addMore) d.editor.grabNewEdges()
      else d.editor.leaveConnectNodesMode()
    },
    async leaveConnectNodesMode(_: unknown, s: RootState) {
      leaveContext(s.selection.type, s.editor.contextStack, d)
      const newEdges = connectNodes.end(() => {
        d.textEditor.setDoBlur(true)
        setTimeout(() => {
          d.textEditor.setDoFocus(true)
        }, 20)
      })
      if (newEdges.length > 0) focusQueue.set(requireCore(s), newEdges)
    },
    //#endregion

    //#region set edge endpoint
    async enterRedirectEdgesMode(p: EdgeRelationship, s: RootState) {
      redirectEdges.start(p, requireCore(s), d.editor.commitRedirectEdges)
      enterContext('redirect edges', s.selection.type, d)
    },
    async cancelRedirectEdges() {
      redirectEdges.cancel()
      d.editor.leaveRedirectEdgesMode()
    },
    async commitRedirectEdges() {
      redirectEdges.commit()
      d.editor.leaveRedirectEdgesMode()
    },
    async leaveRedirectEdgesMode(_: unknown, s: RootState) {
      leaveContext(s.selection.type, s.editor.contextStack, d)
      redirectEdges.end()
    },
    //#endregion

    //#region pick align
    async enterPickTextAlign(_: unknown, s: RootState) {
      enterContext('pick text align', s.selection.type, d)
      pickAlign.start(requireCore(s))
    },
    async pickTextAlign(align: HAlign) {
      pickAlign.set(align)
      d.editor.leavePickTextAlign()
    },
    async leavePickTextAlign(_: unknown, s: RootState) {
      pickAlign.end()
      leaveContext(s.selection.type, s.editor.contextStack, d)
    },
    //#endregion

    //#region pick Size
    async enterPickTextSize(_: unknown, s: RootState) {
      enterContext('pick text size', s.selection.type, d)
      pickTextSize.start(requireCore(s))
    },
    async pickTextSize(size: FontSize) {
      pickTextSize.set(size)
      d.editor.leavePickTextSize()
    },
    async leavePickTextSize(_: unknown, s: RootState) {
      pickTextSize.end()
      leaveContext(s.selection.type, s.editor.contextStack, d)
      setTimeout(() => {
        d.selection.updateSelectionRect()
      }, 0)
    },
    //#endregion

    //#region pick end shape
    async enterPickEndShape(p: EdgeRelationship, s: RootState) {
      enterContext(
        p === 'target' ? 'pick target shape' : 'pick source shape',
        s.selection.type,
        d,
      )
      pickEndShape.start(p, requireCore(s))
    },
    async pickEndShape(p: LineEndShape) {
      pickEndShape.commit(p)
      d.editor.leavePickEndShape()
    },
    async leavePickEndShape(_: unknown, s: RootState) {
      pickEndShape.end()
      leaveContext(s.selection.type, s.editor.contextStack, d)
    },
    //#endregion

    //#region pick line style
    async enterPickLineStyle(_: unknown, s: RootState) {
      enterContext('pick line style', s.selection.type, d)
      pickLineStyle.start(requireCore(s))
    },
    async pickLineStyle(p: LineStyle) {
      pickLineStyle.commit(p)
      d.editor.leavePickLineStyle()
    },
    async leavePickLineStyle(_: unknown, s: RootState) {
      pickLineStyle.end()
      leaveContext(s.selection.type, s.editor.contextStack, d)
    },
    //#endregion

    //#region adjust curve
    async enterAdjustCurveMode(_: unknown, s: RootState) {
      adjustCurve.start(requireCore(s))
      setTimeout(() => {
        enterContext('adjust curve mode', s.selection.type, d)
      }, 0)
    },
    async adjustCurve(p: Pick<EdgeData, 'ctrlDist' | 'ctrlWeight'>) {
      adjustCurve.set({ ...p })
    },
    async resetAdjustCurve(_: unknown, s: RootState) {
      adjustCurve.start(requireCore(s))
      adjustCurve.reset()
      adjustCurve.end()
    },
    async leaveAdjustCurveMode(_: unknown, s: RootState) {
      leaveContext(s.selection.type, s.editor.contextStack, d)
      adjustCurve.end()
    },
    //#endregion

    async deleteSelected(_: unknown, s: RootState) {
      const core = requireCore(s)
      const ur = getUR(core)
      const eles = core
        .$(':selected')
        .deselect()
        .remove()
      ur.do('remove eles', {
        firstTime: true,
        eles,
      } as AddRemoveArg)
    },
    async undo(_: unknown, s: RootState) {
      const core = requireCore(s)
      const ur = getUR(core)
      if (!ur.isUndoStackEmpty()) {
        core.$(':selected').deselect()
        ur.undo()
      }
    },
    async redo(_: unknown, s: RootState) {
      const core = requireCore(s)
      const ur = getUR(core)
      if (!ur.isRedoStackEmpty()) {
        core.$(':selected').deselect()
        ur.redo()
      }
    },
    async toggleManualSize(_: unknown, s: RootState) {
      const core = requireCore(s)
      const ur = getUR(core)
      const node = core.$('node:selected')
      const isManualSize =
        s.selection.nodeWidth !== undefined &&
        s.selection.nodeHeight !== undefined
      const lastState: EleDataSnapshotMap = {
        [node.id()]: {
          data: {
            width: node.data('width'),
            height: node.data('height'),
            nodeWidth: node.data('nodeWidth'),
            nodeHeight: node.data('nodeHeight'),
          },
        },
      }
      const isImageNode = node.hasClass('image')
      if (isImageNode) {
        node.data({
          width: node.data('imageWidth'),
          height: node.data('imageHeight'),
          nodeWidth: isManualSize ? undefined : node.data('imageWidth'),
          nodeHeight: isManualSize ? undefined : node.data('imageHeight'),
        })
        d.selection.updateSelectionRect()
      } else {
        node.data({
          nodeWidth: isManualSize ? undefined : node.data('width'),
          nodeHeight: isManualSize ? undefined : node.data('height'),
        })
      }
      ur.do('set data', {
        firstTime: true,
        eles: node,
        lastState,
      })
    },
    async enterLayoutMode(_: unknown, s: RootState) {
      enterContext('pick layout', s.selection.type, d)
    },
    async leaveLayoutMode(_: unknown, s: RootState) {
      leaveContext(s.selection.type, s.editor.contextStack, d)
    },
    async forceLayout(_: unknown, s: RootState) {
      forceLayout(requireCore(s), () => {
        d.selection.updateSelectionRect()
      })
    },
    async columnLayout(_: unknown, s: RootState) {
      columnLayout(requireCore(s), () => {
        d.selection.updateSelectionRect()
      })
    },
    async rowLayout(_: unknown, s: RootState) {
      rowLayout(requireCore(s), () => {
        d.selection.updateSelectionRect()
      })
    },
    async treeLayout(_: unknown, s: RootState) {
      treeLayout(requireCore(s), () => {
        d.selection.updateSelectionRect()
      })
    },
    async gridLayout(_: unknown, s: RootState) {
      gridLayout(requireCore(s), () => {
        d.selection.updateSelectionRect()
      })
    },
    async expandLayout(_: unknown, s: RootState) {
      expandLayout(
        requireCore(s),
        () => {
          d.selection.updateSelectionRect()
        },
        0.2,
      )
    },
    async contractLayout(_: unknown, s: RootState) {
      expandLayout(
        requireCore(s),
        () => {
          d.selection.updateSelectionRect()
        },
        -0.2,
      )
    },
    async splitNode(_: unknown, s: RootState) {
      const core = requireCore(s)
      if (splitSelectedNode(core)) {
        d.selection.updateSelectionRect()
      }
    },
    async splitNodeEdit(_: unknown, s: RootState) {
      const core = requireCore(s)
      const { ref } = s.textEditor
      if (ref === undefined) return
      leaveContext(s.selection.type, s.editor.contextStack, d)
      splitNodeEdit(core, ref)
    },
    async joinNodes(_: unknown, s: RootState) {
      const core = requireCore(s)
      if (joinSelectedNodes(core)) {
        d.selection.updateSelectionRect()
      }
    },
    async enterModifySelectionMode(_: unknown, s: RootState) {
      enterContext('modify selection', s.selection.type, d)
    },
    async leaveModifySelectionMode(_: unknown, s: RootState) {
      leaveContext(s.selection.type, s.editor.contextStack, d)
    },
    async selectSuccessors(_: unknown, s: RootState) {
      const core = requireCore(s)
      if (selectSuccessors(core)) {
        d.selection.updateSelectionRect()
      }
    },
    async selectPredecessors(_: unknown, s: RootState) {
      const core = requireCore(s)
      if (selectPredecessors(core)) {
        d.selection.updateSelectionRect()
      }
    },
    async deselectNodes(_: unknown, s: RootState) {
      const core = requireCore(s)
      if (deselectNodes(core)) {
        d.selection.updateSelectionRect()
      }
    },
    async deselectEdges(_: unknown, s: RootState) {
      const core = requireCore(s)
      if (deselectEdges(core)) {
        d.selection.updateSelectionRect()
      }
    },
    async focusSelection(_: unknown, s: RootState) {
      const core = requireCore(s)
      focusSelection(core)
    },

    // extension
    async clipNode(text: string, s: RootState) {
      clipNode.run(requireCore(s), text)
      d.selection.updateSelection()
      setTimeout(clipNode.reposition, 20)
    },
    async bookmarkNode({ text, url }, s: RootState) {
      clipNode.run(requireCore(s), text, url)
      d.selection.updateSelection()
      setTimeout(clipNode.reposition, 20)
    },
    async openLink(_, s) {
      const core = requireCore(s)
      const sel = core.$('node:selected')
      const { url } = sel.data()
      console.log(url)
      window.postMessage({ type: 'OPEN_LINK', url }, '*')
    },
  }),
}
// export type EditorModel = typeof editor

export const selectNoteTitle = (s: RootState) => s.editor.title
