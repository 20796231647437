import cytoscape from 'cytoscape'
import {
  EdgeRelationship,
  LineEndShape,
  InvalidCommandStateError,
} from '../types'
import { EleDataSnapshotMap } from './undoRedoCommands'
import { getUR } from '../common/graph'

let rel: EdgeRelationship | undefined
let eles: cytoscape.CollectionReturnValue | undefined
let core: cytoscape.Core | undefined
let originalData: EleDataSnapshotMap | undefined

const getAttr = (rel: EdgeRelationship) =>
  rel === 'target' ? 'targetShape' : 'sourceShape'

const getSelectedDataSnapshot = (
  eles: cytoscape.CollectionReturnValue,
): EleDataSnapshotMap => {
  if (!rel) throw new InvalidCommandStateError()
  const attr = getAttr(rel)
  return eles.reduce((acc, ele) => {
    const id = ele.id()
    acc[id] = {
      data: {
        [attr]: ele.data(attr),
      },
    }
    return acc
  }, {} as EleDataSnapshotMap)
}

const start = (_rel: EdgeRelationship, _core: cytoscape.Core) => {
  core = _core
  rel = _rel
  eles = core.$(':selected')
  originalData = getSelectedDataSnapshot(eles)
}

const commit = (shape: LineEndShape) => {
  if (!eles || !rel) throw new InvalidCommandStateError()
  const attr = getAttr(rel)
  eles.data(attr, shape)
}

const end = () => {
  if (!core || !eles || !originalData) throw new InvalidCommandStateError()
  const ur = getUR(core)
  ur.do('set data', { firstTime: true, eles, lastState: originalData })
  rel = undefined
  core = undefined
  eles = undefined
  originalData = undefined
}

export { start, commit, end }
