import styled from '../../config/theme'

const ContextMenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 1px;
  & > div + div {
    margin-left: 1px;
  }
`

export default ContextMenuContainer
