import { Core, LayoutOptions } from 'cytoscape'
import { Point } from './types'
import { getUR, UndoRedo } from './common/graph'
import {
  snapshotNodePositions,
  ElePositionMap,
} from './commands/undoRedoCommands'
import { getCenter, subtractPoints, addPoints } from './common/geometry'

export const recenter = (eles: cytoscape.NodeCollection, c1: Point): void => {
  const bb2 = eles.boundingBox({})
  const c2: Point = getCenter(bb2)
  const c3: Point = subtractPoints(c1, c2)
  eles.forEach(ele => {
    ele.shift(c3)
  })
}

const reposition = (
  ur: UndoRedo,
  eles: cytoscape.CollectionArgument,
  lastState: ElePositionMap,
): void => {
  ur.do('reposition', { firstTime: true, eles, lastState })
}

export const forceLayout = (core: Core, callback: () => void): void => {
  const ur = getUR(core)
  const eles = core.$(':selected')
  if (eles.size() === 0) return

  const bb1 = eles.boundingBox({})
  const c1: Point = getCenter(bb1)
  const lastState = snapshotNodePositions(eles)

  const options: LayoutOptions & {
    quality: string
    idealEdgeLength: number
  } = {
    name: 'fcose',
    quality: 'default',
    fit: false,
    randomize: false,
    animate: false,
    idealEdgeLength: 100,
    numIter: 10000,
    stop: () => {
      recenter(eles, c1)
      reposition(ur, eles, lastState)
      callback()
    },
  }

  const layout = eles.layout(options)
  try {
    layout.run()
  } catch (e) {
    console.log(eles)
    console.log(e)
  }
}

export const columnLayout = (core: Core, callback: () => void): void => {
  const gap = 10
  const ur = getUR(core)
  const nodes = core.$(':selected').nodes()
  if (nodes.size() === 0) return

  const bb1 = nodes.boundingBox({})
  const c1: Point = getCenter(bb1)
  const lastState = snapshotNodePositions(nodes)

  const sortedEles = nodes
    .toArray()
    .sort((a, b) => a.position('y') - b.position('y'))
  let y = sortedEles[0].position('y')
  const positions: ElePositionMap = {}
  for (let i = 0; i < sortedEles.length; i++) {
    const ele = sortedEles[i]
    if (i > 0) y += ele.height() / 2
    positions[ele.id()] = { x: c1.x, y }
    y += ele.height() / 2 + gap
  }

  const options: LayoutOptions = {
    name: 'preset',
    positions,
    fit: false,
    stop: () => {
      recenter(nodes, c1)
      reposition(ur, nodes, lastState)
      callback()
    },
  }

  const layout = nodes.layout(options)
  try {
    layout.run()
  } catch (e) {
    console.log(nodes)
    console.log(e)
  }
}

export const rowLayout = (core: Core, callback: () => void): void => {
  const gap = 10
  const ur = getUR(core)
  const nodes = core.$(':selected').nodes()
  if (nodes.size() === 0) return

  const bb1 = nodes.boundingBox({})
  const c1: Point = getCenter(bb1)
  const lastState = snapshotNodePositions(nodes)

  const sortedEles = nodes
    .toArray()
    .sort((a, b) => a.position('x') - b.position('x'))
  let x = sortedEles[0].position('x')
  const positions: ElePositionMap = {}
  for (let i = 0; i < sortedEles.length; i++) {
    const ele = sortedEles[i]
    if (i > 0) x += ele.width() / 2
    positions[ele.id()] = { x, y: c1.y }
    x += ele.width() / 2 + gap
  }

  const options: LayoutOptions = {
    name: 'preset',
    positions,
    fit: false,
    stop: () => {
      recenter(nodes, c1)
      reposition(ur, nodes, lastState)
      callback()
    },
  }

  const layout = nodes.layout(options)
  try {
    layout.run()
  } catch (e) {
    console.log(nodes)
    console.log(e)
  }
}

export const treeLayout = (core: Core, callback: () => void): void => {
  const ur = getUR(core)
  const eles = core.$(':selected')
  if (eles.size() === 0) return

  const bb1 = eles.boundingBox({})
  const c1: Point = getCenter(bb1)
  const lastState = snapshotNodePositions(eles)

  const options: LayoutOptions = {
    name: 'dagre',
    fit: false,
    animate: false,
    stop: () => {
      recenter(eles, c1)
      reposition(ur, eles, lastState)
      callback()
    },
  }

  const layout = eles.layout(options)
  try {
    layout.run()
  } catch (e) {
    console.log(eles)
    console.log(e)
  }
}

export const gridLayout = (core: Core, callback: () => void): void => {
  const ur = getUR(core)
  const nodes = core.$(':selected').nodes()
  if (nodes.size() < 2) return

  const gap = 10
  const bb1 = nodes.boundingBox({})
  const c1: Point = getCenter(bb1)
  const lastState = snapshotNodePositions(nodes)

  // sort nodes along each axis
  const sortedX = nodes
    .toArray()
    .sort((a, b) => a.position('x') - b.position('x'))
  const sortedY = nodes
    .toArray()
    .sort((a, b) => a.position('y') - b.position('y'))

  // find the largest distances
  let maxDx = 0
  let maxWidth = sortedX[0].width()
  for (let i = 0; i < sortedX.length - 1; i++) {
    maxDx = Math.max(
      maxDx,
      sortedX[i + 1].position('x') - sortedX[i].position('x'),
    )
    maxWidth = Math.max(maxWidth, sortedX[i + 1].width())
  }
  let maxDy = 0
  let maxHeight = sortedY[0].height()
  for (let i = 0; i < sortedY.length - 1; i++) {
    maxDy = Math.max(
      maxDy,
      sortedY[i + 1].position('y') - sortedY[i].position('y'),
    )
    maxHeight = Math.max(maxHeight, sortedY[i + 1].height())
  }

  let x = sortedX[0].position('x')
  let y = sortedY[0].position('y')

  const positions: ElePositionMap = {}

  const halfDx = maxDx / 2
  const halfDy = maxDy / 2
  for (let i = 0; i < sortedX.length; i++) {
    const ele = sortedX[i]
    if (i > 0) {
      const dx = sortedX[i].position('x') - sortedX[i - 1].position('x')
      if (dx > halfDx) x += maxWidth + gap
    }
    positions[ele.id()] = { x, y: 0 }
  }
  for (let i = 0; i < sortedY.length; i++) {
    const ele = sortedY[i]
    if (i > 0) {
      const dy = sortedY[i].position('y') - sortedY[i - 1].position('y')
      if (dy > halfDy) y += maxHeight + gap
    }
    positions[ele.id()].y = y
  }

  const options: LayoutOptions = {
    name: 'preset',
    positions,
    fit: false,
    stop: () => {
      recenter(nodes, c1)
      reposition(ur, nodes, lastState)
      callback()
    },
  }

  const layout = nodes.layout(options)
  try {
    layout.run()
  } catch (e) {
    console.log(nodes)
    console.log(e)
  }
}

export const expandLayout = (
  core: Core,
  callback: () => void,
  scale: number,
): void => {
  const ur = getUR(core)
  const nodes = core.$(':selected').nodes()
  if (nodes.size() === 0) return

  const bb1 = nodes.boundingBox({})
  const c1: Point = getCenter(bb1)
  const lastState = snapshotNodePositions(nodes)

  const positions: ElePositionMap = {}
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    const dd = {
      x: scale * node.position('x') - c1.x,
      y: scale * node.position('y') - c1.y,
    }
    positions[node.id()] = addPoints(node.position(), dd)
  }

  const options: LayoutOptions = {
    name: 'preset',
    positions,
    fit: false,
    stop: () => {
      recenter(nodes, c1)
      reposition(ur, nodes, lastState)
      callback()
    },
  }

  const layout = nodes.layout(options)
  try {
    layout.run()
  } catch (e) {
    console.log(nodes)
    console.log(e)
  }
}
