import React, { useCallback, useState, useEffect } from 'react'
import styled from '../config/theme'
import { useSelector } from 'react-redux'
import { RootState, RootDispatch } from '../store'
import { Point } from '../types'
import { useGesture } from 'react-use-gesture'
import { throttle } from 'lodash'
import { getUR } from '../common/graph'
import { useRematchDispatch } from '../hooks/useRematchDispatcher'

const HANDLE_SIZE = 20

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`

const Circle = styled.div`
  width: ${HANDLE_SIZE}px;
  height: ${HANDLE_SIZE}px;
  background: ${props => props.theme.colors.primary};
  border-radius: 50%;
  border: 2px solid ${props => props.theme.colors.surface};
  box-shadow: ${props => props.theme.shadows.m};
  cursor: ew-resize;
  pointer-events: all;
  transform: translate(-50%, -50%);
`

interface Props {
  setResizing: (b: boolean) => void
}

const NodeSizeHandles: React.FC<Props> = ({ setResizing }) => {
  const [initialSize, setInitialSize] = useState<Partial<Point>>({
    x: undefined,
    y: undefined,
  })
  const core = useSelector((s: RootState) => s.editor.core)
  const { zoom } = useSelector((s: RootState) => s.viewport)
  const { rect, nodeWidth, nodeHeight } = useSelector(
    (s: RootState) => s.selection,
  )
  if (!rect || nodeWidth === undefined || nodeHeight === undefined || !core) {
    throw new Error('unable to show node size handlers')
  }
  const { updateSelectionRect } = useRematchDispatch(
    (d: RootDispatch) => d.selection,
  )
  const updateWidth = useCallback(
    throttle(
      (w: number) => {
        if (initialSize.x === undefined) return
        const selectedNode = core.$('node:selected')
        const isImageNode = selectedNode.hasClass('image')
        const newWidth = Math.min(
          isImageNode ? 5000 : 500, // theme.sizes.nodeLabelMaxWidth
          Math.max(30, initialSize.x + (w * 2) / zoom),
        )
        if (isImageNode) {
          const ratio =
            selectedNode.data('imageHeight') / selectedNode.data('imageWidth')
          const newHeight = newWidth * ratio
          selectedNode.data({
            width: newWidth,
            nodeWidth: newWidth,
            height: newHeight,
            nodeHeight: newHeight,
          })
          updateSelectionRect()
        } else {
          selectedNode.data({
            width: newWidth,
            nodeWidth: newWidth,
          })
        }
      },
      16,
      {
        leading: true,
        trailing: true,
      },
    ),
    [core, initialSize, zoom, updateSelectionRect],
  )

  const commitSize = useCallback(
    (skipDo = false) => {
      const node = core.$('node:selected')
      if (!skipDo) {
        const ur = getUR(core)
        ur.do('set data', {
          firstTime: true,
          eles: node,
          lastState: {
            [node.id()]: {
              data: {
                width: initialSize.x,
                height: initialSize.y,
                nodeWidth: initialSize.x,
                nodeHeight: initialSize.y,
              },
            },
          },
        })
      }
      const size: Point = {
        x: node.data('width'),
        y: node.data('height'),
      }
      if (size.x !== initialSize.x || size.y !== initialSize.y)
        setInitialSize(size)
    },
    [core, setInitialSize, initialSize],
  )

  useEffect(() => {
    commitSize(true)
  }, [commitSize])

  const bindDragW = useGesture({
    onDrag: ({ down, movement }) => {
      if (down) updateWidth(movement[0])
      else {
        commitSize()
        setResizing(false)
      }
    },
    onDragStart: () => {
      commitSize(true)
      setResizing(true)
    },
  })
  return (
    <Container
      style={{
        transform: `translate(${rect.x + rect.w}px,${rect.y + rect.h}px)`,
      }}
    >
      <Circle {...bindDragW()} />
    </Container>
  )
}

export default NodeSizeHandles
