import { stringEnumIncludes } from '../common/util'
import { RootDispatch } from '../store'

type ImportGraphDispatcher = RootDispatch['editor']['importGraph']
type CreateImageNodeDispatcher = RootDispatch['editor']['createImageNode']
// type CreateVideoNodeDispatcher = RootDispatch['editor']['createVideoNode']

export const preventEvent = (e: DragEvent): void => {
  e.preventDefault()
}

enum SUPPORTED_FILE_TYPES {
  JSON = 'application/json',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  // WEBM = 'video/webm',
}
const MAX_FILE_SIZE = 10e6 // 10MB (units are in bytes)

export const getValidFile = (dt: DataTransfer | null): File | undefined => {
  if (dt === null) return undefined
  if (dt.types.length !== 1) return undefined
  if (dt.types[0] !== 'Files') return undefined
  if (dt.files.length !== 1) return undefined
  const file = dt.files[0]
  if (!stringEnumIncludes(SUPPORTED_FILE_TYPES, file.type)) return undefined
  if (file.size > MAX_FILE_SIZE) return undefined
  return file
}

const readJson = (importGraph: ImportGraphDispatcher, file: File): void => {
  const reader = new FileReader()
  reader.onload = (e: ProgressEvent<FileReader>) => {
    if (!e.target) return
    const json = JSON.parse(e.target.result as string)
    importGraph(json)
  }
  reader.readAsText(file)
}

export const handleDrop = (
  importGraph: ImportGraphDispatcher,
  createImageNode: CreateImageNodeDispatcher,
  // createVideoNode: CreateVideoNodeDispatcher,
) => (e: DragEvent): void => {
  e.stopPropagation()
  e.preventDefault()

  const file = getValidFile(e.dataTransfer)
  if (file === undefined) {
    alert('The thing you dropped is not supported!')
    return
  }

  switch (file.type) {
    case SUPPORTED_FILE_TYPES.JSON:
      console.log('import graph')
      readJson(importGraph, file)
      return
    case SUPPORTED_FILE_TYPES.JPEG:
    case SUPPORTED_FILE_TYPES.PNG:
      console.log('create image node')
      createImageNode({ f: file, p: e })
      return
    // case SUPPORTED_FILE_TYPES.WEBM:
    //   console.log('create video node')
    //   createVideoNode({ f: file, p: e })
    //   return
  }
}
