import { EdgeRelationship, InvalidCommandStateError, GNEdge } from '../types'
import nanoid from 'nanoid'
import { getUR } from '../common/graph'
import { RedirectEdgesArg } from './undoRedoCommands'

let core: cytoscape.Core | undefined
let rel: EdgeRelationship | undefined
let newEdges: string[] | undefined
let selectedEdges: cytoscape.CollectionReturnValue | undefined
let tempEdges: cytoscape.CollectionReturnValue | undefined
let handleClick: cytoscape.EventHandler | undefined
let handleMouseover: cytoscape.EventHandler | undefined
let handleMouseout: cytoscape.EventHandler | undefined
let lastMousedownTarget: string | undefined

const createEdgesTo = (id: string) => {
  if (!core || !selectedEdges) throw new InvalidCommandStateError()
  core.$id(id).addClass('selectHover')
  tempEdges = core.collection()
  selectedEdges.remove()
  selectedEdges.forEach(edge => {
    if (!tempEdges || !core) throw new InvalidCommandStateError()
    const newEdge: GNEdge = {
      data: {
        id: nanoid(),
        title: edge.data('title'),
        textColor: edge.data('textColor'),
        edgeColor: edge.data('edgeColor'),
        textSize: edge.data('textSize'),
        source: rel === 'source' ? id : edge.data('source'),
        target: rel === 'target' ? id : edge.data('target'),
        lineStyle: edge.data('lineStyle'),
        targetShape: edge.data('targetShape'),
        sourceShape: edge.data('sourceShape'),
        ctrlDist: edge.data('ctrlDist'),
        ctrlWeight: edge.data('ctrlWeight'),
        bend: edge.data('bend'),
      },
    }
    tempEdges.merge(core.add(newEdge))
  })
}

const removeTempEdges = () => {
  if (!core || !selectedEdges) throw new InvalidCommandStateError()
  core.$('.selectHover').removeClass('selectHover')
  if (tempEdges && !tempEdges.removed()) tempEdges.remove()
  tempEdges = undefined
  selectedEdges.restore()
}

const start = (
  _rel: EdgeRelationship,
  _core: cytoscape.Core,
  onClick: () => void,
): void => {
  core = _core
  rel = _rel

  selectedEdges = core.$('edge:selected')
  selectedEdges.deselect()

  document.body.style.cursor = 'crosshair'

  core.boxSelectionEnabled(false)
  core.autoungrabify(true)
  core.autounselectify(true)

  handleClick = e => {
    lastMousedownTarget = e.target.id()
    onClick()
  }
  handleMouseover = e => {
    createEdgesTo(e.target.id())
  }
  handleMouseout = () => {
    removeTempEdges()
  }
  core.on('mouseover', 'node', handleMouseover)
  core.on('mouseout', 'node', handleMouseout)
  core.on('click', 'node', handleClick)

  newEdges = []

  const hoverId = core.scratch('_hover')
  if (hoverId) {
    createEdgesTo(hoverId)
  }
}

const end = () => {
  if (!core || !newEdges || !selectedEdges) throw new InvalidCommandStateError()
  core.off('mouseover', 'node', handleMouseover)
  core.off('mouseout', 'node', handleMouseout)
  core.off('click', 'node', handleClick)

  document.body.style.cursor = 'default'

  if (lastMousedownTarget) {
    const lastClicked = core.$id(lastMousedownTarget)
    lastClicked.unselectify()
    setTimeout(() => {
      lastClicked.selectify()
    }, 100)
  }

  core.boxSelectionEnabled(true)
  core.autoungrabify(false)
  core.autounselectify(false)

  core.$('node.selectHover').removeClass('selectHover')
  const result = [...newEdges]
  if (result.length === 0) {
    selectedEdges.select()
  } else {
    const c = core
    result.forEach(id => c.$id(id).select())
  }

  core = undefined
  rel = undefined
  newEdges = undefined
  selectedEdges = undefined
  tempEdges = undefined
  handleClick = undefined
  handleMouseover = undefined
  handleMouseout = undefined
  lastMousedownTarget = undefined
}

const commit = () => {
  if (!core || !tempEdges || !newEdges) throw new InvalidCommandStateError()
  const ur = getUR(core)
  ur.do('redirect edges', {
    firstTime: true,
    oldEdges: selectedEdges,
    newEdges: tempEdges,
  } as RedirectEdgesArg)
  newEdges.push(...tempEdges.map(e => e.id()))
  tempEdges = undefined
}
const cancel = () => {
  if (tempEdges && tempEdges.length > 0) removeTempEdges()
}

export { start, commit, cancel, end }
