import React, {
  useRef,
  useCallback,
  useState,
  useLayoutEffect,
  useMemo,
  useEffect,
} from 'react'
import { MissingCyCoreError } from '../types'
import { css } from '@emotion/core'
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable'
import { RootState, RootDispatch } from '../store'
import { focusAtEnd, getRangeRect, getNodeTextClasses } from '../common/util'
import { useDOMEffectFromState } from '../hooks/useDOMEffectFromState'
import { useSelector } from 'react-redux'
import { getEleText } from '../common/graph'
import {
  getCenter,
  isEmptyRect,
  getDistanceToClampRect,
  toRect,
  shrinkRect,
} from '../common/geometry'
import { useRematchDispatch } from '../hooks/useRematchDispatcher'
import styled, { theme } from '../config/theme'
import * as editText from '../commands/editText'
import { ResizeObserver } from 'resize-observer'
import { useSanitizedPaste } from '../hooks/useSanitizedPaste'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: center;
`

const editorStyle = css`
  max-width: ${theme.sizes.nodeLabelMaxWidth};
  word-break: break-word;
  padding: 8px;
  background: transparent;
  outline: none;
  cursor: text;
  overflow-y: auto;
  ::-webkit-scrollbar {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
`

interface Props {
  resizing: boolean
}

const NodeTextEditor: React.FC<Props> = ({ resizing }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [text, setText] = useState<string>('')
  const [flushText, setFlushText] = useState<boolean>(false)
  const {
    core,
    nodeId,
    zoom,
    viewportRect,
    selectionRect,
    textColor,
    textAlign,
    textSize,
    nodeWidth,
  } = useSelector((s: RootState) => ({
    core: s.editor.core,
    nodeId: s.selection.nodes[0],
    zoom: s.viewport.zoom,
    viewportRect: s.viewport.rect,
    selectionRect: s.selection.rect,
    textColor: s.selection.textColor,
    textAlign: s.selection.textAlign,
    textSize: s.selection.textSize,
    nodeWidth: s.selection.nodeWidth,
  }))
  if (!selectionRect)
    throw new Error('node text editor expects selection rect to exist')
  const {
    enterEditTextMode,
    leaveEditTextMode,
    updateSelectionRect,
    setRef,
  } = useRematchDispatch((d: RootDispatch) => ({
    enterEditTextMode: d.editor.enterEditTextMode,
    leaveEditTextMode: d.editor.leaveEditTextMode,
    updateSelectionRect: d.selection.updateSelectionRectIfNotNull,
    setRef: d.textEditor.setRef,
  }))
  if (!core) throw new MissingCyCoreError()
  const center = useMemo(() => getCenter(selectionRect), [selectionRect])
  const style: React.CSSProperties = useMemo(
    () => ({
      transform: `translate(${center.x}px, ${center.y}px) translate(-50%, -50%) scale(${zoom})`,
      pointerEvents: resizing ? 'none' : 'all',
    }),
    [center, zoom, resizing],
  )
  const editorSize: React.CSSProperties = useMemo(
    () => ({
      width: nodeWidth,
      height: undefined,
    }),
    [nodeWidth],
  )

  // allow focus from actions
  useDOMEffectFromState(
    ref,
    (e: HTMLElement) => {
      e.focus()
      focusAtEnd(e)
    },
    (s: RootState) => s.textEditor.doFocus,
    (d: RootDispatch) => d.textEditor.setDoFocus,
  )
  // allow blur from actions
  useDOMEffectFromState(
    ref,
    (e: HTMLElement) => e.blur(),
    (s: RootState) => s.textEditor.doBlur,
    (d: RootDispatch) => d.textEditor.setDoBlur,
  )
  // set text from cytoscape when a new node is selected
  useLayoutEffect(() => {
    setText(getEleText(core, nodeId))
    setFlushText(false)
    const node = core.$id(nodeId)
    node.data('editing', true)
    return () => {
      node.removeData('editing')
    }
  }, [nodeId, core])
  // scroll label to match whenever we remove the editor from a node
  useEffect(() => {
    const { current } = ref
    if (current) {
      const id = nodeId
      return () => {
        const { scrollTop } = current
        core.$id(id).scratch('_scrollTop', scrollTop)
        setTimeout(() => {
          const label = document.getElementById(id)
          if (label) {
            label.scroll(0, scrollTop)
          }
        }, 20)
      }
    }
  }, [nodeId, core, ref])
  // pan viewport to keep selection range in view
  useEffect(() => {
    const handleSelectionChange = () => {
      const rangeRect = getRangeRect()
      if (!rangeRect || isEmptyRect(rangeRect)) return
      const padding = 20 * core.zoom()
      // make the bottom big enough not to overlap buttons
      const safeRect = shrinkRect(viewportRect, {
        left: Math.max(32, padding),
        right: Math.max(32, padding),
        top: Math.max(32, padding),
        bottom: Math.max(104, padding),
      })
      const d = getDistanceToClampRect(toRect(rangeRect), safeRect)
      if (d.x !== 0 || d.y !== 0) core.panBy(d)
    }
    document.addEventListener('selectionchange', handleSelectionChange)
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange)
    }
  }, [nodeId, core, viewportRect])
  // update selection rect when node text changes
  useEffect(() => {
    const ele = core.$id(nodeId)
    ele.on('data', updateSelectionRect)
    return () => {
      ele.off('data', undefined, updateSelectionRect)
    }
  }, [nodeId, core, updateSelectionRect])
  // update the cy element whenever the text is updated by typing
  useEffect(() => {
    if (flushText) {
      const current = ref.current
      if (current) {
        const inputRect = current.getBoundingClientRect()
        const width = inputRect.width / zoom
        const height = inputRect.height / zoom
        editText.set(text, width, height)
        setFlushText(false)
      }
    } else {
      const node = core.$id(nodeId)
      const scrollTop = node.scratch('_scrollTop')
      if (scrollTop) {
        const { current } = ref
        if (current) {
          setTimeout(() => {
            current.scroll(0, scrollTop)
          }, 0)
        }
      }
    }
  }, [core, text, flushText, setFlushText, nodeId, ref, zoom])
  useEffect(() => {
    const current = ref.current
    if (current) {
      const ele = core.$id(nodeId)
      const ro = new ResizeObserver(e => {
        const z = core.zoom()
        const rect = e[0].target.getBoundingClientRect()
        ele.data({
          width: rect.width / z,
          height: rect.height / z,
        })
      })
      ro.observe(current)
      return () => {
        ro.unobserve(current)
      }
    }
  }, [nodeId, core, ref])
  useEffect(() => {
    const current = ref.current
    if (current) {
      let triggerRefocus = true

      const handleFocus = () => {
        editText.start(core, nodeId)
        if (triggerRefocus) {
          // this is a hack to fix the first focus bug
          setTimeout(() => {
            current.blur()
            triggerRefocus = false
          }, 0)
          setTimeout(() => {
            current.focus()
          }, 10)
        } else {
          enterEditTextMode()
        }
      }

      const handleBlur = () => {
        if (triggerRefocus) return
        leaveEditTextMode()
        editText.end()
        document.getSelection()?.removeAllRanges()
      }

      current.addEventListener('focus', handleFocus)
      current.addEventListener('blur', handleBlur)
      return () => {
        current.removeEventListener('focus', handleFocus)
        current.removeEventListener('blur', handleBlur)
      }
    }
  }, [nodeId, ref, enterEditTextMode, leaveEditTextMode, core])
  useSanitizedPaste(ref)
  const handleChange = useCallback(
    (e: ContentEditableEvent) => {
      const newText = e.target.value
      setText(newText)
      setFlushText(true)
    },
    [setText, setFlushText],
  )

  const [styleClasses, setStyleClasses] = useState<string>('')
  useLayoutEffect(() => {
    const data = { ...core.$id(nodeId).data() }
    setStyleClasses(getNodeTextClasses(data))
    return () => {
      setStyleClasses('')
    }
  }, [nodeId, core, textColor, textAlign, textSize, setStyleClasses])
  useEffect(() => {
    if (ref.current) setRef(ref.current)
  }, [ref, setRef])
  return (
    <Container style={style}>
      <ContentEditable
        className={['mousetrap', styleClasses].join(' ')}
        css={editorStyle}
        style={editorSize}
        innerRef={ref}
        html={text}
        onChange={handleChange}
      />
    </Container>
  )
}

export default NodeTextEditor
