import styled, { CreateStyled } from '@emotion/styled'
import chroma from 'chroma-js'
import { openColor, OpenColorPalette } from './openColor'
import { FontSize } from '../types'

const rgba = (c: chroma.Color): string => {
  const list = c.rgba()
  return `rgba(${list[0]},${list[1]},${list[2]},${list[3]})`
}
const hex = (c: chroma.Color): string => c.hex()

const primary = chroma('#49E2E5')
const highlightOpacity = 0.15
const base = chroma(openColor.gray[9])

// pick out shades from an oc hue
const shades = [1, 4, 6, 8, 9]
const ocHues = (hue: keyof OpenColorPalette): string[] => {
  const hues: string[] = []
  for (const i of shades) hues.unshift(openColor[hue][i])
  return hues
}

export const theme = {
  colors: {
    primary: hex(primary),
    highlight: rgba(primary.alpha(highlightOpacity)),
    outline: rgba(primary.darken(0.8).alpha(0.5)),
    base: hex(base),
    surface: hex(chroma(openColor.gray[8]).darken(0.1)),
    overlay: hex(chroma(openColor.gray[8]).brighten(0.1)),
    overlay2: hex(chroma(openColor.gray[8]).brighten(0.15)),
    text: openColor.gray[0],
    subtext: openColor.gray[3],
  },
  hues: {
    gray: ocHues('gray'),
    red: ocHues('red'),
    orange: ocHues('orange'),
    yellow: ocHues('yellow'),
    green: ocHues('lime'),
    cyan: ocHues('cyan'),
    blue: ocHues('teal'),
    purple: ocHues('grape'),
    pink: ocHues('pink'),
  },
  spacing: {
    xs: '2px',
    s: '4px',
    m: '8px',
    l: '16px',
    xl: '32px',
  },
  easing: {
    easeOutQuad: 'cubic-bezier(.25, .46, .45, .94)',
  },
  borderRadius: '3px',
  opacities: {
    highlight: highlightOpacity,
  },
  shadows: {
    m: `0 0.8px 4.2px rgba(0, 0, 0, 0.31), 0 2.7px 11.6px rgba(0, 0, 0, 0.185), 0 12px 42px rgba(0, 0, 0, 0.125);`,
  },
  sizes: {
    nodeLabelMaxWidth: '500px',
    buttonSize: '48px',
  },
}
export type Theme = typeof theme
export type Hue = keyof typeof theme.hues
export enum Lightness {
  DARKEST,
  DARK,
  MID,
  LIGHT,
  LIGHTEST,
}
export interface HL {
  hue: Hue
  lightness: Lightness
}
const getHLMap = (t: Theme): Map<string, HL> => {
  const map = new Map<string, HL>()
  for (const k of Object.keys(t.hues)) {
    for (let lightness = 0; lightness <= Lightness.LIGHTEST; lightness++) {
      const hue = k as Hue
      map.set(theme.hues[k as Hue][lightness], {
        hue,
        lightness,
      })
    }
  }
  return map
}
const hlMap = getHLMap(theme)
export const getColor = (hue: Hue, lightness: Lightness): string =>
  theme.hues[hue][lightness]
export const getHL = (color: string): { hue: Hue; lightness: Lightness } => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (hlMap.has(color)) return hlMap.get(color)!
  return { hue: 'gray', lightness: 5 }
  // throw new Error(`${color} is not in theme hues`)
}
export const textColorStyles = Array.from(hlMap.keys())
  .map(c => `.c-${c.substr(1)}{ color: ${c} }`)
  .join('\n')
export const edgeColorStyles = Array.from(hlMap.keys())
  .map(c => `.e-${c.substr(1)}{ background: ${c} }`)
  .join('\n')
export const textAlignStyles = `
.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }
`
export const textSizeStyles = `
.text-s { font-size: 1em; }
.text-m { font-size: 1.5em; }
.text-l { font-size: 2em; }
.text-xl { font-size: 3em; }
`
export const getFontSize = (size: FontSize): string => {
  switch (size) {
    case 's':
      return '1em'
    case 'm':
      return '1.5em'
    case 'l':
      return '2em'
    case 'xl':
      return '3em'
  }
}

export default styled as CreateStyled<Theme>
