import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { CommandName } from '../../types'
import { ReactComponent as SIcon } from '../../icons/text-size-small.svg'
import { ReactComponent as MIcon } from '../../icons/text-size-medium.svg'
import { ReactComponent as LIcon } from '../../icons/text-size-large.svg'
import { ReactComponent as XLIcon } from '../../icons/text-size-extra-large.svg'
import CommandBtn from './CommandBtn'

const COMMAND: CommandName = 'set text size'
const TextSizeBtn: React.FC = () => {
  const { textSize } = useSelector((s: RootState) => s.selection)
  const icon = useMemo(() => {
    switch (textSize) {
      case null:
      case 's':
        return <SIcon />
      case 'm':
        return <MIcon />
      case 'l':
        return <LIcon />
      case 'xl':
        return <XLIcon />
    }
  }, [textSize])
  return <CommandBtn command={COMMAND}>{icon}</CommandBtn>
}

export default TextSizeBtn
