let queue: string[] | undefined
let current: string | undefined

const set = (core: cytoscape.Core, value: string[]): void => {
  queue = [...value]
  const clearIfNotTabbing = (e: cytoscape.EventObject) => {
    if (e.target.id() !== current) {
      queue = undefined
      current = undefined
      core.off('select', undefined, clearIfNotTabbing)
    }
  }
  core.on('select', clearIfNotTabbing)
}

const next = (core: cytoscape.Core): boolean => {
  core.$(':selected').unselect()
  if (queue !== undefined && queue.length > 0) {
    const id = queue.shift() as string
    current = id
    core.$id(id).select()
    return true
  }
  return false
}

export { set, next }
