import { NodeData, EdgeData } from '../types'
import { Clipboard } from '../common/graph'

export interface AddRemoveArg {
  firstTime: boolean
  eles: cytoscape.CollectionReturnValue
}
export interface EleDataSnapshot {
  data: Partial<NodeData & EdgeData>
  classes?: string
}
export type EleDataSnapshotMap = {
  [id: string]: EleDataSnapshot
}
export interface SetDataArg {
  firstTime: boolean
  eles: cytoscape.CollectionReturnValue
  lastState: EleDataSnapshotMap
}
export type ElePositionMap = {
  [id: string]: cytoscape.Position
}
export interface SetPositionArg {
  firstTime: boolean
  eles: cytoscape.CollectionReturnValue
  lastState: ElePositionMap
}
export interface RedirectEdgesArg {
  firstTime: boolean
  oldEdges: cytoscape.CollectionReturnValue
  newEdges: cytoscape.CollectionReturnValue
}
export interface PasteClipboardArg {
  firstTime: boolean
  cb: Clipboard
  eles: cytoscape.CollectionReturnValue
}

const snapshotNodePositions = (
  eles: cytoscape.CollectionArgument,
): ElePositionMap =>
  eles
    .nodes()
    .toArray()
    .reduce((currentState, node) => {
      currentState[node.id()] = { ...node.position() }
      return currentState
    }, {} as ElePositionMap)

const repositionNodes = ({
  firstTime,
  eles,
  lastState,
}: SetPositionArg): SetPositionArg => {
  if (firstTime) return { firstTime: false, eles, lastState }
  const currentState = snapshotNodePositions(eles)
  eles.nodes().forEach(node => {
    node.position({ ...lastState[node.id()] })
    node.scratch('_flushSize', true)
  })
  return {
    firstTime: false,
    eles,
    lastState: currentState,
  }
}
const addEles = ({ firstTime, eles }: AddRemoveArg): AddRemoveArg => {
  if (!firstTime) eles.restore()
  return { firstTime: false, eles }
}
const removeEles = ({ firstTime, eles }: AddRemoveArg): AddRemoveArg => {
  let removedEles = eles
  if (!firstTime) removedEles = eles.remove()
  return { firstTime: false, eles: removedEles }
}
const getClasses = (ele: cytoscape.SingularElementReturnValue): string => {
  const classes = (ele.classes() as unknown) as string[]
  return classes.join(' ')
}
const setData = ({ firstTime, eles, lastState }: SetDataArg): SetDataArg => {
  if (firstTime) return { firstTime: false, eles, lastState }
  const currentState: EleDataSnapshotMap = {}
  eles.forEach(ele => {
    ele.scratch('_flushSize', true)
    const eleState: EleDataSnapshot = {
      data: {},
      classes: '',
    }
    const lastEleData = lastState[ele.id()].data
    for (const k of Object.keys(lastEleData)) {
      const key = k as keyof NodeData | keyof EdgeData
      eleState.data[key] = ele.data(key)
    }
    ele.data(lastEleData)
    eleState.classes = getClasses(ele)
    currentState[ele.id()] = eleState
    ele.classes(lastState[ele.id()].classes)
  })
  return { firstTime: false, eles, lastState: currentState }
}
const redirectEdges = ({
  firstTime,
  oldEdges,
  newEdges,
}: RedirectEdgesArg): RedirectEdgesArg => {
  if (!firstTime) {
    newEdges.restore()
    oldEdges.remove()
  }
  return { firstTime: false, oldEdges: newEdges, newEdges: oldEdges }
}
let cb: Clipboard
const setCb = (_cb: Clipboard) => {
  cb = _cb
}
const pasteClipboard = ({ firstTime, eles }: AddRemoveArg): AddRemoveArg => {
  let nextEles = eles
  if (firstTime) {
    nextEles = cb.paste()
  } else {
    eles.restore()
  }
  return { firstTime: false, eles: nextEles }
}

export {
  addEles,
  removeEles,
  setData,
  redirectEdges,
  setCb,
  pasteClipboard,
  repositionNodes,
  snapshotNodePositions,
}
