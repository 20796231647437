import React, { useMemo, memo } from 'react'
import { CommandName, LineEndShape } from '../../types'
import CommandBtn from './CommandBtn'

interface Props {
  command: CommandName
  shape: LineEndShape
  NoneIcon: React.ReactNode
  ArrowIcon: React.ReactNode
  SquareIcon: React.ReactNode
  CircleIcon: React.ReactNode
}
const CommandShapeBtn: React.FC<Props> = ({
  command,
  shape,
  NoneIcon,
  ArrowIcon,
  SquareIcon,
  CircleIcon,
}) => {
  const icon = useMemo(() => {
    switch (shape) {
      case 'none':
        return NoneIcon
      case 'triangle':
        return ArrowIcon
      case 'circle':
        return CircleIcon
      case 'square':
        return SquareIcon
    }
  }, [shape, NoneIcon, ArrowIcon, CircleIcon, SquareIcon])
  return <CommandBtn command={command}>{icon}</CommandBtn>
}

export default memo(CommandShapeBtn)
