import cytoscape from 'cytoscape'
import { debounce, throttle } from 'lodash'
import {
  MissingCyContainerError,
  MissingCyCoreError,
  Rect,
  Point,
  AuthError,
  GNNode,
  GNEdge,
} from '../types'
import { toRect } from './geometry'
import { RootState } from '../store'
import { style } from '../config/style'
import { ZOOM_STEPS } from '../models/viewport'
import {
  getNodeTextClasses,
  getEditingEleClass,
  getEdgeTextClasses,
  getManualNodeWidth,
  getUnfocusClass,
} from './util'
import {
  addEles,
  removeEles,
  setData,
  redirectEdges,
  repositionNodes,
} from '../commands/undoRedoCommands'
// import { StoredState } from './storage'
import undoRedo from 'cytoscape-undo-redo'
import clipboard from 'cytoscape-clipboard'
import jquery from 'jquery'
// import coseBilkent from 'cytoscape-cose-bilkent'
import fcose from 'cytoscape-fcose'
import dagre from 'cytoscape-dagre'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const htmlLabel = require('cytoscape-html-label')

undoRedo(cytoscape)
clipboard(cytoscape, jquery)
htmlLabel(cytoscape)
// cytoscape.use(coseBilkent)
cytoscape.use(fcose)
cytoscape.use(dagre)
// nodeHtmlLabel(cytoscape)

type UndoRedoCommand =
  | 'add eles'
  | 'remove eles'
  | 'redirect edges'
  | 'set data'
  | 'paste'
  | 'move nodes'
  | 'reposition'
  | 'batch'

export interface UndoRedo {
  action: <T>(
    actionName: UndoRedoCommand,
    _do: (x: T) => T,
    _undo: (x: T) => T,
  ) => UndoRedo
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  do: (actionName: UndoRedoCommand, x: any) => void
  undo: () => void
  redo: () => void
  isUndoStackEmpty: () => boolean
  isRedoStackEmpty: () => boolean
  on: (
    event: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handler: (event: string, actionName: UndoRedoCommand, x: any) => void,
  ) => void
  reset: () => void
}
let ur: UndoRedo
export interface Clipboard {
  paste: () => cytoscape.CollectionReturnValue
  copy: (
    eles: cytoscape.CollectionReturnValue,
  ) => cytoscape.CollectionReturnValue
}
let cb: Clipboard

export const enableZoomWithPanningDisabled = (
  container: HTMLElement,
  core: cytoscape.Core,
  debounceTime: number,
): void => {
  const debouncedDisablePan = debounce(() => {
    core.userPanningEnabled(false)
  }, debounceTime)
  const handleWheel = () => {
    if (!core.userPanningEnabled()) core.userPanningEnabled(true)
    debouncedDisablePan()
  }
  container.addEventListener('wheel', handleWheel)
}

export const requireCore = (s: RootState): cytoscape.Core => {
  const core = s.editor.core
  if (!core) throw new MissingCyCoreError()
  return core
}

export const requireContainer = (core: cytoscape.Core): HTMLElement => {
  const container = core.container()
  if (!container) throw new MissingCyContainerError()
  return container
}

export const requireAuth = (s: RootState, attemptedAction?: string): string => {
  if (!s.auth.user) {
    alert('You must be logged in')
    throw new AuthError(attemptedAction)
  }
  return s.auth.user.uid
}

export const getSelectionRect = (core: cytoscape.Core): Rect => {
  return toRect(core.$(':selected').renderedBoundingBox({}))
}

export interface CreateCoreOptions {
  // storedState: StoredState
  container: HTMLElement
  updateSelection: () => void
  updateSelectionRect: () => void
  updateSelectionData: () => void
  clearSelectionRect: () => void
  save: () => void
  setDirty: () => void
  setZoom: (zoom: number) => void
  setPan: (pan: Point) => void
  addNewNode: () => void
}
export const createCore = (
  options: CreateCoreOptions,
): Promise<cytoscape.Core> => {
  const {
    // storedState: { elements, zoom, pan },
    container,
    updateSelection,
    updateSelectionRect,
    updateSelectionData,
    setPan,
    clearSelectionRect,
    setZoom,
    save,
    setDirty,
    addNewNode,
  } = options
  const core = cytoscape({
    container,
    // elements,
    zoom: 1,
    pan: { x: 0, y: 0 },
    style,
    layout: {
      name: 'preset',
    },
    minZoom: ZOOM_STEPS[0],
    maxZoom: ZOOM_STEPS[ZOOM_STEPS.length - 1],
    userPanningEnabled: false,
    wheelSensitivity: 0.2,
  })

  const autoSave = debounce(save, 5000, { leading: false, trailing: true })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const coreWithPlugin = core as any
  ur = coreWithPlugin.undoRedo({ stackSizeLimit: 500 })
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //@ts-ignore
  core.on('afterDo', () => {
    autoSave()
    setDirty()
  })
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //@ts-ignore
  core.on('afterUndo', () => {
    autoSave()
    setDirty()
  })
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //@ts-ignore
  core.on('afterRedo', () => {
    autoSave()
    setDirty()
  })
  ur.action('add eles', addEles, removeEles)
  ur.action('remove eles', removeEles, addEles)
  ur.action('set data', setData, setData)
  ur.action('redirect edges', redirectEdges, redirectEdges)
  ur.action('reposition', repositionNodes, repositionNodes)
  core.scratch('_ur', ur)

  cb = coreWithPlugin.clipboard({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    afterCopy: (cb: { nodes: any[]; edges: any[] }) => {
      cb.nodes.forEach(n => {
        n.data.editing = undefined
      })
    },
  })
  core.scratch('_cb', cb)

  coreWithPlugin.htmlLabel([
    {
      query: 'node',
      tpl(data: GNNode['data']) {
        return `<div id="${data.id}" class="${[
          'cy-label',
          getNodeTextClasses(data),
          getEditingEleClass(data),
          getUnfocusClass(data),
        ].join(' ')}" ${getManualNodeWidth(data)}>
          <div class="cy-link">${data.url ? '🔗' : ''}</div>
          ${data.title}
        </div>`
      },
    },
    {
      query: 'edge',
      ealign: 'midpoint',
      tpl(data: GNEdge['data']) {
        return `<div class="${[
          'cy-edge-label',
          getEdgeTextClasses(data),
          getEditingEleClass(data),
          getUnfocusClass(data),
        ].join(' ')}">${data.title}</div>`
      },
    },
    {
      query: ':removed',
      tpl: () => null,
    },
    {
      query: 'node.image',
      tpl: () => null,
    },
  ])

  enableZoomWithPanningDisabled(container, core, 200)

  const debouncedUpdateSelection = debounce(updateSelection, 4)
  const debouncedUpdateData = debounce(updateSelectionData, 4)
  const clearOnStart = debounce(clearSelectionRect, 33, {
    leading: true,
    trailing: false,
  })
  const updateOnStop = debounce(updateSelectionRect, 33, {
    leading: false,
    trailing: true,
  })
  // const updateOnDrag = throttle(updateSelectionRect, 33, {
  //   leading: true,
  //   trailing: true,
  // })
  const updateZoom = throttle(
    (e: cytoscape.EventObject) => {
      updateSelectionRect()
      setZoom(e.cy.zoom())
      setPan(e.cy.pan())
    },
    33,
    {
      leading: true,
      trailing: true,
    },
  )

  core.on('select', e => {
    debouncedUpdateSelection()
    debouncedUpdateData()
    e.target.on('data', debouncedUpdateData)
  })
  core.on('unselect', e => {
    debouncedUpdateSelection()
    debouncedUpdateData()
    e.target.off('data', undefined, debouncedUpdateData)
  })
  core.on('grabon', clearOnStart)
  core.on('freeon', updateOnStop)
  // core.on('drag', updateOnDrag)
  core.on('viewport', updateZoom)

  let lastCoreClicked = 0
  core.on('click', e => {
    if (e.target === core) {
      if (e.timeStamp - lastCoreClicked < 300) {
        addNewNode()
      }
      lastCoreClicked = e.timeStamp
    }
  })

  const handleZoom: cytoscape.EventHandler = e => {
    // clearOnStart()
    // updateOnStop()
    updateZoom(e)
  }
  core.on('zoom', handleZoom)

  core.on(
    'mousemove',
    throttle(
      e => {
        core.scratch('_mouse', e.position)
      },
      16,
      { trailing: true },
    ),
  )

  core.scratch('_hover', null)
  core.on('mouseover', 'node', e => {
    core.scratch('_hover', e.target.id())
  })
  core.on('mouseout', 'node', () => {
    core.scratch('_hover', null)
  })
  core.on('remove', 'node', e => {
    const scratch = core.scratch('_hover')
    if (scratch && scratch === e.target.id()) {
      core.scratch('_hover', null)
    }
  })

  return new Promise(resolve => {
    core.one('resize', e => {
      setZoom(e.cy.zoom())
      setPan(e.cy.pan())
      resolve(core)
    })
  })
}

export const getEleText = (core: cytoscape.Core, id: string): string => {
  return core.$id(id).data('title') ?? ''
}

export const getUR = (core: cytoscape.Core): UndoRedo => {
  const ur = core.scratch('_ur')
  if (ur) return ur as UndoRedo
  throw new Error('expected cytoscape undoredo to exist in scratch')
}

export const getCB = (core: cytoscape.Core): Clipboard => {
  const cb = core.scratch('_cb')
  if (cb) return cb as Clipboard
  throw new Error('expected cytoscape clipboard to exist in scratch')
}

export const resetCore = (core: cytoscape.Core): void => {
  core.$('*').remove()
  const ur = getUR(core)
  ur.reset()
  core.zoom(1)
  core.pan({ x: 0, y: 0 })
}

export const lockCore = (core: cytoscape.Core): void => {
  core.autolock(true)
}

export const unlockCore = (core: cytoscape.Core): void => {
  core.autolock(false)
}
