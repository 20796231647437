import { getCenter } from '../common/geometry'
import { createNewNode } from '../common/util'
import { getUR } from '../common/graph'
import { NodeData } from '../types'

let core: cytoscape.Core | undefined
let lastCreatedNode: cytoscape.CollectionReturnValue | undefined

export const run = (
  _core: cytoscape.Core,
  text: string,
  url?: string,
): void => {
  core = _core
  const ur = getUR(core)
  const data: Partial<NodeData> = { title: text }
  if (url) data.url = url
  const n = core.add(
    createNewNode({
      data,
      position: getCenter(core.extent()),
    }),
  )
  ur.do('add eles', { firstTime: true, eles: n })
  core.$(':selected').unselect()
  n.select()
  lastCreatedNode = n
}

export const reposition = (): void => {
  if (core !== undefined && lastCreatedNode !== undefined) {
    const bb = lastCreatedNode.renderedBoundingBox({})
    const x = 0
    const y = -bb.h - 16
    core.panBy({ x, y })
  }
}
