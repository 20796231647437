import React from 'react'
import styled from '../config/theme'
import moment from 'moment'

const Container = styled.div`
  padding: 8px;
  cursor: pointer;
  border-radius: ${props => props.theme.borderRadius};
  &:hover {
    background: ${props => props.theme.colors.highlight};
  }
`
const Title = styled.div`
  font-weight: bold;
  color: white;
`
const Modified = styled.div`
  font-size: 0.8em;
`

interface Props {
  id: string
  title: string
  modified: number
  onClick: () => void
}
const NoteListItem: React.FC<Props> = ({ id, title, modified, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Title>{title}</Title>
      <Modified>{moment(modified).fromNow()}</Modified>
    </Container>
  )
}

export default NoteListItem
