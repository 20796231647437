import styled from '../../config/theme'

const ContextMenuDivider = styled.span`
  flex: 1;
  display: block;
  margin-left: 4px;
  width: 4px;
  border-left: 1px solid ${props => props.theme.colors.subtext};
  height: ${props => props.theme.sizes.buttonSize};
`

export default ContextMenuDivider
