import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import ColorPickerMenu from './ColorPickerMenu'

const NodeColorPickerMenu: React.FC = () => {
  const { nodeColor } = useSelector((s: RootState) => s.selection)
  return <ColorPickerMenu sourceColor={nodeColor || 'white'} />
}

export default NodeColorPickerMenu
