import { useEffect } from 'react'
import { throttle } from 'lodash'
import { Point } from '../types'
import { RootState, RootDispatch } from '../store'
import { useSelector } from 'react-redux'
import { selectCurrentContext } from '../models/editor'
import { useRematchDispatch } from './useRematchDispatcher'

export const useClickToDrag = () => {
  const { core, context } = useSelector((s: RootState) => ({
    core: s.editor.core,
    context: selectCurrentContext(s),
  }))
  const { setRect, updateSelectionRect } = useRematchDispatch(
    (d: RootDispatch) => d.selection,
  )

  useEffect(() => {
    if (!core || context !== 'panning') return
    let p0: Point = { x: 0, y: 0 }
    let v0: Point = { x: 0, y: 0 }

    const dragViewport: cytoscape.EventHandler = throttle(e => {
      const p = e.renderedPosition
      core.pan({
        x: v0.x + (p.x - p0.x),
        y: v0.y + (p.y - p0.y),
      })
    }, 16)

    const startDrag: cytoscape.EventHandler = e => {
      // setRect(null)
      p0 = e.renderedPosition
      const { x, y } = core.pan()
      v0 = { x, y }
      core.on('mousemove', dragViewport)
      document.body.style.cursor = 'grabbing'
    }

    const stopDrag = () => {
      // updateSelectionRect()
      core.off('mousemove', undefined, dragViewport)
      document.body.style.cursor = 'grab'
    }

    const setupClickToDrag = () => {
      core.boxSelectionEnabled(false)
      core.autoungrabify(true)
      core.autounselectify(true)
      document.body.style.cursor = 'grab'
      core.on('mousedown', startDrag)
      core.on('mouseup', stopDrag)
    }

    const cleanupClickToDrag = () => {
      stopDrag()
      core.boxSelectionEnabled(true)
      core.autoungrabify(false)
      core.autounselectify(false)
      document.body.style.cursor = 'default'
      core.off('mousedown', undefined, startDrag)
      core.off('mouseup', undefined, stopDrag)
    }

    setupClickToDrag()
    return () => {
      cleanupClickToDrag()
    }
  }, [core, context, setRect, updateSelectionRect])
}
