import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import CommandShapeBtn from './CommandShapeBtn'
import { ReactComponent as NoneIcon } from '../../icons/target-shape-none.svg'
import { ReactComponent as SquareIcon } from '../../icons/target-shape-square.svg'
import { ReactComponent as CircleIcon } from '../../icons/target-shape-circle.svg'
import { ReactComponent as ArrowIcon } from '../../icons/target-shape-arrow.svg'

const CommandTargetShapeBtn: React.FC = () => {
  const { targetShape } = useSelector((s: RootState) => s.selection)
  return (
    <CommandShapeBtn
      command="target style"
      shape={targetShape || 'none'}
      NoneIcon={<NoneIcon />}
      SquareIcon={<SquareIcon />}
      CircleIcon={<CircleIcon />}
      ArrowIcon={<ArrowIcon />}
    />
  )
}

export default CommandTargetShapeBtn
