import {
  Rect,
  SelectionType,
  HAlign,
  FontSize,
  LineEndShape,
  LineStyle,
} from '../types'
import { RootDispatch, RootState } from '../store'
import { getSelectionRect, requireCore } from '../common/graph'
import { toRect } from '../common/geometry'
import { unbindVariant, bindVariant, selectCurrentContext } from './editor'

const getSelectionType = (nodes: string[], edges: string[]): SelectionType => {
  if (nodes.length === 0 && edges.length === 0) return SelectionType.NONE
  if (nodes.length === 0)
    return edges.length === 1 ? SelectionType.EDGE : SelectionType.EDGES
  if (edges.length === 0)
    return nodes.length === 1 ? SelectionType.NODE : SelectionType.NODES
  return SelectionType.MIXED
}

const emptyRectToNull = (rect: Rect | null) =>
  rect === null || (rect.w === 0 && rect.h === 0) ? null : rect

const initialState = {
  nodes: [] as string[],
  edges: [] as string[],
  rect: null as Rect | null,
  type: SelectionType.NONE,
  nodeColor: null as string | null,
  borderColor: null as string | null,
  textColor: null as string | null,
  textAlign: null as HAlign | null,
  textSize: null as FontSize | null,
  // text: null as string | null,
  edgeColor: null as string | null,
  targetShape: null as LineEndShape | null,
  sourceShape: null as LineEndShape | null,
  lineStyle: null as LineStyle | null,
  ctrlDist: null as string | null,
  ctrlWeight: null as string | null,
  nodeWidth: undefined as number | undefined,
  nodeHeight: undefined as number | undefined,
  isImageNode: false,
}
type SelectionState = typeof initialState
type SelectionData = Partial<
  Pick<
    SelectionState,
    | 'nodeColor'
    | 'borderColor'
    | 'textColor'
    | 'edgeColor'
    | 'textAlign'
    | 'textSize'
    | 'edgeColor'
    | 'targetShape'
    | 'sourceShape'
    | 'lineStyle'
    | 'ctrlDist'
    | 'ctrlWeight'
    | 'nodeWidth'
    | 'nodeHeight'
  >
>

export const selection = {
  state: initialState,
  reducers: {
    setIsImageNode: (s: SelectionState, p: boolean): SelectionState => {
      s.isImageNode = p
      return s
    },
    setSelection: (
      s: SelectionState,
      p: Pick<SelectionState, 'nodes' | 'edges' | 'type' | 'rect'>,
    ): SelectionState => {
      s.nodes = p.nodes
      s.edges = p.edges
      s.type = p.type
      s.rect = emptyRectToNull(p.rect)
      return s
    },
    setSelectionData: (s: SelectionState, p: SelectionData): SelectionState => {
      if (p.nodeColor) s.nodeColor = p.nodeColor
      if (p.borderColor) s.borderColor = p.borderColor
      if (p.textColor) s.textColor = p.textColor
      if (p.edgeColor) s.edgeColor = p.edgeColor
      if (p.textAlign) s.textAlign = p.textAlign
      if (p.textSize) s.textSize = p.textSize
      if (p.targetShape) s.targetShape = p.targetShape
      if (p.sourceShape) s.sourceShape = p.sourceShape
      if (p.lineStyle) s.lineStyle = p.lineStyle
      if (p.ctrlDist) s.ctrlDist = p.ctrlDist
      if (p.ctrlWeight) s.ctrlWeight = p.ctrlWeight
      s.nodeWidth = p.nodeWidth
      s.nodeHeight = p.nodeHeight
      return s
    },
    setRect: (s: SelectionState, p: Rect | null): SelectionState => {
      s.rect = emptyRectToNull(p)
      return s
    },
  },
  effects: (d: RootDispatch) => ({
    async updateSelection(_: unknown, s: RootState) {
      const core = requireCore(s)
      const selection = core.$(':selected')
      const rect = toRect(selection.renderedBoundingBox({}))
      const nodes = selection.filter('node').map(n => n.id())
      const edges = selection.filter('edge').map(e => e.id())
      const type = getSelectionType(nodes, edges)
      const prevType = s.selection.type
      const context = selectCurrentContext(s)
      if (context && type !== prevType) {
        unbindVariant(context, prevType)
        bindVariant(context, type, d)
      }
      d.selection.setSelection({
        nodes,
        edges,
        rect,
        type,
      })
      d.selection.setIsImageNode(
        selection.length === 1 && selection.hasClass('image'),
      )
    },
    async updateSelectionData(_: unknown, s: RootState) {
      const core = requireCore(s)
      const selection = core.$(':selected')
      const nodeColor = selection.data('nodeColor')
      const borderColor = selection.data('borderColor')
      const textColor = selection.data('textColor')
      const edgeColor = selection.data('edgeColor')
      const textAlign = selection.data('textAlign')
      const textSize = selection.data('textSize')
      const targetShape = selection.data('targetShape')
      const sourceShape = selection.data('sourceShape')
      const lineStyle = selection.data('lineStyle')
      const ctrlDist = selection.data('ctrlDist')
      const ctrlWeight = selection.data('ctrlWeight')
      const nodeWidth = selection.data('nodeWidth')
      const nodeHeight = selection.data('nodeHeight')
      d.selection.setSelectionData({
        nodeColor,
        borderColor,
        textColor,
        edgeColor,
        textAlign,
        textSize,
        targetShape,
        sourceShape,
        lineStyle,
        ctrlDist,
        ctrlWeight,
        nodeWidth,
        nodeHeight,
      })
    },
    async updateSelectionRect(_: unknown, s: RootState) {
      const core = s.editor.core
      if (core) d.selection.setRect(getSelectionRect(core))
    },
    async updateSelectionRectIfNotNull(_: unknown, s: RootState) {
      const core = s.editor.core
      if (core && s.selection.rect) d.selection.setRect(getSelectionRect(core))
    },
  }),
}
export type SelectionModel = typeof selection
