import React, { useEffect } from 'react'
import sanitizeHtml from 'sanitize-html'
import { stringEnumIncludes } from '../common/util'

enum SUPPORTED_TYPES {
  PLAIN = 'text/plain',
  HTML = 'text/html',
}

const getClipboardText = (dt: DataTransfer | null): string | undefined => {
  if (dt === null) return undefined
  if (dt.types.length !== 1) return undefined
  const type = dt.types[0]
  if (!stringEnumIncludes(SUPPORTED_TYPES, type)) {
    alert(`Pasting ${type} is not supported`)
    return undefined
  }
  return dt.getData(type)
}

export const useSanitizedPaste = (ref: React.RefObject<HTMLDivElement>) => {
  useEffect(() => {
    const current = ref.current
    if (current) {
      const handlePaste = (e: ClipboardEvent) => {
        e.preventDefault()
        const text = getClipboardText(e.clipboardData)
        if (text) {
          const sanitized = sanitizeHtml(text, {
            allowedTags: ['div', 'br'],
            allowedAttributes: {},
            selfClosing: ['div', 'br'],
          })
          document.execCommand('insertHTML', false, sanitized)
        }
      }
      current.addEventListener('paste', handlePaste)
      return () => {
        current.removeEventListener('paste', handlePaste)
      }
    }
  }, [ref])
}
