import React, { useLayoutEffect, useCallback } from 'react'
import { RootState, RootDispatch } from '../store'
import { RematchDispatcher } from '@rematch/core'
import { useSelector } from 'react-redux'
import { useRematchDispatch } from './useRematchDispatcher'

export const useDOMEffectFromState = (
  ref: React.RefObject<HTMLElement>,
  effect: (e: HTMLElement) => void,
  selector: (s: RootState) => boolean,
  dispatcher: (d: RootDispatch) => RematchDispatcher<boolean, void>,
) => {
  const flag = useSelector(selector)
  const setFlag = useRematchDispatch(dispatcher)
  const callback = useCallback(effect, [])
  useLayoutEffect(() => {
    const ele = ref.current
    if (ele && flag) {
      callback(ele)
      setFlag(false)
    }
  }, [flag, setFlag, ref, callback])
}
