import React from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import CommandColorBtn from '../controls/CommandColorBtn'
import TextAlignBtn from '../controls/TextAlignBtn'
import TextSizeBtn from '../controls/TextSizeBtn'
import CommandBtn from '../controls/CommandBtn'

//#region styles
const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1px;
  & > div + div {
    margin-left: 1px;
  }
`
//#endregion

const EditTextMenu: React.FC = () => {
  const { textColor, edges } = useSelector((s: RootState) => s.selection)
  const showAlign = edges.length === 0
  return (
    <Container>
      {showAlign && <TextAlignBtn />}
      <TextSizeBtn />
      <CommandColorBtn command="set text color" color={textColor || 'white'} />
      <CommandBtn command="split node edit" />
      <CommandBtn command="stop editing text" />
    </Container>
  )
}

export default EditTextMenu
