import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { CommandName } from '../../types'
import { ReactComponent as LeftIcon } from '../../icons/text-align-left.svg'
import { ReactComponent as CenterIcon } from '../../icons/text-align-center.svg'
import { ReactComponent as RightIcon } from '../../icons/text-align-right.svg'
import CommandBtn from './CommandBtn'

const COMMAND: CommandName = 'set text align'
const TextAlignBtn: React.FC = () => {
  const { textAlign } = useSelector((s: RootState) => s.selection)
  const icon = useMemo(() => {
    switch (textAlign) {
      case null:
      case 'left':
        return <LeftIcon />
      case 'center':
        return <CenterIcon />
      case 'right':
        return <RightIcon />
    }
  }, [textAlign])
  return <CommandBtn command={COMMAND}>{icon}</CommandBtn>
}

export default TextAlignBtn
