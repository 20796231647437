import React, {
  useRef,
  useCallback,
  useState,
  useLayoutEffect,
  useMemo,
  useEffect,
} from 'react'
import { MissingCyCoreError } from '../types'
import { css } from '@emotion/core'
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable'
import { RootState, RootDispatch } from '../store'
import { focusAtEnd, getRangeRect, getEdgeTextClasses } from '../common/util'
import { useDOMEffectFromState } from '../hooks/useDOMEffectFromState'
import { useSelector } from 'react-redux'
import { getEleText } from '../common/graph'
import {
  isEmptyRect,
  getDistanceToClampRect,
  toRect,
  shrinkRect,
} from '../common/geometry'
import { useRematchDispatch } from '../hooks/useRematchDispatcher'
import styled from '../config/theme'
import * as editText from '../commands/editText'
import { useSanitizedPaste } from '../hooks/useSanitizedPaste'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  max-width: ${props => props.theme.sizes.nodeLabelMaxWidth};
  transform-origin: center;
`

const editorStyle = css`
  padding: 2px 4px;
  word-break: break-word;
  outline: none;
  cursor: text;

  &:empty {
    padding: 2px 8px;
    opacity: 0.2;
  }
`

const EdgeTextEditor: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [text, setText] = useState<string>('')
  const [flushText, setFlushText] = useState<boolean>(false)
  const {
    core,
    edgeId,
    zoom,
    viewportRect,
    selectionRect,
    textColor,
    textSize,
    edgeColor,
  } = useSelector((s: RootState) => ({
    core: s.editor.core,
    edgeId: s.selection.edges[0],
    zoom: s.viewport.zoom,
    viewportRect: s.viewport.rect,
    selectionRect: s.selection.rect,
    textColor: s.selection.textColor,
    textSize: s.selection.textSize,
    edgeColor: s.selection.edgeColor,
  }))
  if (!selectionRect)
    throw new Error('node text editor expects selection rect to exist')
  const {
    enterEditTextMode,
    leaveEditTextMode,
    updateSelectionRect,
  } = useRematchDispatch((d: RootDispatch) => ({
    enterEditTextMode: d.editor.enterEditTextMode,
    leaveEditTextMode: d.editor.leaveEditTextMode,
    updateSelectionRect: d.selection.updateSelectionRectIfNotNull,
  }))
  if (!core) throw new MissingCyCoreError()
  const center = useMemo(() => {
    const edge = core.$id(edgeId)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const mid = (edge as any).renderedMidpoint()
    return mid
    // use selectionRect as a proxy dependency for the edge midpoint
    // so that this triggers when panning and zooming
    // eslint-disable-next-line
  }, [core, edgeId, selectionRect])
  const style = useMemo(
    () => ({
      transform: `translate(${center.x}px, ${center.y}px) translate(-50%, -50%) scale(${zoom})`,
    }),
    [center, zoom],
  )

  useSanitizedPaste(ref)
  // allow focus from actions
  useDOMEffectFromState(
    ref,
    (e: HTMLElement) => {
      e.focus()
      focusAtEnd(e)
    },
    (s: RootState) => s.textEditor.doFocus,
    (d: RootDispatch) => d.textEditor.setDoFocus,
  )
  // allow blur from actions
  useDOMEffectFromState(
    ref,
    (e: HTMLElement) => e.blur(),
    (s: RootState) => s.textEditor.doBlur,
    (d: RootDispatch) => d.textEditor.setDoBlur,
  )
  // set text from cytoscape when a new node is selected
  useLayoutEffect(() => {
    setText(getEleText(core, edgeId))
    setFlushText(false)
    const node = core.$id(edgeId)
    node.data('editing', true)
    return () => {
      node.removeData('editing')
    }
  }, [edgeId, core])
  // pan viewport to keep selection range in view
  useEffect(() => {
    const handleSelectionChange = () => {
      const rangeRect = getRangeRect()
      if (!rangeRect || isEmptyRect(rangeRect)) return
      const padding = 20 * core.zoom()
      // make the bottom big enough not to overlap buttons
      const safeRect = shrinkRect(viewportRect, {
        left: Math.max(32, padding),
        right: Math.max(32, padding),
        top: Math.max(32, padding),
        bottom: Math.max(104, padding),
      })
      const d = getDistanceToClampRect(toRect(rangeRect), safeRect)
      if (d.x !== 0 || d.y !== 0) core.panBy(d)
    }
    document.addEventListener('selectionchange', handleSelectionChange)
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange)
    }
  }, [edgeId, core, viewportRect])
  // update selection rect when node text changes
  useEffect(() => {
    const ele = core.$id(edgeId)
    ele.on('data', updateSelectionRect)
    return () => {
      ele.off('data', undefined, updateSelectionRect)
    }
  }, [edgeId, core, updateSelectionRect])
  // update the cy element whenever the text is updated by typing
  useEffect(() => {
    if (flushText) {
      const current = ref.current
      if (current) {
        const inputRect = current.getBoundingClientRect()
        const width = inputRect.width / zoom
        const height = inputRect.height / zoom
        editText.set(text, width, height)
        setFlushText(false)
      }
    }
  }, [core, text, flushText, setFlushText, edgeId, ref, zoom])
  useEffect(() => {
    const current = ref.current
    if (current) {
      let triggerRefocus = true

      const handleFocus = () => {
        editText.start(core, edgeId)
        if (triggerRefocus) {
          // this is a hack to fix the first focus bug
          setTimeout(() => {
            current.blur()
            triggerRefocus = false
          }, 10)
          setTimeout(() => {
            current.focus()
          }, 20)
        } else {
          enterEditTextMode()
        }
      }

      const handleBlur = () => {
        if (triggerRefocus) return
        leaveEditTextMode()
        editText.end()
        document.getSelection()?.removeAllRanges()
      }

      current.addEventListener('focus', handleFocus)
      current.addEventListener('blur', handleBlur)
      return () => {
        current.removeEventListener('focus', handleFocus)
        current.removeEventListener('blur', handleBlur)
      }
    }
  }, [edgeId, ref, enterEditTextMode, leaveEditTextMode, core])
  const handleChange = useCallback(
    (e: ContentEditableEvent) => {
      const newText = e.target.value
      setText(newText)
      setFlushText(true)
    },
    [setText, setFlushText],
  )
  const styleClasses = useMemo(() => {
    const data = { ...core.$id(edgeId).data() }
    if (textColor) data.textColor = textColor
    if (textSize) data.textSize = textSize
    return getEdgeTextClasses(data)
    // edgeColor is an extra dependency to trigger rerender
    // eslint-disable-next-line
  }, [edgeId, core, textColor, textSize, edgeColor])
  return (
    <Container style={style}>
      <ContentEditable
        className={['mousetrap', styleClasses].join(' ')}
        css={editorStyle}
        innerRef={ref}
        html={text}
        onChange={handleChange}
      />
    </Container>
  )
}

export default EdgeTextEditor
