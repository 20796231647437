import { editor, EditorModel } from './editor'
import { auth, AuthModel } from './auth'
import { viewport, ViewportModel } from './viewport'
import { selection, SelectionModel } from './selection'
import { textEditor, TextEditorModel } from './textEditor'

export interface RootModel {
  editor: EditorModel
  auth: AuthModel
  viewport: ViewportModel
  selection: SelectionModel
  textEditor: TextEditorModel
}

export const models: RootModel = {
  editor,
  auth,
  viewport,
  selection,
  textEditor,
}
