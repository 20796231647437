import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import ShapePickerMenu from './ShapePickerMenu'

const TargetShapePickerMenu: React.FC = () => {
  const { targetShape } = useSelector((s: RootState) => s.selection)
  return (
    <ShapePickerMenu
      shape={targetShape || 'none'}
      arrowCommand="pick target arrow"
      circleCommand="pick target circle"
      squareCommand="pick target square"
      noneCommand="pick target none"
    />
  )
}

export default TargetShapePickerMenu
