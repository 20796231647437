import React from 'react'
import styled from '../config/theme'

const Container = styled.div`
  color: inherit;
  user-select: none;
`

const AnonInfo: React.FC = () => {
  return <Container>Log in to save your work</Container>
}

export default AnonInfo
