import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import CommandShapeBtn from './CommandShapeBtn'
import { ReactComponent as NoneIcon } from '../../icons/source-shape-none.svg'
import { ReactComponent as SquareIcon } from '../../icons/source-shape-square.svg'
import { ReactComponent as CircleIcon } from '../../icons/source-shape-circle.svg'
import { ReactComponent as ArrowIcon } from '../../icons/source-shape-arrow.svg'

const CommandSourceShapeBtn: React.FC = () => {
  const { sourceShape } = useSelector((s: RootState) => s.selection)
  return (
    <CommandShapeBtn
      command="source style"
      shape={sourceShape || 'none'}
      NoneIcon={<NoneIcon />}
      SquareIcon={<SquareIcon />}
      CircleIcon={<CircleIcon />}
      ArrowIcon={<ArrowIcon />}
    />
  )
}

export default CommandSourceShapeBtn
