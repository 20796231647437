export const focusSelection = (core: cytoscape.Core): void => {
  const selected = core.$(':selected')
  const unselected = core.$(':unselected')
  if (selected.size() > 0) {
    selected.removeData('unfocus')
    unselected.data('unfocus', true)
  } else {
    unselected.removeData('unfocus')
    unselected.select().unselect()
  }
}
