import React from 'react'
import styled from '../config/theme'
import { authProvider } from '../config/firestore'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import TitleButton from './TitleButton'

const Container = styled.div``

const Login: React.FC = () => {
  return (
    <Container>
      <TitleButton
        onClick={() => {
          firebase.auth().signInWithPopup(authProvider)
        }}
      >
        Log in
      </TitleButton>
    </Container>
  )
}

export default Login
