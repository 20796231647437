import React, { useCallback, useState } from 'react'
import styled from '../config/theme'
import { useSelector } from 'react-redux'
import { RootState, RootDispatch } from '../store'
import TitleButton from './TitleButton'
import NotesMenu from './NotesMenu'
import NoteMenu from './NoteMenu'
import { useRematchDispatch } from '../hooks/useRematchDispatcher'
import TitleInput from './TitleInput'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  & > * + * {
    margin-left: 4px;
  }
`
const TitleDecoration = styled.div`
  color: ${props => props.theme.colors.subtext};
  pointer-events: none;
  user-select: none;
`

const Public = styled.div`
  border-radius: ${props => props.theme.borderRadius};
  color: black;
  font-size: 0.8em;
  background: ${props => props.theme.colors.subtext};
  padding: 2px 4px;
  user-select: none;
`

interface TitleProps {
  update: (newTitle: string) => void
  original: string
}
const TitleEditor: React.FC<TitleProps> = ({ original, update }) => {
  const [titleText, setTitleText] = useState(original)
  const handleChangeTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTitleText(e.target.value)
    },
    [setTitleText],
  )
  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      update(e.target.value)
    },
    [update],
  )
  const handleKey = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' || e.key === 'Tab' || e.key === 'Escape') {
        e.preventDefault()
        e.stopPropagation()
        update(e.currentTarget.value)
      }
    },
    [update],
  )

  return (
    <TitleInput
      type="text"
      onChange={handleChangeTitle}
      onBlur={handleBlur}
      value={titleText}
      autoFocus={true}
      onKeyDown={handleKey}
    />
  )
}

const NoteInfo: React.FC = () => {
  const { title, isEditingTitle, isPublic, viewMode } = useSelector(
    (s: RootState) => s.editor,
  )
  const { updateTitle } = useRematchDispatch((d: RootDispatch) => d.editor)

  const [showNotesMenu, setShowNotesMenu] = useState(false)
  const toggleNotesMenu = useCallback(() => {
    setShowNotesMenu(s => !s)
  }, [setShowNotesMenu])

  const [showNoteMenu, setShowNoteMenu] = useState(false)
  const toggleNoteMenu = useCallback(() => {
    if (!viewMode) setShowNoteMenu(s => !s)
  }, [setShowNoteMenu, viewMode])

  return (
    <Container>
      <TitleButton onClick={toggleNotesMenu}>Notes</TitleButton>
      <TitleDecoration>{'>'}</TitleDecoration>
      {isEditingTitle ? (
        <TitleEditor original={title} update={updateTitle} />
      ) : (
        <TitleButton onClick={toggleNoteMenu}>{title}</TitleButton>
      )}
      {isPublic && !viewMode && <Public>public</Public>}
      {isPublic && viewMode && <Public>read only</Public>}
      {showNotesMenu && <NotesMenu toggle={toggleNotesMenu} />}
      {showNoteMenu && <NoteMenu toggle={toggleNoteMenu} />}
    </Container>
  )
}

export default NoteInfo
