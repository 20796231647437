import { debounce } from 'lodash'
import {
  CommandConfig,
  ActionDispatcher,
  SVGComponent,
  HAlign,
  FontSize,
  EdgeRelationship,
  LineStyle,
  LineEndShape,
} from '../types'
import { RootDispatch } from '../store'
import { Hue, Lightness } from './theme'
import { ReactComponent as ZoomInIcon } from '../icons/zoom-in.svg'
import { ReactComponent as ZoomOutIcon } from '../icons/zoom-out.svg'
import { ReactComponent as ZoomFitIcon } from '../icons/zoom-fit.svg'
import { ReactComponent as PanIcon } from '../icons/pan.svg'
import { ReactComponent as XIcon } from '../icons/x.svg'
import { ReactComponent as PasteStyleIcon } from '../icons/paste-style.svg'
import { ReactComponent as CopyIcon } from '../icons/copy.svg'
import { ReactComponent as DeleteIcon } from '../icons/delete.svg'
import { ReactComponent as ConnectTargetIcon } from '../icons/connect-target-node.svg'
import { ReactComponent as ConnectSourceIcon } from '../icons/connect-source-node.svg'
import { ReactComponent as AddTargetIcon } from '../icons/add-target-node.svg'
import { ReactComponent as AddSourceIcon } from '../icons/add-source-node.svg'
import { ReactComponent as EditTextIcon } from '../icons/edit-text.svg'
import { ReactComponent as CopyStyleIcon } from '../icons/copy-style.svg'
import { ReactComponent as SetTargetIcon } from '../icons/set-target-node.svg'
import { ReactComponent as SetSourceIcon } from '../icons/set-source-node.svg'
import { ReactComponent as SaveIcon } from '../icons/save.svg'
import { ReactComponent as AddNodesIcon } from '../icons/add-node.svg'
import { ReactComponent as UndoIcon } from '../icons/undo.svg'
import { ReactComponent as RedoIcon } from '../icons/redo.svg'
import { ReactComponent as TextAlignLeftIcon } from '../icons/text-align-left.svg'
import { ReactComponent as TextAlignCenterIcon } from '../icons/text-align-center.svg'
import { ReactComponent as TextAlignRightIcon } from '../icons/text-align-right.svg'
import { ReactComponent as SIcon } from '../icons/text-size-small.svg'
import { ReactComponent as MIcon } from '../icons/text-size-medium.svg'
import { ReactComponent as LIcon } from '../icons/text-size-large.svg'
import { ReactComponent as XLIcon } from '../icons/text-size-extra-large.svg'
import { ReactComponent as SourceShapeNoneIcon } from '../icons/source-shape-none.svg'
import { ReactComponent as SourceShapeSquareIcon } from '../icons/source-shape-square.svg'
import { ReactComponent as SourceShapeCircleIcon } from '../icons/source-shape-circle.svg'
import { ReactComponent as SourceShapeArrowIcon } from '../icons/source-shape-arrow.svg'
import { ReactComponent as TargetShapeNoneIcon } from '../icons/target-shape-none.svg'
import { ReactComponent as TargetShapeSquareIcon } from '../icons/target-shape-square.svg'
import { ReactComponent as TargetShapeCircleIcon } from '../icons/target-shape-circle.svg'
import { ReactComponent as TargetShapeArrowIcon } from '../icons/target-shape-arrow.svg'
import { ReactComponent as LineStyleNormalIcon } from '../icons/line-style-normal.svg'
import { ReactComponent as LineStyleThickIcon } from '../icons/line-style-thick.svg'
import { ReactComponent as LineStyleDashIcon } from '../icons/line-style-dash.svg'
import { ReactComponent as AdjustCurveIcon } from '../icons/adjust-curve.svg'
import { ReactComponent as ResetAdjustCurveIcon } from '../icons/reset-adjust-curve.svg'
import { ReactComponent as ToggleKeybindsIcon } from '../icons/toggle-keybinds.svg'
import { ReactComponent as ExportIcon } from '../icons/export.svg'
import { ReactComponent as ManualSizeIcon } from '../icons/manual-size.svg'
import { ReactComponent as LayoutForceIcon } from '../icons/layout-force.svg'
import { ReactComponent as LayoutColumnIcon } from '../icons/layout-column.svg'
import { ReactComponent as LayoutRowIcon } from '../icons/layout-row.svg'
import { ReactComponent as LayoutMenuIcon } from '../icons/layout-menu.svg'
import { ReactComponent as LayoutTreeIcon } from '../icons/layout-tree.svg'
import { ReactComponent as LayoutGridIcon } from '../icons/layout-grid.svg'
import { ReactComponent as LayoutExpandIcon } from '../icons/layout-expand.svg'
import { ReactComponent as LayoutContractIcon } from '../icons/layout-contract.svg'
import { ReactComponent as SplitIcon } from '../icons/split.svg'
import { ReactComponent as JoinIcon } from '../icons/join.svg'
import { ReactComponent as ModifySelectionIcon } from '../icons/modify-selection.svg'
import { ReactComponent as SelectPredecessorsIcon } from '../icons/select-predecessors.svg'
import { ReactComponent as SelectSuccessorsIcon } from '../icons/select-successors.svg'
import { ReactComponent as DeselectNodesIcon } from '../icons/deselect-nodes.svg'
import { ReactComponent as DeselectEdgesIcon } from '../icons/deselect-edges.svg'
import { ReactComponent as FocusIcon } from '../icons/focus.svg'

const cmd = ({
  a,
  d,
  i,
}: {
  a: ActionDispatcher
  d?: string
  i?: SVGComponent
}): CommandConfig => ({
  action: a,
  description: d,
  icon: i,
})
const copy: ActionDispatcher = (d: RootDispatch) => {
  d.editor.copy()
}
const paste: ActionDispatcher = (d: RootDispatch) => {
  d.editor.paste()
}
const copyStyle: ActionDispatcher = (d: RootDispatch) => {
  d.editor.copyStyle()
}
const pasteStyle: ActionDispatcher = (d: RootDispatch) => {
  d.editor.pasteStyle()
}
const noop: ActionDispatcher = (d: RootDispatch) => {
  d.editor.noop()
}
const enterPan: ActionDispatcher = (d: RootDispatch) => {
  d.editor.enterPanningMode()
}
const leavePan: ActionDispatcher = (d: RootDispatch) => {
  d.editor.leavePanningMode()
}
const zoomIn: ActionDispatcher = (d: RootDispatch) => {
  d.viewport.zoomIn()
}
const zoomOut: ActionDispatcher = (d: RootDispatch) => {
  d.viewport.zoomOut()
}
const resetZoom: ActionDispatcher = (d: RootDispatch) => {
  d.viewport.resetZoom()
}
const zoomFit: ActionDispatcher = (d: RootDispatch) => {
  d.viewport.zoomFit()
}
const clearSelection: ActionDispatcher = (d: RootDispatch) => {
  d.editor.clearSelection()
}
const focus: ActionDispatcher = (d: RootDispatch) => {
  d.textEditor.focus()
}
const blur: ActionDispatcher = (d: RootDispatch) => {
  d.textEditor.blur()
}
const pickHue = (hue: Hue): ActionDispatcher => (d: RootDispatch) => {
  d.editor.pickHue(hue)
}
const pickLightness = (lightness: Lightness): ActionDispatcher => (
  d: RootDispatch,
) => {
  d.editor.pickLightness(lightness)
}
const tryHue = (hue: Hue): ActionDispatcher => (d: RootDispatch) => {
  d.editor.tryHue(hue)
}
const tryLightness = (lightness: Lightness): ActionDispatcher => (
  d: RootDispatch,
) => {
  d.editor.tryLightness(lightness)
}
const enterPickNodeColor = (d: RootDispatch) => {
  d.editor.enterPickNodeColor()
}
const enterPickBorderColor = (d: RootDispatch) => {
  d.editor.enterPickBorderColor()
}
const enterPickTextColor = (d: RootDispatch) => {
  d.editor.enterPickTextColor()
}
const enterPickEdgeColor = (d: RootDispatch) => {
  d.editor.enterPickEdgeColor()
}
const cancelPickColor = (d: RootDispatch) => {
  d.editor.leavePickColor()
}
const save = (d: RootDispatch) => {
  d.editor.save()
}
const exportGraph = (d: RootDispatch) => {
  d.editor.exportGraph()
}
const focusNextNode = (d: RootDispatch) => {
  d.editor.focusNextNode()
}
const deleteSelected = (d: RootDispatch) => {
  d.editor.deleteSelected()
}
const undo = (d: RootDispatch) => {
  d.editor.undo()
}
const redo = (d: RootDispatch) => {
  d.editor.redo()
}

//#region add nodes command actions
const enterAddNodesMode = (rel?: EdgeRelationship) => (d: RootDispatch) => {
  d.editor.enterAddNodesMode(rel)
}
const cancelAddNodes = (d: RootDispatch) => {
  d.editor.cancelAddNodes()
}
const addMoreOn = (d: RootDispatch) => {
  d.editor.setAddMore(true)
}
const addMoreOff = (d: RootDispatch) => {
  d.editor.setAddMore(false)
}
const dropNewNode = (d: RootDispatch) => {
  d.editor.dropNewNode()
}
//#endregion

//#region text align
const enterPickTextAlign = (d: RootDispatch) => {
  d.editor.enterPickTextAlign()
}
const pickTextAlign = (align: HAlign): ActionDispatcher => (
  d: RootDispatch,
) => {
  d.editor.pickTextAlign(align)
}
const leavePickTextAlign = (d: RootDispatch) => {
  d.editor.leavePickTextAlign()
}
//#endregion

//#region text size
const enterPickTextSize = (d: RootDispatch) => {
  d.editor.enterPickTextSize()
}
const pickTextSize = (size: FontSize): ActionDispatcher => (
  d: RootDispatch,
) => {
  d.editor.pickTextSize(size)
}
const leavePickTextSize = (d: RootDispatch) => {
  d.editor.leavePickTextSize()
}
//#endregion

//#region edge line style
const enterPickLineStyle = (d: RootDispatch) => {
  d.editor.enterPickLineStyle()
}
const pickLineStyle = (shape: LineStyle): ActionDispatcher => (
  d: RootDispatch,
) => {
  d.editor.pickLineStyle(shape)
}
const leavePickLineStyle = (d: RootDispatch) => {
  d.editor.leavePickLineStyle()
}
//#endregion

//#region edge endpoint shape
const enterPickEndShape = (rel: EdgeRelationship): ActionDispatcher => (
  d: RootDispatch,
) => {
  d.editor.enterPickEndShape(rel)
}
const pickEndShape = (shape: LineEndShape): ActionDispatcher => (
  d: RootDispatch,
) => {
  d.editor.pickEndShape(shape)
}
const leavePickEndShape = (d: RootDispatch) => {
  d.editor.leavePickEndShape()
}
//#endregion

//#region connect nodes
const connectNodes = (rel: EdgeRelationship): ActionDispatcher => (
  d: RootDispatch,
) => {
  d.editor.enterConnectNodesMode(rel)
}
const commitConnectNodes = (d: RootDispatch) => {
  d.editor.commitConnectNodes()
}
const cancelConnectNodes = (d: RootDispatch) => {
  d.editor.cancelConnectNodes()
}
//#endregion

//#region redirect edges
const redirectEdges = (rel: EdgeRelationship): ActionDispatcher => (
  d: RootDispatch,
) => {
  d.editor.enterRedirectEdgesMode(rel)
}
const commitRedirectEdges = (d: RootDispatch) => {
  d.editor.commitRedirectEdges()
}
const cancelRedirectEdges = (d: RootDispatch) => {
  d.editor.cancelRedirectEdges()
}
//#endregion

//#region adjust curve
const adjustCurve = (d: RootDispatch) => {
  d.editor.enterAdjustCurveMode()
}
const resetAdjustCurve = (d: RootDispatch) => {
  d.editor.resetAdjustCurve()
}
const leaveAdjustCurveMode = (d: RootDispatch) => {
  d.editor.leaveAdjustCurveMode()
}
//#endregion

const toggleShowKeybinds = (d: RootDispatch) => {
  d.editor.toggleShowKeybinds()
}

const toggleManualSize = (d: RootDispatch) => {
  d.editor.toggleManualSize()
}

//#region layout
const enterLayoutMode = (d: RootDispatch) => {
  d.editor.enterLayoutMode()
}
const leaveLayoutMode = (d: RootDispatch) => {
  d.editor.leaveLayoutMode()
}
const forceLayout = (d: RootDispatch) => {
  d.editor.forceLayout()
}
const columnLayout = (d: RootDispatch) => {
  d.editor.columnLayout()
}
const rowLayout = (d: RootDispatch) => {
  d.editor.rowLayout()
}
const treeLayout = (d: RootDispatch) => {
  d.editor.treeLayout()
}
const gridLayout = (d: RootDispatch) => {
  d.editor.gridLayout()
}
const expandLayout = (d: RootDispatch) => {
  d.editor.expandLayout()
}
const contractLayout = (d: RootDispatch) => {
  d.editor.contractLayout()
}
//#endregion

//#region manipulate nodes
const splitNode = (d: RootDispatch) => {
  d.editor.splitNode()
}
const splitNodeEdit = (d: RootDispatch) => {
  d.editor.splitNodeEdit()
}
const joinNodes = (d: RootDispatch) => {
  d.editor.joinNodes()
}
//#endregion

//#region modify selection
const enterModifySelectionMode = (d: RootDispatch) => {
  d.editor.enterModifySelectionMode()
}
const leaveModifySelectionMode = (d: RootDispatch) => {
  d.editor.leaveModifySelectionMode()
}
const selectSuccessors = (d: RootDispatch) => {
  d.editor.selectSuccessors()
}
const selectPredecessors = (d: RootDispatch) => {
  d.editor.selectPredecessors()
}
const deselectNodes = (d: RootDispatch) => {
  d.editor.deselectNodes()
}
const deselectEdges = (d: RootDispatch) => {
  d.editor.deselectEdges()
}
//#endregion

const focusSelection = (d: RootDispatch) => {
  d.editor.focusSelection()
}

const openLink = (d: RootDispatch) => {
  d.editor.openLink()
}

export const commandConfigs = {
  // graph selection
  noop: cmd({ a: noop }), // use this to disable default keys
  'toggle show keybinds': cmd({ a: toggleShowKeybinds, i: ToggleKeybindsIcon }),
  'add nodes': cmd({ a: enterAddNodesMode(), i: AddNodesIcon }),
  save: cmd({
    a: debounce(save, 1000, { leading: true, trailing: false }),
    i: SaveIcon,
  }),
  export: cmd({ a: exportGraph, i: ExportIcon }),
  paste: cmd({ a: paste }),
  'show command palette': cmd({ a: noop }),
  undo: cmd({ a: undo, i: UndoIcon }),
  redo: cmd({ a: redo, i: RedoIcon }),
  'enter pan mode': cmd({
    a: enterPan,
    i: PanIcon,
  }),
  'zoom out': cmd({ a: zoomOut, i: ZoomOutIcon }),
  'zoom in': cmd({ a: zoomIn, i: ZoomInIcon }),
  'reset zoom': cmd({ a: resetZoom }),
  'zoom to selection': cmd({ a: zoomFit, i: ZoomFitIcon }),
  // layout
  layout: cmd({ a: enterLayoutMode, i: LayoutMenuIcon }),
  'cancel layout': cmd({ a: leaveLayoutMode, i: XIcon }),
  'force layout': cmd({ a: forceLayout, i: LayoutForceIcon }),
  'column layout': cmd({ a: columnLayout, i: LayoutColumnIcon }),
  'row layout': cmd({ a: rowLayout, i: LayoutRowIcon }),
  'tree layout': cmd({ a: treeLayout, i: LayoutTreeIcon }),
  'grid layout': cmd({ a: gridLayout, i: LayoutGridIcon }),
  'expand layout': cmd({ a: expandLayout, i: LayoutExpandIcon }),
  'contract layout': cmd({ a: contractLayout, i: LayoutContractIcon }),
  // mixed selection
  'clear selection': cmd({ a: clearSelection, i: XIcon }),
  'paste style': cmd({ a: pasteStyle, i: PasteStyleIcon }),
  copy: cmd({ a: copy, i: CopyIcon }),
  'delete elements': cmd({ a: deleteSelected, i: DeleteIcon }),
  // nodes selection
  'connect to target node': cmd({
    a: connectNodes('target'),
    i: ConnectTargetIcon,
  }),
  'connect to source node': cmd({
    a: connectNodes('source'),
    i: ConnectSourceIcon,
  }),
  'add connected target nodes': cmd({
    a: enterAddNodesMode('target'),
    i: AddTargetIcon,
  }),
  'add connected source nodes': cmd({
    a: enterAddNodesMode('source'),
    i: AddSourceIcon,
  }),
  'set node color': cmd({ a: enterPickNodeColor }),
  'set border color': cmd({ a: enterPickBorderColor }),
  // single node selection
  'copy style': cmd({ a: copyStyle, i: CopyStyleIcon }),
  'edit text': cmd({ a: focus, i: EditTextIcon }),
  'toggle manual size': cmd({ a: toggleManualSize, i: ManualSizeIcon }),
  // single edge selection
  'set target node': cmd({ a: redirectEdges('target'), i: SetTargetIcon }),
  'set source node': cmd({ a: redirectEdges('source'), i: SetSourceIcon }),
  'target style': cmd({ a: enterPickEndShape('target') }),
  'source style': cmd({ a: enterPickEndShape('source') }),
  'line style': cmd({ a: enterPickLineStyle }),
  'adjust curve': cmd({ a: adjustCurve, i: AdjustCurveIcon }),
  reverse: cmd({ a: noop }),
  'set edge color': cmd({ a: enterPickEdgeColor }),
  // panning
  'leave pan mode': cmd({ a: leavePan, i: PanIcon }),
  // add nodes
  'cancel add nodes': cmd({ a: cancelAddNodes, i: XIcon }),
  'add more on': cmd({ a: addMoreOn }),
  'add more off': cmd({ a: addMoreOff }),
  'place node': cmd({ a: dropNewNode }),
  // command palette
  'close command palette': cmd({ a: noop }),
  'execute command': cmd({ a: noop }),
  'select previous item': cmd({ a: noop }),
  'select next item': cmd({ a: noop }),
  // connect nodes
  'cancel connect nodes': cmd({ a: cancelConnectNodes, i: XIcon }),
  'connect node': cmd({ a: commitConnectNodes }),
  // redirect edges
  'cancel redirect edges': cmd({ a: cancelRedirectEdges, i: XIcon }),
  'commit redirect edges': cmd({ a: commitRedirectEdges }),
  // pick color
  'cancel pick color': cmd({ a: cancelPickColor, i: XIcon }),
  'pick red': cmd({ a: pickHue('red') }),
  'pick orange': cmd({ a: pickHue('orange') }),
  'pick yellow': cmd({ a: pickHue('yellow') }),
  'pick green': cmd({ a: pickHue('green') }),
  'pick cyan': cmd({ a: pickHue('cyan') }),
  'pick blue': cmd({ a: pickHue('blue') }),
  'pick purple': cmd({ a: pickHue('purple') }),
  'pick pink': cmd({ a: pickHue('pink') }),
  'pick gray': cmd({ a: pickHue('gray') }),
  'pick darkest': cmd({ a: pickLightness(Lightness.DARKEST) }),
  'pick dark': cmd({ a: pickLightness(Lightness.DARK) }),
  'pick mid': cmd({ a: pickLightness(Lightness.MID) }),
  'pick light': cmd({ a: pickLightness(Lightness.LIGHT) }),
  'pick lightest': cmd({ a: pickLightness(Lightness.LIGHTEST) }),
  'try red': cmd({ a: tryHue('red') }),
  'try orange': cmd({ a: tryHue('orange') }),
  'try yellow': cmd({ a: tryHue('yellow') }),
  'try green': cmd({ a: tryHue('green') }),
  'try cyan': cmd({ a: tryHue('cyan') }),
  'try blue': cmd({ a: tryHue('blue') }),
  'try purple': cmd({ a: tryHue('purple') }),
  'try pink': cmd({ a: tryHue('pink') }),
  'try gray': cmd({ a: tryHue('gray') }),
  'try darkest': cmd({ a: tryLightness(Lightness.DARKEST) }),
  'try dark': cmd({ a: tryLightness(Lightness.DARK) }),
  'try mid': cmd({ a: tryLightness(Lightness.MID) }),
  'try light': cmd({ a: tryLightness(Lightness.LIGHT) }),
  'try lightest': cmd({ a: tryLightness(Lightness.LIGHTEST) }),
  // edit text
  'stop editing text': cmd({ a: blur, i: XIcon }),
  'set text size': cmd({ a: enterPickTextSize }),
  'set text align': cmd({ a: enterPickTextAlign }),
  'set text color': cmd({ a: enterPickTextColor }),
  'focus next node': cmd({ a: focusNextNode }),
  // text size
  'cancel pick text size': cmd({ a: leavePickTextSize, i: XIcon }),
  'pick small': cmd({ a: pickTextSize('s'), i: SIcon }),
  'pick medium': cmd({ a: pickTextSize('m'), i: MIcon }),
  'pick large': cmd({ a: pickTextSize('l'), i: LIcon }),
  'pick extra large': cmd({ a: pickTextSize('xl'), i: XLIcon }),
  // text align
  'cancel pick text align': cmd({ a: leavePickTextAlign, i: XIcon }),
  'pick left': cmd({ a: pickTextAlign('left'), i: TextAlignLeftIcon }),
  'pick center': cmd({ a: pickTextAlign('center'), i: TextAlignCenterIcon }),
  'pick right': cmd({ a: pickTextAlign('right'), i: TextAlignRightIcon }),
  // edge line style
  'cancel pick line style': cmd({ a: leavePickLineStyle, i: XIcon }),
  'pick normal': cmd({ a: pickLineStyle('normal'), i: LineStyleNormalIcon }),
  'pick thick': cmd({ a: pickLineStyle('thick'), i: LineStyleThickIcon }),
  'pick dash': cmd({ a: pickLineStyle('dash'), i: LineStyleDashIcon }),
  // edge endpoint style
  'cancel pick end shape': cmd({ a: leavePickEndShape, i: XIcon }),
  'pick source none': cmd({ a: pickEndShape('none'), i: SourceShapeNoneIcon }),
  'pick source arrow': cmd({
    a: pickEndShape('triangle'),
    i: SourceShapeArrowIcon,
  }),
  'pick source square': cmd({
    a: pickEndShape('square'),
    i: SourceShapeSquareIcon,
  }),
  'pick source circle': cmd({
    a: pickEndShape('circle'),
    i: SourceShapeCircleIcon,
  }),
  'pick target none': cmd({ a: pickEndShape('none'), i: TargetShapeNoneIcon }),
  'pick target arrow': cmd({
    a: pickEndShape('triangle'),
    i: TargetShapeArrowIcon,
  }),
  'pick target square': cmd({
    a: pickEndShape('square'),
    i: TargetShapeSquareIcon,
  }),
  'pick target circle': cmd({
    a: pickEndShape('circle'),
    i: TargetShapeCircleIcon,
  }),
  // adjust curve mode
  'cancel adjust curve': cmd({ a: leaveAdjustCurveMode, i: XIcon }),
  'reset adjust curve': cmd({ a: resetAdjustCurve, i: ResetAdjustCurveIcon }),
  // manipulate nodes
  'split node': cmd({ a: splitNode, i: SplitIcon }),
  'split node edit': cmd({ a: splitNodeEdit, i: SplitIcon }),
  'join nodes': cmd({ a: joinNodes, i: JoinIcon }),
  // modify selection
  'modify selection': cmd({
    a: enterModifySelectionMode,
    i: ModifySelectionIcon,
  }),
  'cancel modify selection': cmd({ a: leaveModifySelectionMode, i: XIcon }),
  'select successors': cmd({ a: selectSuccessors, i: SelectSuccessorsIcon }),
  'select predecessors': cmd({
    a: selectPredecessors,
    i: SelectPredecessorsIcon,
  }),
  'deselect nodes': cmd({ a: deselectNodes, i: DeselectNodesIcon }),
  'deselect edges': cmd({ a: deselectEdges, i: DeselectEdgesIcon }),
  'focus selection': cmd({ a: focusSelection, i: FocusIcon }),
  'open link': cmd({ a: openLink, i: XIcon }),
}
