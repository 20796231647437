/*eslint-disable no-mixed-operators*/
import React, { useCallback, useRef, useState, useEffect } from 'react'
import styled from '../config/theme'
import { useSelector } from 'react-redux'
import { RootState, RootDispatch } from '../store'
import { Point, MissingCyCoreError } from '../types'
import { throttle } from 'lodash'
import { useRematchDispatch } from '../hooks/useRematchDispatcher'
import { useOnClickOutside } from '../hooks/useOnClickOutside'

const size = 20
const modelToRendered = (p: Point, zoom: number, pan: Point): Point => ({
  x: p.x * zoom + pan.x,
  y: p.y * zoom + pan.y,
})
const renderedToModel = (p: Point, zoom: number, pan: Point): Point => ({
  x: (p.x - pan.x) / zoom,
  y: (p.y - pan.y) / zoom,
})
const minus = (p1: Point, p2: Point): Point => ({
  x: p1.x - p2.x,
  y: p1.y - p2.y,
})
const magnitude = (p: Point): number => Math.sqrt(p.x * p.x + p.y * p.y)
const getDotStyle = (p: Point): React.CSSProperties => ({
  transform: `translate(${p.x}px, ${p.y}px) translate(-50%, -50%)`,
})

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  color: ${props => props.theme.colors.primary};
`
const Dot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${size}px;
  height: ${size}px;
  border-radius: 50%;
  background: ${props => props.theme.colors.primary};
  border: 2px solid ${props => props.theme.colors.surface};
  box-shadow: ${props => props.theme.shadows.m};
  cursor: grab;
`

const CurveHandles: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { ctrlDist, ctrlWeight } = useSelector((s: RootState) => s.selection)
  const { zoom, pan } = useSelector((s: RootState) => s.viewport)
  const { core } = useSelector((s: RootState) => s.editor)
  if (!core) throw new MissingCyCoreError()
  if (!ctrlDist || !ctrlWeight)
    throw new Error('missing control point parameters')
  const { adjustCurve, leaveAdjustCurveMode } = useRematchDispatch(
    (d: RootDispatch) => d.editor,
  )
  useOnClickOutside(ref, leaveAdjustCurveMode)
  const { updateSelectionRect } = useRematchDispatch(
    (d: RootDispatch) => d.selection,
  )

  const [cList, setCList] = useState<Point[]>([])
  const [reset, setReset] = useState(true)
  useEffect(() => {
    const controlPoints = core.$('edge:selected').controlPoints()
    if (controlPoints !== undefined) {
      if (reset) {
        updateSelectionRect()
        setReset(false)
      }
      setCList(controlPoints.map(c => modelToRendered(c, zoom, pan)))
    }
  }, [setCList, zoom, reset, updateSelectionRect, core, pan])

  const startDrag = useCallback(
    (e: React.MouseEvent) => {
      const edge = core.$('edge:selected')
      const initialOffset: Point = {
        x: e.nativeEvent.offsetX - size / 2,
        y: e.nativeEvent.offsetY - size / 2,
      }
      const container = (e.target as HTMLElement).parentElement?.parentElement
      if (!container)
        throw new Error('expected curve handle grandparent to exist')
      document.body.style.cursor = 'grabbing'
      const handleDrag = throttle((e2: MouseEvent) => {
        e2.preventDefault()
        const a = core.$id(edge.source().id()).position()
        const b = core.$id(edge.target().id()).position()
        setCList(edge.controlPoints().map(c => modelToRendered(c, zoom, pan)))

        if (isNaN(edge.targetEndpoint().x) || isNaN(edge.sourceEndpoint().x)) {
          adjustCurve({
            ctrlDist: '0',
            ctrlWeight: '0.5',
          })
          const evt = document.createEvent('MouseEvents')
          evt.initEvent('mouseup', true, true)
          container.dispatchEvent(evt)
          setReset(true)
          return
        }
        const renderedC = {
          x: e2.x - container.offsetLeft - initialOffset.x,
          y: e2.y - container.offsetTop - initialOffset.y,
        }
        const c = renderedToModel(renderedC, zoom, pan)
        const ab = minus(b, a)
        const abSquared = ab.x * ab.x + ab.y * ab.y
        const w = ((c.x - a.x) * ab.x + (c.y - a.y) * ab.y) / abSquared
        const p = {
          x: a.x + ab.x * w,
          y: a.y + ab.y * w,
        }
        const pc = minus(c, p)
        const isPositive =
          (ab.x === 0 || ab.x > 0 === pc.y > 0) &&
          (ab.y === 0 || ab.y > 0 !== pc.x > 0)
        const dist = (isPositive ? 1 : -1) * magnitude(pc)

        adjustCurve({
          ctrlDist: `${dist}`,
          ctrlWeight: `${w}`,
        })
      }, 32)
      const handleStyle = () => {
        updateSelectionRect()
      }
      core.on('style', handleStyle)
      const handleMouseup = () => {
        document.body.style.cursor = 'default'
        container.removeEventListener('mousemove', handleDrag)
        core.off('style', undefined, handleStyle)
      }
      container.addEventListener('mousemove', handleDrag)
      container.addEventListener('mouseup', handleMouseup)
    },
    [core, zoom, adjustCurve, pan, updateSelectionRect],
  )
  return (
    <Container ref={ref}>
      {cList.map((c, i) => (
        <Dot
          key={i}
          style={getDotStyle({ x: c.x, y: c.y })}
          onMouseDown={startDrag}
        />
      ))}
    </Container>
  )
}

export default CurveHandles
