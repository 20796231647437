import React, { useRef, useEffect, useState } from 'react'
import styled, {
  textColorStyles,
  textSizeStyles,
  textAlignStyles,
  edgeColorStyles,
} from '../config/theme'
import { useRematchDispatch } from '../hooks/useRematchDispatcher'
import { RootDispatch, RootState } from '../store'
import { useWatchSize } from '../hooks/useWatchSize'
import { useSelector } from 'react-redux'
import { useClickToDrag } from '../hooks/useClickToDrag'
import { SelectionType } from '../types'
import SelectionBox from './SelectionBox'
import ContextMenu from './menus/ContextMenu'
import NodeTextEditor from './NodeTextEditor'
import { selectCurrentContext, selectPreviousContext } from '../models/editor'
import { useFreezeScroll } from '../hooks/useFreezeScroll'
import { nodeLabelStyle } from '../styles'
import EdgeTextEditor from './EdgeTextEditor'
import CurveHandles from './CurveHandles'
import NodeSizeHandles from './NodeSizeHandles'
import { emptyRect } from '../common/geometry'
import { preventEvent, handleDrop } from '../dndFiles'
import { useMiddleClickToDrag } from '../hooks/useMiddleClickToDrag'
import { useExtension } from '../hooks/useExtension'

//#region styles
const Layout = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  ${textColorStyles}
  ${textSizeStyles}
  ${textAlignStyles}
  ${edgeColorStyles}
`

const Container = styled.div`
  position: absolute;
  .cy-label {
    box-sizing: border-box;
    padding: 8px;
    word-break: break-word;
    max-width: ${props => props.theme.sizes.nodeLabelMaxWidth};
    user-select: none;
    overflow-y: auto;
    ::-webkit-scrollbar {
      background: transparent;
    }
    position: relative;
  }
  .cy-link {
    position: absolute;
    top: -6px;
    left: 0;
  }
  .cy-edge-label {
    ${nodeLabelStyle}
    max-width: ${props => props.theme.sizes.nodeLabelMaxWidth};
    user-select: none;
    padding: 2px 4px;
  }
  .cy-edge-label:empty {
    padding: 0;
  }
  .editing {
    opacity: 0;
  }
  .unfocus {
    opacity: 0.1;
  }
`
//#endregion

const GraphEditor: React.FC = () => {
  const layoutRef = useRef(null)
  const containerRef = useRef(null)
  const {
    initGraph,
    setRect,
    importGraph,
    createImageNode,
  } = useRematchDispatch((d: RootDispatch) => ({
    setRect: d.viewport.setRect,
    initGraph: d.editor.initGraph,
    importGraph: d.editor.importGraph,
    createImageNode: d.editor.createImageNode,
  }))
  const rect = useSelector((s: RootState) => s.viewport.rect)
  const {
    rect: selectionRect,
    type: selectionType,
    nodeWidth,
    nodeHeight,
    isImageNode,
  } = useSelector((s: RootState) => s.selection)
  const { viewMode } = useSelector((s: RootState) => s.editor)
  const context = useSelector(selectCurrentContext)
  const previousContext = useSelector(selectPreviousContext)
  const showBox = selectionRect !== null
  const showContextMenu =
    !viewMode && selectionRect !== null && selectionType !== SelectionType.NONE
  const showNodeTextEditor =
    !viewMode &&
    selectionRect &&
    selectionType === SelectionType.NODE &&
    !isImageNode
  const showEdgeTextEditor =
    !viewMode &&
    context !== 'adjust curve mode' &&
    selectionRect &&
    selectionType === SelectionType.EDGE
  const showCurveHandles = context === 'adjust curve mode'
  const showNodeSizeHandles =
    ((selectionRect && isImageNode) || showNodeTextEditor) &&
    context === 'graph selection' &&
    nodeWidth !== undefined &&
    nodeHeight !== undefined

  useWatchSize(layoutRef, setRect)
  useClickToDrag()
  useMiddleClickToDrag(containerRef)
  useFreezeScroll(layoutRef)
  useExtension()

  useEffect(() => {
    const container = containerRef.current
    if (container) initGraph(container)
  }, [containerRef, initGraph])

  useEffect(() => {
    const handler = handleDrop(importGraph, createImageNode)
    document.body.addEventListener('dragenter', preventEvent)
    document.body.addEventListener('dragleave', preventEvent)
    document.body.addEventListener('dragover', preventEvent)
    document.body.addEventListener('drop', handler)
    return () => {
      document.body.removeEventListener('dragenter', preventEvent)
      document.body.removeEventListener('dragleave', preventEvent)
      document.body.removeEventListener('dragover', preventEvent)
      document.body.removeEventListener('drop', handler)
    }
  }, [importGraph, createImageNode])

  const [cachedSelectionRect, setCachedSelectionRect] = useState(emptyRect())
  useEffect(() => {
    if (selectionRect !== null) setCachedSelectionRect(selectionRect)
  }, [selectionRect])

  const [resizing, setResizing] = useState(false)

  return (
    <Layout ref={layoutRef}>
      <Container
        ref={containerRef}
        style={{
          width: rect.w,
          height: rect.h,
        }}
      />
      <SelectionBox show={showBox} rect={cachedSelectionRect} />
      <ContextMenu
        selectionRect={cachedSelectionRect}
        viewportRect={rect}
        context={context === 'panning' ? previousContext : context}
        type={selectionType}
        offset={8}
        show={showContextMenu}
      />
      {showNodeTextEditor && <NodeTextEditor resizing={resizing} />}
      {showEdgeTextEditor && <EdgeTextEditor />}
      {showCurveHandles && <CurveHandles />}
      {showNodeSizeHandles && <NodeSizeHandles setResizing={setResizing} />}
    </Layout>
  )
}

export default GraphEditor
