import styled from '../config/theme'
import AutosizeInput from 'react-input-autosize'

const TitleInput = styled(AutosizeInput)`
  & input {
    padding: 4px 8px;
    border-radius: ${props => props.theme.borderRadius};
    font-size: 1em;
    color: white;
    background: ${props => props.theme.colors.base};
    border: none;
    outline: none;
  }
`
export default TitleInput
