import React, { useRef } from 'react'
import styled from '../config/theme'
import { useSelector } from 'react-redux'
import { selectLoggedIn } from '../models/auth'
import { RootDispatch, RootState } from '../store'
import { useDOMEffectFromState } from '../hooks/useDOMEffectFromState'
import NoteInfo from './NoteInfo'
import AnonInfo from './AnonInfo'
import UserInfo from './UserInfo'
import Login from './Login'

const ANIMATION_CLASS = 'login-anim'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${props => props.theme.colors.overlay};
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  color: ${props => props.theme.colors.subtext};
  background: ${props => props.theme.colors.base};

  &.${ANIMATION_CLASS} {
    animation: notify-login 0.6s forwards;
  }

  @keyframes notify-login {
    0% {
      color: ${props => props.theme.colors.subtext};
    }
    25% {
      color: ${props => props.theme.colors.primary};
    }
    50% {
      color: ${props => props.theme.colors.subtext};
    }
    75% {
      color: ${props => props.theme.colors.primary};
    }
    100% {
      color: ${props => props.theme.colors.subtext};
    }
  }
`

const Topbar: React.FC = () => {
  const loggedIn = useSelector(selectLoggedIn)
  const ref = useRef<HTMLDivElement>(null)
  useDOMEffectFromState(
    ref,
    (e: HTMLElement) => {
      e.classList.add(ANIMATION_CLASS)
      // console.log('add class')
      window.setTimeout(() => {
        e.classList.remove(ANIMATION_CLASS)
      }, 1100)
    },
    (s: RootState) => s.editor.shouldNotifyLogin,
    (d: RootDispatch) => d.editor.setShouldNotifyLogin,
  )
  return (
    <Container ref={ref}>
      {loggedIn ? <NoteInfo /> : <AnonInfo />}
      {loggedIn ? <UserInfo /> : <Login />}
    </Container>
  )
}

export default Topbar
