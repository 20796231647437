import React, { memo } from 'react'
import styled, { Lightness, Theme } from '../../config/theme'
import { CommandName } from '../../types'
import { SerializedStyles } from '@emotion/core'
import 'rc-tooltip/assets/bootstrap.css'
import CommandBtn from './CommandBtn'
import { useTheme } from 'emotion-theming'

const Swatch = styled.div`
  transition-duration: 0.2s;
  transition-timing-function: ${props => props.theme.easing.easeOutQuad};
  transition-property: width, height, border-radius, background, border;
`

interface Props {
  command: CommandName
  color: string
  shiftCommand?: CommandName
  active?: boolean
  outline?: boolean
  innerCss?: SerializedStyles
}

// const getSize = (active: boolean, theme: Theme) =>
//   `calc(${theme.sizes.buttonSize} * ${active ? 0.9 : 0.6}`
const getSize = (active: boolean, theme: Theme) => '80%'

const CommandColorBtn: React.FC<Props> = ({
  command,
  shiftCommand,
  active,
  outline,
  color,
}) => {
  const theme = useTheme<Theme>()
  const size = getSize(!!active, theme)
  const style = {
    width: size,
    height: size,
    borderRadius: active ? theme.borderRadius : '50%',
    background: outline ? 'transparent' : color,
    border: outline
      ? `5px solid ${color}`
      : `1px solid ${theme.hues.gray[Lightness.MID]}`,
  }
  return (
    <CommandBtn command={command} shiftCommand={shiftCommand}>
      <Swatch style={style} />
    </CommandBtn>
  )
}

export default memo(CommandColorBtn)
