import React from 'react'
import CommandColorBtn from '../controls/CommandColorBtn'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import TextAlignBtn from '../controls/TextAlignBtn'
import TextSizeBtn from '../controls/TextSizeBtn'
import CommandBtn from '../controls/CommandBtn'
import ContextMenuDivider from './ContextMenuDivider'
import ContextMenuContainer from './ContextMenuContainer'

const NodesSelectionMenu: React.FC = () => {
  const { nodeColor, borderColor, textColor } = useSelector(
    (s: RootState) => s.selection,
  )
  return (
    <ContextMenuContainer>
      <CommandBtn command="add connected target nodes" />
      <CommandBtn command="add connected source nodes" />
      <CommandBtn command="connect to target node" />
      <CommandBtn command="connect to source node" />
      <CommandBtn command="join nodes" />
      <ContextMenuDivider />
      <CommandColorBtn
        command="set node color"
        color={nodeColor || 'white'}
        active
      />
      <CommandColorBtn
        command="set border color"
        color={borderColor || 'white'}
        outline
        active
      />
      <CommandColorBtn
        command="set text color"
        color={textColor || 'white'}
        active
      />
      <TextSizeBtn />
      <TextAlignBtn />
      <ContextMenuDivider />
      <CommandBtn command="paste style" />
      <CommandBtn command="copy" />
      <CommandBtn command="layout" />
      <CommandBtn command="delete elements" />
      <CommandBtn command="modify selection" />
      <CommandBtn command="clear selection" />
    </ContextMenuContainer>
  )
}

export default NodesSelectionMenu
