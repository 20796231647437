import React, { useMemo, useRef, useEffect } from 'react'
import styled from '../../config/theme'
import CommandColorBtn from '../controls/CommandColorBtn'
import { Lightness, getHL, Theme } from '../../config/theme'
import { RootDispatch, RootState } from '../../store'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useRematchDispatch } from '../../hooks/useRematchDispatcher'
import { useSelector } from 'react-redux'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import CommandBtn from '../controls/CommandBtn'

//#region styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing.s};
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
`
//#endregion

interface Props {
  sourceColor: string
}

const ColorPickerMenu: React.FC<Props> = ({ sourceColor }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { leavePickColor } = useRematchDispatch((d: RootDispatch) => d.editor)
  useOnClickOutside(ref, leavePickColor)
  const hl = useMemo(() => getHL(sourceColor), [sourceColor])
  const { core } = useSelector((s: RootState) => s.editor)
  useEffect(() => {
    if (core) {
      const eles = core.$(':selected')
      eles.addClass('editing')
      return () => {
        eles.removeClass('editing')
      }
    }
  }, [core])
  const theme = useTheme<Theme>()
  return (
    <Container ref={ref}>
      <Row
        css={css`
          padding-bottom: ${theme.spacing.s};
          margin-bottom: ${theme.spacing.s};
          border-bottom: 1px solid ${theme.colors.overlay};
        `}
      >
        <CommandColorBtn
          innerCss={css`
            border-top-left-radius: ${theme.borderRadius};
          `}
          command="pick darkest"
          shiftCommand="try darkest"
          color={theme.hues[hl.hue][Lightness.DARKEST]}
          active={hl.lightness === Lightness.DARKEST}
        />
        <CommandColorBtn
          command="pick dark"
          shiftCommand="try dark"
          color={theme.hues[hl.hue][Lightness.DARK]}
          active={hl.lightness === Lightness.DARK}
        />
        <CommandColorBtn
          command="pick mid"
          shiftCommand="try mid"
          color={theme.hues[hl.hue][Lightness.MID]}
          active={hl.lightness === Lightness.MID}
        />
        <CommandColorBtn
          command="pick light"
          shiftCommand="try light"
          color={theme.hues[hl.hue][Lightness.LIGHT]}
          active={hl.lightness === Lightness.LIGHT}
        />
        <CommandColorBtn
          innerCss={css`
            border-top-right-radius: ${theme.borderRadius};
          `}
          command="pick lightest"
          shiftCommand="try lightest"
          color={theme.hues[hl.hue][Lightness.LIGHTEST]}
          active={hl.lightness === Lightness.LIGHTEST}
        />
      </Row>
      <Row>
        <CommandColorBtn
          command="pick red"
          shiftCommand="try red"
          color={theme.hues.red[hl.lightness]}
          active={hl.hue === 'red'}
        />
        <CommandColorBtn
          command="pick orange"
          shiftCommand="try orange"
          color={theme.hues.orange[hl.lightness]}
          active={hl.hue === 'orange'}
        />
        <CommandColorBtn
          command="pick yellow"
          shiftCommand="try yellow"
          color={theme.hues.yellow[hl.lightness]}
          active={hl.hue === 'yellow'}
        />
        <CommandColorBtn
          command="pick green"
          shiftCommand="try green"
          color={theme.hues.green[hl.lightness]}
          active={hl.hue === 'green'}
        />
        <CommandColorBtn
          command="pick gray"
          shiftCommand="try gray"
          color={theme.hues.gray[hl.lightness]}
          active={hl.hue === 'gray'}
        />
      </Row>
      <Row>
        <CommandColorBtn
          innerCss={css`
            border-bottom-left-radius: ${theme.borderRadius};
          `}
          command="pick cyan"
          shiftCommand="try cyan"
          color={theme.hues.cyan[hl.lightness]}
          active={hl.hue === 'cyan'}
        />
        <CommandColorBtn
          command="pick blue"
          shiftCommand="try blue"
          color={theme.hues.blue[hl.lightness]}
          active={hl.hue === 'blue'}
        />
        <CommandColorBtn
          command="pick purple"
          shiftCommand="try purple"
          color={theme.hues.purple[hl.lightness]}
          active={hl.hue === 'purple'}
        />
        <CommandColorBtn
          command="pick pink"
          shiftCommand="try pink"
          color={theme.hues.pink[hl.lightness]}
          active={hl.hue === 'pink'}
        />
        <CommandBtn
          innerCss={css`
            border-bottom-right-radius: ${theme.borderRadius};
          `}
          command="cancel pick color"
        />
      </Row>
    </Container>
  )
}

export default ColorPickerMenu
