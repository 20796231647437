import cytoscape from 'cytoscape'
import { EdgeRelationship, GNEdge, InvalidCommandStateError } from '../types'
import nanoid from 'nanoid'
import { theme, Lightness } from '../config/theme'
import { getUR } from '../common/graph'

let core: cytoscape.Core | undefined
let rel: EdgeRelationship | undefined
let newEdges: string[] | undefined
let tempEdges: cytoscape.CollectionReturnValue | undefined
let selectedNodes: cytoscape.CollectionReturnValue | undefined
let handleMouseover: cytoscape.EventHandler | undefined
let handleMouseout: cytoscape.EventHandler | undefined
let handleMousedown: cytoscape.EventHandler | undefined
let lastMousedownTarget: string | undefined

const start = (_rel: EdgeRelationship, _core: cytoscape.Core): void => {
  core = _core
  rel = _rel

  selectedNodes = core.$('node:selected')
  selectedNodes.deselect()

  document.body.style.cursor = 'crosshair'

  core.boxSelectionEnabled(false)
  core.autoungrabify(true)
  core.autounselectify(true)
  newEdges = []
}

const end = (onComplete: () => void) => {
  if (!core || !selectedNodes || !newEdges) throw new InvalidCommandStateError()

  if (lastMousedownTarget) {
    const lastClicked = core.$id(lastMousedownTarget)
    lastClicked.unselectify()
    setTimeout(() => {
      lastClicked.selectify()
    }, 100)
  }

  document.body.style.cursor = 'default'

  core.boxSelectionEnabled(true)
  core.autoungrabify(false)
  core.autounselectify(false)

  core.$('node.selectHover').removeClass('selectHover')
  const result = [...newEdges]
  if (result.length === 0) {
    selectedNodes.select()
  } else {
    const first = result.shift() as string
    core.$id(first).select()
    onComplete()
  }

  core = undefined
  rel = undefined
  newEdges = undefined
  tempEdges = undefined
  selectedNodes = undefined
  handleMouseover = undefined
  handleMouseout = undefined
  handleMousedown = undefined

  return result
}

const createEdgesTo = (id: string) => {
  if (!core || !selectedNodes) throw new InvalidCommandStateError()
  core.$id(id).addClass('selectHover')
  tempEdges = core.collection()
  selectedNodes.forEach(node => {
    if (!tempEdges || !core) throw new InvalidCommandStateError()
    const newEdge: GNEdge = {
      data: {
        id: nanoid(),
        title: '',
        textColor: theme.hues.gray[Lightness.LIGHT],
        edgeColor: theme.hues.gray[Lightness.DARK],
        textSize: 's',
        source: rel === 'source' ? id : node.id(),
        target: rel === 'target' ? id : node.id(),
        lineStyle: 'normal',
        targetShape: 'triangle',
        sourceShape: 'none',
        ctrlDist: '0',
        ctrlWeight: '0.5',
      },
    }
    tempEdges.merge(core.add(newEdge))
  })
}

const removeTempEdges = () => {
  if (!core) throw new InvalidCommandStateError()
  core.$('.selectHover').removeClass('selectHover')
  if (tempEdges) tempEdges.remove()
  tempEdges = undefined
}

const grabNewEdges = (onMousedown: () => void) => {
  if (!selectedNodes || !rel || !core) throw new InvalidCommandStateError()
  const hoverId = core.scratch('_hover')
  if (hoverId) {
    createEdgesTo(hoverId)
  }
  handleMouseover = e => {
    createEdgesTo(e.target.id())
  }
  handleMouseout = e => {
    removeTempEdges()
  }
  handleMousedown = e => {
    lastMousedownTarget = e.target.id()
    onMousedown()
  }
  core.on('mouseover', 'node', handleMouseover)
  core.on('mouseout', 'node', handleMouseout)
  core.on('click', 'node', handleMousedown)
}

const dropNewEdges = () => {
  if (!core) throw new InvalidCommandStateError()
  core.off('mouseover', 'node', handleMouseover)
  core.off('mouseout', 'node', handleMouseout)
  core.off('click', 'node', handleMousedown)
}

const cancel = () => {
  removeTempEdges()
  dropNewEdges()
}

const commit = () => {
  if (!core || !newEdges || !tempEdges) throw new InvalidCommandStateError()
  const ur = getUR(core)
  ur.do('add eles', { firstTime: true, eles: tempEdges })
  newEdges.push(...tempEdges.map(e => e.id()))
  dropNewEdges()
}

export { start, grabNewEdges, dropNewEdges, cancel, commit, end }
