import cytoscape from 'cytoscape'
import { EdgeStyle, NodeStyle } from '../types'
import { getUR } from '../common/graph'
import { SetDataArg, EleDataSnapshotMap } from './undoRedoCommands'
import { theme, Lightness } from '../config/theme'

const nodeClipboard: Partial<NodeStyle> = {
  textColor: theme.hues.gray[Lightness.LIGHT],
  nodeColor: theme.hues.gray[Lightness.DARK],
  borderColor: theme.hues.gray[Lightness.DARK],
  textAlign: 'left',
  textSize: 's',
}
const edgeClipboard: Partial<EdgeStyle> = {
  textColor: theme.hues.gray[Lightness.LIGHT],
  edgeColor: theme.hues.gray[Lightness.DARK],
  textSize: 's',
  lineStyle: 'normal',
  targetShape: 'triangle',
  sourceShape: 'none',
}
let core: cytoscape.Core | undefined

const copy = (_core: cytoscape.Core) => {
  core = _core
  const node = core.$('node:selected')
  if (node.length > 0) {
    nodeClipboard.nodeColor = node.data('nodeColor')
    nodeClipboard.borderColor = node.data('borderColor')
    nodeClipboard.textColor = node.data('textColor')
    nodeClipboard.textAlign = node.data('textAlign')
    nodeClipboard.textSize = node.data('textSize')
  }
  const edge = core.$('edge:selected')
  if (edge.length > 0) {
    edgeClipboard.textColor = edge.data('textColor')
    edgeClipboard.textSize = edge.data('textSize')
    edgeClipboard.edgeColor = edge.data('edgeColor')
    edgeClipboard.lineStyle = edge.data('lineStyle')
    edgeClipboard.targetShape = edge.data('targetShape')
    edgeClipboard.sourceShape = edge.data('sourceShape')
  }
}

const getSelectedDataSnapshot = (
  eles: cytoscape.CollectionReturnValue,
): EleDataSnapshotMap => {
  return eles.reduce((acc, ele) => {
    const id = ele.id()
    acc[id] = {
      data: {
        nodeColor: ele.data('nodeColor'),
        borderColor: ele.data('borderColor'),
        edgeColor: ele.data('edgeColor'),
        textColor: ele.data('textColor'),
        textAlign: ele.data('textAlign'),
        textSize: ele.data('textSize'),
        lineStyle: ele.data('lineStyle'),
        targetShape: ele.data('targetShape'),
        sourceShape: ele.data('sourceShape'),
      },
    }
    return acc
  }, {} as EleDataSnapshotMap)
}

const paste = () => {
  if (!core) return
  const eles = core.$(':selected')
  const ur = getUR(core)
  const lastState = getSelectedDataSnapshot(eles)
  const nodes = eles.filter('node')
  nodes.scratch('_flushSize', true)
  nodes.data({
    nodeColor: nodeClipboard.nodeColor,
    borderColor: nodeClipboard.borderColor,
    textColor: nodeClipboard.textColor,
    textAlign: nodeClipboard.textAlign,
    textSize: nodeClipboard.textSize,
  })
  eles.filter('edge').data({
    textColor: edgeClipboard.textColor,
    textSize: edgeClipboard.textSize,
    edgeColor: edgeClipboard.edgeColor,
    lineStyle: edgeClipboard.lineStyle,
    targetShape: edgeClipboard.targetShape,
    sourceShape: edgeClipboard.sourceShape,
  })
  ur.do('set data', { firstTime: true, eles, lastState } as SetDataArg)
}

export { copy, paste }
