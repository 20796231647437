import {
  ContextConfig,
  CommandBinding,
  CommandName,
  KeyCombo,
  KeyEventType,
  SelectionType,
  ContextVariantMap,
  ContextName,
} from '../types'
import { getIsMac } from '../common/util'

const x = (
  commands = [] as CommandBinding[],
  variants = {} as ContextVariantMap,
): ContextConfig => ({
  commands,
  variants,
})

const b = ({
  c,
  k,
  t,
}: {
  c: CommandName
  k?: string
  t?: KeyEventType
}): CommandBinding => {
  const binding: CommandBinding = {
    command: c,
  }
  if (k) {
    binding.keyBinding = {
      combo: k as KeyCombo,
      type: t || 'keypress',
    }
  }
  return binding
}

const anySelectionCommands = [
  b({ c: 'clear selection', k: 'esc' }),
  b({ c: 'copy', k: 'meta+c' }),
  b({ c: 'copy style', k: 'c' }),
  b({ c: 'paste style', k: 'v' }),
  b({ c: 'delete elements', k: 'd' }),
  b({ c: 'set text color', k: 't' }),
  b({ c: 'set text size', k: 'g' }),
  b({ c: 'modify selection', k: 'shift+x' }),
]
const multipleNodeCommands = [b({ c: 'layout', k: 'x' })]
const onlyNodesCommands = [
  b({ c: 'connect to target node', k: 'w' }),
  b({ c: 'connect to source node', k: 'shift+w' }),
  b({ c: 'add connected target nodes', k: 'q' }),
  b({ c: 'add connected source nodes', k: 'shift+q' }),
  b({ c: 'set node color', k: 'r' }),
  b({ c: 'set border color', k: 'f' }),
  b({ c: 'set text align', k: 'b' }),
]
const onlyEdgesCommands = [
  b({ c: 'reset adjust curve', k: 'shift+w' }),
  b({ c: 'set target node', k: 'q' }),
  b({ c: 'set source node', k: 'shift+q' }),
  b({ c: 'target style', k: 'f' }),
  b({ c: 'source style', k: 'shift+f' }),
  b({ c: 'line style', k: 'b' }),
  b({ c: 'reverse' }),
  b({ c: 'set edge color', k: 'r' }),
]
const onlyOneElementCommands = [b({ c: 'edit text', k: 'enter' })]
const colorPickerCommands = [
  b({ c: 'noop', k: 'tab' }),
  b({ c: 'noop', k: 'shift+tab' }),
  b({ c: 'cancel pick color', k: 'esc' }),
  b({ c: 'pick red', k: 'q' }),
  b({ c: 'pick orange', k: 'w' }),
  b({ c: 'pick yellow', k: 'e' }),
  b({ c: 'pick green', k: 'r' }),
  b({ c: 'pick cyan', k: 'a' }),
  b({ c: 'pick blue', k: 's' }),
  b({ c: 'pick purple', k: 'd' }),
  b({ c: 'pick pink', k: 'f' }),
  b({ c: 'pick gray', k: 't' }),
  b({ c: 'pick darkest', k: '1' }),
  b({ c: 'pick dark', k: '2' }),
  b({ c: 'pick mid', k: '3' }),
  b({ c: 'pick light', k: '4' }),
  b({ c: 'pick lightest', k: '5' }),
  b({ c: 'try red', k: 'shift+q' }),
  b({ c: 'try orange', k: 'shift+w' }),
  b({ c: 'try yellow', k: 'shift+e' }),
  b({ c: 'try green', k: 'shift+r' }),
  b({ c: 'try cyan', k: 'shift+a' }),
  b({ c: 'try blue', k: 'shift+s' }),
  b({ c: 'try purple', k: 'shift+d' }),
  b({ c: 'try pink', k: 'shift+f' }),
  b({ c: 'try gray', k: 'shift+t' }),
  b({ c: 'try darkest', k: 'shift+1' }),
  b({ c: 'try dark', k: 'shift+2' }),
  b({ c: 'try mid', k: 'shift+3' }),
  b({ c: 'try light', k: 'shift+4' }),
  b({ c: 'try lightest', k: 'shift+5' }),
]
interface ContextConfigMap {
  empty: ContextConfig
  'graph selection': ContextConfig
  panning: ContextConfig
  'edit text': ContextConfig
  'pick node color': ContextConfig
  'pick border color': ContextConfig
  'pick text color': ContextConfig
  'pick edge color': ContextConfig
  'add nodes': ContextConfig
  'connect nodes': ContextConfig
  'redirect edges': ContextConfig
  'pick text align': ContextConfig
  'pick text size': ContextConfig
  'pick target shape': ContextConfig
  'pick source shape': ContextConfig
  'pick line style': ContextConfig
  'adjust curve mode': ContextConfig
  'view mode': ContextConfig
  'pick layout': ContextConfig
  'modify selection': ContextConfig
}
export const contextConfigs: ContextConfigMap = {
  empty: x(),
  'graph selection': x(
    [
      b({ c: 'noop', k: 'tab' }),
      b({ c: 'noop', k: 'shift+tab' }),
      b({ c: 'toggle show keybinds', k: 'k' }),
      b({ c: 'add nodes', k: 'a' }),
      b({ c: 'save', k: 'meta+s' }),
      b({ c: 'export', k: 'meta+shift+s' }),
      b({ c: 'paste', k: 'meta+v' }),
      b({ c: 'undo', k: 'meta+z' }),
      b({ c: 'redo', k: 'meta+shift+z' }),
      b({ c: 'enter pan mode', k: 'space', t: 'keydown' }),
      b({ c: 'zoom in', k: '=' }),
      b({ c: 'zoom out', k: '-' }),
      b({ c: 'reset zoom', k: '0' }),
      b({ c: 'zoom to selection', k: 'z' }),
      b({ c: 'focus selection', k: 'shift+z' }),
    ],
    {
      [SelectionType.NODE]: [
        b({ c: 'toggle manual size', k: 's' }),
        b({ c: 'split node', k: 'e' }),
        b({ c: 'open link', k: 'alt+x' }),
        ...onlyOneElementCommands,
        ...onlyNodesCommands,
        ...anySelectionCommands,
      ],
      [SelectionType.EDGE]: [
        b({ c: 'adjust curve', k: 'w' }),
        ...onlyOneElementCommands,
        ...onlyEdgesCommands,
        ...anySelectionCommands,
      ],
      [SelectionType.NODES]: [
        b({ c: 'join nodes', k: 'e' }),
        ...onlyNodesCommands,
        ...anySelectionCommands,
        ...multipleNodeCommands,
      ],
      [SelectionType.EDGES]: [...onlyEdgesCommands, ...anySelectionCommands],
      [SelectionType.MIXED]: [...anySelectionCommands, ...multipleNodeCommands],
    },
  ),
  panning: x([b({ c: 'leave pan mode', k: 'space', t: 'keyup' })]),
  'edit text': x([
    b({ c: 'set text color', k: 'meta+alt+t' }),
    b({ c: 'set text size', k: 'meta+alt+g' }),
    b({ c: 'set text align', k: 'meta+alt+b' }),
    b({ c: 'stop editing text', k: 'esc' }),
    b({ c: 'focus next node', k: 'tab' }),
    b({ c: 'split node edit', k: 'meta+alt+x' }),
    b({ c: 'noop', k: 'shift+tab' }),
  ]),
  'pick node color': x(colorPickerCommands),
  'pick border color': x(colorPickerCommands),
  'pick text color': x(colorPickerCommands),
  'pick edge color': x(colorPickerCommands),
  'add nodes': x([
    b({ c: 'noop', k: 'tab' }),
    b({ c: 'noop', k: 'shift+tab' }),
    b({ c: 'cancel add nodes', k: 'esc' }),
    b({ c: 'add more on', k: 'shift', t: 'keydown' }),
    b({ c: 'add more off', k: 'shift', t: 'keyup' }),
    b({ c: 'place node' }),
  ]),
  'connect nodes': x([
    b({ c: 'cancel connect nodes', k: 'esc' }),
    b({ c: 'add more on', k: 'shift', t: 'keydown' }),
    b({ c: 'add more off', k: 'shift', t: 'keyup' }),
    b({ c: 'connect node' }),
  ]),
  'redirect edges': x([
    b({ c: 'cancel redirect edges', k: 'esc' }),
    b({ c: 'commit redirect edges' }),
  ]),
  'pick text align': x([
    b({ c: 'pick left', k: '1' }),
    b({ c: 'pick center', k: '2' }),
    b({ c: 'pick right', k: '3' }),
    b({ c: 'cancel pick text align', k: 'esc' }),
  ]),
  'pick text size': x([
    b({ c: 'pick small', k: '1' }),
    b({ c: 'pick medium', k: '2' }),
    b({ c: 'pick large', k: '3' }),
    b({ c: 'pick extra large', k: '4' }),
    b({ c: 'cancel pick text size', k: 'esc' }),
  ]),
  'pick target shape': x([
    b({ c: 'cancel pick end shape', k: 'esc' }),
    b({ c: 'pick target arrow', k: '1' }),
    b({ c: 'pick target circle', k: '2' }),
    b({ c: 'pick target square', k: '3' }),
    b({ c: 'pick target none', k: '4' }),
  ]),
  'pick source shape': x([
    b({ c: 'cancel pick end shape', k: 'esc' }),
    b({ c: 'pick source arrow', k: '1' }),
    b({ c: 'pick source circle', k: '2' }),
    b({ c: 'pick source square', k: '3' }),
    b({ c: 'pick source none', k: '4' }),
  ]),
  'pick line style': x([
    b({ c: 'cancel pick line style', k: 'esc' }),
    b({ c: 'pick normal', k: '1' }),
    b({ c: 'pick dash', k: '2' }),
    b({ c: 'pick thick', k: '3' }),
  ]),
  'adjust curve mode': x([
    b({ c: 'cancel adjust curve', k: 'esc' }),
    b({ c: 'cancel adjust curve', k: 's' }),
    b({ c: 'cancel adjust curve', k: 'tab' }),
    b({ c: 'enter pan mode', k: 'space', t: 'keydown' }),
  ]),
  'view mode': x([
    b({ c: 'enter pan mode', k: 'space', t: 'keydown' }),
    b({ c: 'zoom in', k: '=' }),
    b({ c: 'zoom out', k: '-' }),
    b({ c: 'reset zoom', k: '0' }),
    b({ c: 'zoom to selection', k: 'z' }),
  ]),
  'pick layout': x([
    b({ c: 'cancel layout', k: 'esc' }),
    b({ c: 'force layout', k: '1' }),
    b({ c: 'column layout', k: '2' }),
    b({ c: 'row layout', k: '3' }),
    b({ c: 'tree layout', k: '4' }),
    b({ c: 'grid layout', k: '5' }),
    b({ c: 'expand layout', k: 'e' }),
    b({ c: 'contract layout', k: 'd' }),
  ]),
  'modify selection': x([
    b({ c: 'cancel modify selection', k: 'esc' }),
    b({ c: 'select successors', k: '1' }),
    b({ c: 'select predecessors', k: '2' }),
    b({ c: 'deselect nodes', k: '3' }),
    b({ c: 'deselect edges', k: '4' }),
  ]),
}

enum AsCommandHash {}
type CommandHash = string & AsCommandHash
enum AsFormattedKeyCombo {}
type FormattedKeyCombo = string | AsFormattedKeyCombo
const getCommandHash = (
  context: ContextName,
  command: CommandName,
): CommandHash => {
  return `${context}${command}` as CommandHash
}
const commandKeymap = new Map<CommandHash, FormattedKeyCombo>()
export const getKeybinding = (
  context: ContextName,
  command: CommandName,
): FormattedKeyCombo | undefined => {
  const ownKey = commandKeymap.get(getCommandHash(context, command))
  if (ownKey) return ownKey
  return commandKeymap.get(getCommandHash('graph selection', command))
}
const titleCaseWords = (s: string) =>
  s.replace(/\b[a-z]|['_][a-z]|\B[A-Z]/g, function(x) {
    return x[0] === "'" || x[0] === '_'
      ? x
      : String.fromCharCode(x.charCodeAt(0) ^ 32)
  })
const replaceModifiers = (s: string) =>
  s
    .replace(/shift/g, '⇧')
    .replace(/alt/g, '⌥')
    .replace(/meta/g, getIsMac() ? '⌘' : '^')
    .replace(/\+/g, '')
const formatKeyCombo = (k: KeyCombo): FormattedKeyCombo => {
  return titleCaseWords(replaceModifiers(k))
}
for (const key of Object.keys(contextConfigs)) {
  const context = key as ContextName
  const { commands, variants } = contextConfigs[context]
  const addKey = ({ command, keyBinding }: CommandBinding) => {
    if (keyBinding)
      commandKeymap.set(
        getCommandHash(context, command),
        formatKeyCombo(keyBinding.combo),
      )
  }
  commands.forEach(addKey)
  for (const variant of Object.keys(variants)) {
    const type = variant as SelectionType
    const variantCommands = variants[type]
    if (variantCommands) variantCommands.forEach(addKey)
  }
}
