import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { useRematchDispatch } from '../../hooks/useRematchDispatcher'
import { RootDispatch } from '../../store'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import CommandBtn from '../controls/CommandBtn'

//#region styles
const Container = styled.div`
  display: flex;
  flex-direction: row;
`
//#endregion

const LayoutPickerMenu: React.FC = () => {
  const { leaveLayoutMode } = useRematchDispatch((d: RootDispatch) => d.editor)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, leaveLayoutMode)
  return (
    <Container ref={ref}>
      <CommandBtn command="force layout" />
      <CommandBtn command="column layout" />
      <CommandBtn command="row layout" />
      <CommandBtn command="tree layout" />
      <CommandBtn command="grid layout" />
      <CommandBtn command="expand layout" />
      <CommandBtn command="contract layout" />
      <CommandBtn command="cancel layout" />
    </Container>
  )
}

export default LayoutPickerMenu
