import React, { useRef, useMemo } from 'react'
import styled from '../../config/theme'
import { Rect, SelectionType, ContextName } from '../../types'
import { useClampedPosition } from '../../hooks/useClampedPosition'
import MixedSelectionMenu from './MixedSelectionMenu'
import NodesSelectionMenu from './NodesSelectionMenu'
import NodeSelectionMenu from './NodeSelectionMenu'
import EdgeSelectionMenu from './EdgeSelectionMenu'
import EdgesSelectionMenu from './EdgesSelectionMenu'
import EditTextMenu from './EditTextMenu'
import NodeColorPickerMenu from './NodeColorPickerMenu'
import BorderColorPickerMenu from './BorderColorPickerMenu'
import TextColorPickerMenu from './TextColorPickerMenu'
import EdgeColorPickerMenu from './EdgeColorPickerMenu'
import TextAlignMenu from './TextAlignMenu'
import TextSizeMenu from './TextSizeMenu'
import TargetShapePickerMenu from './TargetShapePickerMenu'
import SourceShapePickerMenu from './SourceShapePickerMenu'
import LineStylePickerMenu from './LineStylePickerMenu'
import LayoutPickerMenu from './LayoutPickerMenu'
import ModifySelectionMenu from './ModifySelectionMenu'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.colors.surface};
  padding: 1px;
  box-shadow: ${props => props.theme.shadows.m};
`

interface Props {
  selectionRect: Rect
  viewportRect: Rect
  context: ContextName
  type: SelectionType
  offset?: number
  show: boolean
}

const getMenuForSelectionContext = (type: SelectionType): React.ReactNode => {
  switch (type) {
    case SelectionType.MIXED:
      return <MixedSelectionMenu />
    case SelectionType.NODES:
      return <NodesSelectionMenu />
    case SelectionType.NODE:
      return <NodeSelectionMenu />
    case SelectionType.EDGE:
      return <EdgeSelectionMenu />
    case SelectionType.EDGES:
      return <EdgesSelectionMenu />
    default:
      return `context menu (${type})`
  }
}

const getMenuFor = (
  context: ContextName,
  type: SelectionType,
): React.ReactNode => {
  switch (context) {
    case 'panning':
    case 'graph selection':
      return getMenuForSelectionContext(type)
    case 'edit text':
      return <EditTextMenu />
    case 'pick node color':
      return <NodeColorPickerMenu />
    case 'pick border color':
      return <BorderColorPickerMenu />
    case 'pick text color':
      return <TextColorPickerMenu />
    case 'pick edge color':
      return <EdgeColorPickerMenu />
    case 'pick text align':
      return <TextAlignMenu />
    case 'pick text size':
      return <TextSizeMenu />
    case 'pick target shape':
      return <TargetShapePickerMenu />
    case 'pick source shape':
      return <SourceShapePickerMenu />
    case 'pick line style':
      return <LineStylePickerMenu />
    case 'adjust curve mode':
      return null
    case 'pick layout':
      return <LayoutPickerMenu />
    case 'modify selection':
      return <ModifySelectionMenu />
    default:
      return `context menu ${context}`
  }
}

const ContextMenu: React.FC<Props> = ({
  selectionRect,
  viewportRect,
  context,
  type,
  offset,
  show,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const style = useClampedPosition({
    ref,
    viewportRect,
    selectionRect,
    offset,
  })
  const menu = useMemo(() => {
    // console.log(`render menu for ${context} (${type})`)
    return getMenuFor(context, type)
  }, [context, type])
  return (
    <Container ref={ref} style={{ ...style, display: show ? 'block' : 'none' }}>
      {menu}
    </Container>
  )
}

export default ContextMenu
