import styled from '../../config/theme'
import BtnContainer from './BtnContainer'

const BtnGroup = styled(BtnContainer)`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  & > div + div {
    margin-left: 1px;
  }
  & > :first-of-type {
    border-top-left-radius: ${props => props.theme.borderRadius};
    border-bottom-left-radius: ${props => props.theme.borderRadius};
  }
  & > :last-of-type {
    border-top-right-radius: ${props => props.theme.borderRadius};
    border-bottom-right-radius: ${props => props.theme.borderRadius};
  }
`
export default BtnGroup
