import { RootState } from '../store'
import { InvalidCommandStateError } from '../types'
import { requireCore, getUR } from '../common/graph'
import { getHL, Hue, getColor, Lightness } from '../config/theme'
import { EleDataSnapshotMap } from './undoRedoCommands'

let selectColor: ((s: RootState) => string) | undefined
let core: cytoscape.Core | undefined
let attr: string | undefined
let eles: cytoscape.CollectionReturnValue | undefined
let originalData: EleDataSnapshotMap | undefined

const getSelectedDataSnapshot = (
  eles: cytoscape.CollectionReturnValue,
  attr: string,
): EleDataSnapshotMap => {
  return eles.reduce((acc, ele) => {
    const id = ele.id()
    acc[id] = {
      data: {
        [attr]: ele.data()[attr],
      },
    }
    return acc
  }, {} as EleDataSnapshotMap)
}

const start = (
  colorSelector: (s: RootState) => string,
  dataAttr: string,
  s: RootState,
) => {
  core = requireCore(s)
  eles = core.$('*:selected')
  attr = dataAttr
  originalData = getSelectedDataSnapshot(eles, attr)
  const selectionColor = colorSelector(s)
  if (!selectionColor) throw new Error('bad color selector pass to pickColor')
  selectColor = colorSelector
}

const consumeColor = (c: string) => {
  if (!eles || !attr) throw new InvalidCommandStateError()
  eles.data(attr, c)
}

const setHue = (hue: Hue, s: RootState) => {
  if (!selectColor || !consumeColor) throw new InvalidCommandStateError()
  const { lightness } = getHL(selectColor(s))
  consumeColor(getColor(hue, lightness))
}

const setLightness = (lightness: Lightness, s: RootState) => {
  if (!selectColor || !consumeColor) throw new InvalidCommandStateError()
  const { hue } = getHL(selectColor(s))
  consumeColor(getColor(hue, lightness))
}

const end = () => {
  if (!core || !eles || !originalData) throw new InvalidCommandStateError()
  const ur = getUR(core)
  ur.do('set data', { firstTime: true, eles, lastState: originalData })
  selectColor = undefined
  core = undefined
  attr = undefined
  eles = undefined
  originalData = undefined
}

export { start, setHue, setLightness, end }
