import React, { useRef, useCallback } from 'react'
import styled from '../config/theme'
import { useOnClickOutside } from '../hooks/useOnClickOutside'
import { NoteMenuButton } from './NoteMenuButton'
import { useRematchDispatch } from '../hooks/useRematchDispatcher'
import { RootDispatch, RootState } from '..//store'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useClipboard } from 'use-clipboard-copy'

const Container = styled.div`
  position: absolute;
  top: 42px;
  left: 68px;
  padding: 8px;
  background: ${props => props.theme.colors.overlay};
  border-radius: ${props => props.theme.borderRadius};
  z-index: 50;
  width: 200px;
  box-shadow: ${props => props.theme.shadows.m};
`

const PublicURL = styled.input`
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.colors.base};
  width: 100%;
  color: white;
  border: none;
  margin-top: 4px;
  font-size: 0.8em;
  padding: 4px;
`

interface Props {
  toggle: () => void
}

const NoteMenu: React.FC<Props> = ({ toggle }) => {
  const clipboard = useClipboard({
    copiedTimeout: 1200,
  })
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => {
    toggle()
  })
  const {
    setIsEditingTitle,
    clearNote,
    deleteNote,
    makeNotePrivate,
    makeNotePublic,
  } = useRematchDispatch((d: RootDispatch) => d.editor)
  const { isPublic } = useSelector((s: RootState) => s.editor)
  const handleRename = useCallback(() => {
    setIsEditingTitle(true)
    toggle()
  }, [setIsEditingTitle, toggle])

  const history = useHistory()
  const handleDelete = useCallback(() => {
    deleteNote(history)
    toggle()
  }, [history, deleteNote, toggle])

  const handleClear = useCallback(() => {
    clearNote()
    toggle()
  }, [clearNote, toggle])

  const handlePublic = useCallback(() => {
    makeNotePrivate()
  }, [makeNotePrivate])
  const handlePrivate = useCallback(() => {
    makeNotePublic()
  }, [makeNotePublic])
  return (
    <Container ref={ref}>
      <NoteMenuButton onClick={handleRename}>Rename</NoteMenuButton>
      <NoteMenuButton onClick={handleClear}>Clear Document</NoteMenuButton>
      <NoteMenuButton onClick={handleDelete}>Delete</NoteMenuButton>
      {isPublic ? (
        <NoteMenuButton onClick={handlePublic}>Make Private</NoteMenuButton>
      ) : (
        <NoteMenuButton onClick={handlePrivate}>Make Public</NoteMenuButton>
      )}
      {isPublic && (
        <NoteMenuButton onClick={clipboard.copy}>
          {clipboard.copied ? 'Copied to Clipboard' : 'Copy Link'}
        </NoteMenuButton>
      )}
      {isPublic && (
        <PublicURL
          ref={clipboard.target}
          value={window.location.href}
          readOnly
        />
      )}
    </Container>
  )
}

export default NoteMenu
