import React, { useEffect } from 'react'
import styled from '../config/theme'
import GraphEditor from './GraphEditor'
import ViewportControls from './controls/ViewportControls'
import DocumentMenu from './controls/DocumentMenu'
import { matchPath } from 'react-router'
import { useHistory } from 'react-router-dom'
import Topbar from './Topbar'
import { useRematchDispatch } from '../hooks/useRematchDispatcher'
import { RootDispatch, RootState } from '../store'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import { useSelector } from 'react-redux'

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr;
  grid-gap: 0px;
  overflow: hidden;
  background: ${props => props.theme.colors.base};
`

interface RouteParams {
  nid: string
}

const App = () => {
  const { login, logout } = useRematchDispatch((d: RootDispatch) => d.auth)
  const { core, viewMode } = useSelector((s: RootState) => s.editor)
  const history = useHistory()

  useEffect(() => {
    if (core) {
      const unregisterAuthObserver = firebase
        .auth()
        .onAuthStateChanged(async user => {
          const match = matchPath<RouteParams>(history.location.pathname, {
            path: '/note/:nid',
            exact: true,
            strict: true,
          })
          const nid = match ? match.params.nid : null
          // console.log('auth event')
          // console.log(`nid from route: ${nid}`)
          if (user) {
            // const photoUrl = user.photoURL
            const providerPhotoUrl = user.providerData[0]?.photoURL || ''
            // if (providerPhotoUrl === '') console.warn('photo url is empty')
            // if (photoUrl !== providerPhotoUrl) {
            //   await firebase.auth().currentUser?.updateProfile({
            //     photoURL: providerPhotoUrl,
            //   })
            // }
            login({
              history,
              nid,
              user: {
                name: user.displayName || '',
                avatar: providerPhotoUrl,
                uid: user.uid,
              },
            })
          } else {
            logout({ history, nid })
          }
        })
      return () => {
        unregisterAuthObserver()
      }
    }
  }, [login, logout, history, core])
  return (
    <>
      <Container>
        <Topbar />
        <GraphEditor />
      </Container>
      {!viewMode && <DocumentMenu />}
      <ViewportControls />
    </>
  )
}

export default App
