import { GNNode, HAlign, FontSize, GNEdge } from './types'
import nanoid from 'nanoid'
import { theme, getFontSize } from './config/theme'
import { getUR } from './common/graph'
import { recenter } from './graphLayouts'
import { getCenter } from './common/geometry'

const getFirstSelectedNode = (
  core: cytoscape.Core,
): cytoscape.NodeSingular | undefined => {
  const selectedNodes = core.$(':selected').nodes()
  if (selectedNodes.length === 0) return undefined
  return selectedNodes[0]
}

const getSplitLines = (node: cytoscape.NodeSingular): string[] => {
  const text = node.data('title') as string
  // first line will just be text
  // additional lines will be wrapped in <div> tags
  // remove closing </div> from lines after first
  return text
    .split('<div>')
    .map((line, i) => (i === 0 ? line : line.substring(0, line.length - 6)))
}

const getSize = (text: string, size: FontSize, align: HAlign) => {
  // these need to be in sync with .cy-label in GraphEditor.tsx
  const container = document.createElement('div')
  container.style.boxSizing = 'border-box'
  container.style.padding = '8px'
  container.style.wordBreak = 'break-word'
  container.style.textAlign = align
  container.style.fontSize = getFontSize(size)
  container.style.maxWidth = theme.sizes.nodeLabelMaxWidth
  container.style.visibility = 'hidden'
  container.style.position = 'absolute'
  container.style.zIndex = '-1'
  container.innerHTML = text
  document.body.appendChild(container)
  const width = container.clientWidth
  const height = container.clientHeight
  container.parentNode?.removeChild(container)

  return { width, height }
}

const doSplit = (
  core: cytoscape.Core,
  node: cytoscape.NodeSingular,
  pieces: string[],
): void => {
  // store all the attributes we need to copy over from the original node
  const textColor = node.data('textColor') as string
  const nodeColor = node.data('nodeColor') as string
  const borderColor = node.data('borderColor') as string
  const textSize = node.data('textSize') as FontSize
  const textAlign = node.data('textAlign') as HAlign

  //#region create objects to describe a new node for each line
  const c1 = { ...node.position() }
  const gap = 10
  let y = c1.y
  const newNodes: GNNode[] = []
  for (let i = 0; i < pieces.length; i++) {
    const piece = pieces[i]
    const { width, height } = getSize(piece, textSize, textAlign)
    if (i > 0) y += height / 2
    const newNode: GNNode = {
      data: {
        id: nanoid(),
        title: piece,
        width,
        height,
        textColor,
        nodeColor,
        borderColor,
        textAlign,
        textSize,
      },
      position: { x: c1.x, y },
    }
    y += height / 2 + gap
    newNodes.push(newNode)
  }
  //#endregion

  //#region create objects to describe edges redirected to first split node
  const originalEdges = node.connectedEdges()
  const oldId = node.id()
  const newId = newNodes[0].data.id
  const newEdges: GNEdge[] = []
  for (const edge of originalEdges.toArray()) {
    newEdges.push({
      data: {
        id: nanoid(),
        title: edge.data('title'),
        textColor: edge.data('textColor'),
        edgeColor: edge.data('edgeColor'),
        textSize: edge.data('textSize'),
        source: edge.data('source') === oldId ? newId : edge.data('source'),
        target: edge.data('target') === oldId ? newId : edge.data('target'),
        lineStyle: edge.data('lineStyle'),
        targetShape: edge.data('targetShape'),
        sourceShape: edge.data('sourceShape'),
        ctrlDist: edge.data('ctrlDist'),
        ctrlWeight: edge.data('ctrlWeight'),
        bend: edge.data('bend'),
      },
    })
  }
  //#endregion

  //#region update graph elements
  const removedEdges = core.remove(originalEdges)

  const addedNodes = core.add(newNodes)
  recenter(addedNodes, c1)
  addedNodes.select()

  const addedEdges = core.add(newEdges)

  node.deselect()
  const removedNodes = core.remove(node)
  //#endregion

  const ur = getUR(core)
  ur.do('batch', [
    {
      name: 'add eles',
      param: { firstTime: true, eles: addedNodes },
    },
    {
      name: 'redirect edges',
      param: { firstTime: true, oldEdges: removedEdges, newEdges: addedEdges },
    },
    {
      name: 'remove eles',
      param: { firstTime: true, eles: removedNodes },
    },
  ])
}

export const splitSelectedNode = (core: cytoscape.Core): boolean => {
  const node = getFirstSelectedNode(core)
  if (node === undefined) return false

  const lines = getSplitLines(node)
  if (lines.length === 1) return false

  doSplit(core, node, lines)

  return true
}

const wrapLine = (s: string): string => {
  const i = s.indexOf('<div>')
  if (i === -1) return `<div>${s}</div>`
  return `<div>${s.substring(0, i)}</div>${s.substring(i)}`
}

export const joinSelectedNodes = (core: cytoscape.Core): boolean => {
  // get selected nodes
  const nodes = core.$(':selected').nodes()
  if (nodes.length < 2) return false

  // copy over attributes from the first node in the selection
  const sortedNodes = nodes
    .toArray()
    .sort((a, b) => a.position('y') - b.position('y'))
  const firstNode = sortedNodes[0]
  const textColor = firstNode.data('textColor') as string
  const nodeColor = firstNode.data('nodeColor') as string
  const borderColor = firstNode.data('borderColor') as string
  const textSize = firstNode.data('textSize') as FontSize
  const textAlign = firstNode.data('textAlign') as HAlign

  //#region create new node with joined text
  const joinedText = sortedNodes
    .map((node, i) => {
      if (i === 0) return node.data('title')
      else return wrapLine(node.data('title'))
    })
    .join('')
  const c1 = getCenter(nodes.boundingBox({}))
  const { width, height } = getSize(joinedText, textSize, textAlign)
  const newNode: GNNode = {
    data: {
      id: nanoid(),
      title: joinedText,
      width,
      height,
      textColor,
      nodeColor,
      borderColor,
      textAlign,
      textSize,
    },
    position: c1,
  }

  const originalEdges = nodes.connectedEdges()
  const oldIds = sortedNodes.map(node => node.id())
  const newId = newNode.data.id
  const newEdges: GNEdge[] = []
  for (const edge of originalEdges.toArray()) {
    const sourceJoined = oldIds.includes(edge.data('source'))
    const targetJoined = oldIds.includes(edge.data('target'))
    // skip edges that would point to self
    if (sourceJoined && targetJoined) continue
    newEdges.push({
      data: {
        id: nanoid(),
        title: edge.data('title'),
        textColor: edge.data('textColor'),
        edgeColor: edge.data('edgeColor'),
        textSize: edge.data('textSize'),
        source: sourceJoined ? newId : edge.data('source'),
        target: targetJoined ? newId : edge.data('target'),
        lineStyle: edge.data('lineStyle'),
        targetShape: edge.data('targetShape'),
        sourceShape: edge.data('sourceShape'),
        ctrlDist: edge.data('ctrlDist'),
        ctrlWeight: edge.data('ctrlWeight'),
        bend: edge.data('bend'),
      },
    })
  }

  const removedEdges = core.remove(originalEdges)

  const addedNodes = core.add(newNode)
  addedNodes.select()

  const addedEdges = core.add(newEdges)

  nodes.deselect()
  const removedNodes = core.remove(nodes)

  const ur = getUR(core)
  ur.do('batch', [
    {
      name: 'add eles',
      param: { firstTime: true, eles: addedNodes },
    },
    {
      name: 'redirect edges',
      param: { firstTime: true, oldEdges: removedEdges, newEdges: addedEdges },
    },
    {
      name: 'remove eles',
      param: { firstTime: true, eles: removedNodes },
    },
  ])

  return true
}

const getCaretIndex = (element: HTMLDivElement): [number, number] => {
  let start = 0
  let end = 0
  const isSupported = typeof window.getSelection !== 'undefined'
  if (isSupported) {
    const selection = window.getSelection()
    if (selection !== null && selection.rangeCount !== 0) {
      const range = selection.getRangeAt(0)

      const startRange = range.cloneRange()
      startRange.selectNodeContents(element)
      startRange.setEnd(range.startContainer, range.startOffset)
      start = startRange.toString().length

      const endRange = range.cloneRange()
      endRange.selectNodeContents(element)
      endRange.setEnd(range.endContainer, range.endOffset)
      end = endRange.toString().length
    }
  }
  return [start, end]
}

const getPieces = (ele: HTMLDivElement): string[] => {
  const text = ele.textContent
  const [start, end] = getCaretIndex(ele)
  if (text == null) return []
  const pieces: string[] = []
  const piece = (a: number, b: number) => text.substring(a, b).trim()
  pieces.push(piece(0, start))
  if (start !== end) pieces.push(piece(start, end))
  pieces.push(piece(end, text.length))
  return pieces.filter(p => p.length > 0)
}

export const splitNodeEdit = (
  core: cytoscape.Core,
  ele: HTMLDivElement,
): boolean => {
  const pieces = getPieces(ele)
  if (pieces.length < 2) return false

  const node = getFirstSelectedNode(core)
  if (node === undefined) return false

  doSplit(core, node, pieces)

  return true
}
