import {
  Rect,
  Point,
  Edges,
  cyBoundingBox,
  AnyRect,
  isCyBB,
  isDOMRect,
} from '../types'

export const emptyRect = (): Rect => ({
  x: 0,
  y: 0,
  w: 0,
  h: 0,
})
export const isEmptyRect = (rect: DOMRect): boolean =>
  rect.top === 0 && rect.bottom === 0 && rect.left === 0 && rect.right === 0

export const shrinkRectUniform = (rect: Rect, margin: number): Rect => ({
  x: rect.x + margin,
  y: rect.y + margin,
  w: rect.w - margin * 2,
  h: rect.h - margin * 2,
})
export const shrinkRect = (rect: Rect, edges: Partial<Edges>): Rect => {
  const l = edges.left || 0
  const t = edges.top || 0
  return {
    x: rect.x + l,
    y: rect.y + t,
    w: rect.w - (edges.right || 0) - l,
    h: rect.h - (edges.bottom || 0) - t,
  }
}

const getDistanceToClamp = (
  aStart: number,
  aSize: number,
  bStart: number,
  bSize: number,
): number => {
  if (aSize <= bSize) {
    const d1 = aStart - bStart
    const d2 = bStart + bSize - (aStart + aSize)
    if (d1 < 0) return -d1
    if (d2 < 0) return d2
  }
  return 0
}

export const getDistanceToClampRect = (
  target: Rect,
  container: Rect,
): Point => ({
  x: Math.floor(
    getDistanceToClamp(target.x, target.w, container.x, container.w),
  ),
  y: Math.floor(
    getDistanceToClamp(target.y, target.h, container.y, container.h),
  ),
})

export const addPoints = (a: Point, b: Point): Point => ({
  x: a.x + b.x,
  y: a.y + b.y,
})

export const subtractPoints = (a: Point, b: Point): Point => ({
  x: a.x - b.x,
  y: a.y - b.y,
})

export const edgesToRect = (edges: Edges): Rect => ({
  x: edges.left,
  y: edges.top,
  w: edges.right - edges.left,
  h: edges.bottom - edges.top,
})

export const cyBBToRect = (bb: cyBoundingBox): Rect => ({
  x: bb.x1,
  y: bb.y1,
  w: bb.w,
  h: bb.h,
})

export const DOMRectToRect = (rect: DOMRect): Rect => ({
  x: rect.x,
  y: rect.y,
  w: rect.width,
  h: rect.height,
})

export const toRect = (rectLike: AnyRect): Rect => {
  if (isCyBB(rectLike)) return cyBBToRect(rectLike)
  if (isDOMRect(rectLike)) return DOMRectToRect(rectLike)
  return rectLike
}

export const getCenter = (rectLike: AnyRect): Point => {
  if (isCyBB(rectLike))
    return {
      x: (rectLike.x1 + rectLike.x2) / 2,
      y: (rectLike.y1 + rectLike.y2) / 2,
    }
  if (isDOMRect(rectLike))
    return {
      x: (rectLike.left + rectLike.right) / 2,
      y: (rectLike.top + rectLike.bottom) / 2,
    }
  return {
    x: rectLike.x + rectLike.w / 2,
    y: rectLike.y + rectLike.h / 2,
  }
}

export const cyBBToWindowRect = (
  bb: cyBoundingBox,
  containerRect: DOMRect,
): Rect => {
  return {
    x: bb.x1 + containerRect.x,
    y: bb.y1 + containerRect.y,
    w: bb.w,
    h: bb.h,
  }
}
