import styled, { Lightness } from '../../config/theme'

const BtnBase = styled.div`
  width: ${props => props.theme.sizes.buttonSize};
  height: ${props => props.theme.sizes.buttonSize};
  cursor: pointer;
  user-select: none;
  background: transparent;
  color: ${props => props.theme.colors.text};
  :hover {
    background: ${props => props.theme.hues.gray[Lightness.DARK]};
    color: ${props => props.theme.colors.primary};
  }
`
export default BtnBase
