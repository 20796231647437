import React from 'react'
// import { css } from '@emotion/core'
import styled from '../config/theme'
import { Rect } from '../types'
import { useSpring, animated } from 'react-spring'

const Box = styled(animated.div)`
  user-select: none;
  pointer-events: none;
  background: transparent;
  border: 2px solid ${props => props.theme.colors.outline};
`

interface Props {
  rect: Rect
  show: boolean
}

const SelectionBox: React.FC<Props> = ({ rect, show }) => {
  const { x, y, w, h } = rect
  const props = useSpring({
    config: {
      clamp: true,
      precision: 1,
      duration: 1,
    },
    width: w,
    height: h,
    transform: `translate(${x}px,${y}px)`,
    opacity: show ? 1 : 0,
  })
  return <Box style={props} />
}

export default SelectionBox
