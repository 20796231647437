import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import CommandColorBtn from '../controls/CommandColorBtn'
import TextSizeBtn from '../controls/TextSizeBtn'
import CommandBtn from '../controls/CommandBtn'
import ContextMenuContainer from './ContextMenuContainer'
import ContextMenuDivider from './ContextMenuDivider'

const MixedSelectionMenu: React.FC = () => {
  const { textColor } = useSelector((s: RootState) => s.selection)
  return (
    <ContextMenuContainer>
      <CommandColorBtn
        command="set text color"
        color={textColor || 'white'}
        active
      />
      <TextSizeBtn />
      <ContextMenuDivider />
      <CommandBtn command="paste style" />
      <CommandBtn command="copy" />
      <CommandBtn command="layout" />
      <CommandBtn command="delete elements" />
      <CommandBtn command="modify selection" />
      <CommandBtn command="clear selection" />
    </ContextMenuContainer>
  )
}

export default MixedSelectionMenu
