import { theme } from './theme'

export const style: cytoscape.Stylesheet[] = [
  {
    selector: 'core',
    style: {
      'selection-box-color': theme.colors.primary,
      'selection-box-opacity': theme.opacities.highlight,
      'selection-box-border-width': 0,
      'selection-box-border-color': theme.colors.primary,
      'active-bg-color': theme.colors.primary,
      'active-bg-opacity': 0,
      'active-bg-size': 0,
      'outside-texture-bg-color': '#000',
      'outside-texture-bg-opacity': 0,
    },
  },
  {
    selector: 'node',
    style: {
      width: 'data(width)',
      height: 'data(height)',
      'background-color': 'data(nodeColor)',
      'border-color': 'data(borderColor)',
      'border-style': 'solid',
      'border-width': 2,
      'text-valign': 'center',
      shape: 'roundrectangle',
      'font-size': '1em',
    },
  },
  {
    selector: 'node:parent',
    style: {
      'text-valign': 'top',
      'text-margin-y': -8,
    },
  },
  {
    selector: 'node:selected',
    style: {
      'overlay-color': theme.colors.primary,
      'overlay-padding': 0,
      'overlay-opacity': theme.opacities.highlight,
    },
  },
  {
    selector: 'node.editing',
    style: {
      'overlay-opacity': 0,
    },
  },
  {
    selector: 'node.image',
    style: {
      'background-image': 'data(title)',
      'background-fit': 'contain',
    },
  },
  {
    selector: 'edge',
    style: {
      'curve-style': function(ele) {
        return ele.data('bend') ? 'unbundled-bezier' : 'bezier'
      },
      'edge-distances': 'node-position',
      'line-style': function(ele) {
        return ele.data('lineStyle') === 'dash' ? 'dashed' : 'solid'
      },
      width: function(ele: cytoscape.EdgeSingular) {
        return ele.data('lineStyle') === 'thick' ? 9 : 3
      },
      'line-color': 'data(edgeColor)',
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      // 'target-arrow-shape': 'data(targetShape)',
      'target-arrow-shape': function(ele) {
        return ele.data('targetShape') || 'none'
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      // 'source-arrow-shape': 'data(sourceShape)',
      'source-arrow-shape': function(ele) {
        return ele.data('sourceShape') || 'none'
      },
      'target-arrow-color': 'data(edgeColor)',
      'source-arrow-color': 'data(edgeColor)',
      'arrow-scale': 1.5,
    },
  },
  {
    selector: 'edge[bend]',
    style: {
      'control-point-distances': 'data(ctrlDist)',
      'control-point-weights': 'data(ctrlWeight)',
    },
  },
  {
    selector: 'edge:selected',
    style: {
      'overlay-color': theme.colors.primary,
      'overlay-padding': 0,
      'overlay-opacity': theme.opacities.highlight,
    },
  },
  {
    selector: 'edge.editing',
    style: {
      'overlay-opacity': 0,
    },
  },
  {
    selector: 'node:active',
    style: {
      'overlay-color': '#000',
      'overlay-padding': 0,
      'overlay-opacity': 0,
    },
  },
  {
    selector: 'edge:active',
    style: {
      'overlay-color': '#000',
      'overlay-padding': 0,
      'overlay-opacity': 0,
    },
  },
  {
    selector: 'node.selectHover',
    style: {
      'overlay-color': theme.colors.primary,
      'overlay-padding': 0,
      'overlay-opacity': theme.opacities.highlight,
    },
  },
  {
    selector: 'edge[unfocus]',
    style: {
      opacity: 0.1,
    },
  },
  {
    selector: 'node[unfocus]',
    style: {
      'background-opacity': 0.1,
      'border-opacity': 0.1,
      'background-image-opacity': 0.1,
    },
  },
]
