import { defaultElements } from '../config/elements'
import { Point } from '../types'
import { requireCore } from './graph'
import { RootState } from '../store'
import { saveAs } from 'file-saver'
import firebase from 'firebase/app'
import 'firebase/database'
import { fireStorage } from '../config/firestore'
import nanoid from 'nanoid'

const LOCAL_STORAGE_KEY = 'cy.elements'

export interface User {
  uid: string
  name: string
  avatar: string
}

export interface NoteMetadata {
  id: string
  title: string
  created: number
  modified: number
}

export interface NoteMetadataMap {
  [nid: string]: Pick<NoteMetadata, 'title' | 'created' | 'modified'>
}

export interface UserData {
  lastModifiedNote: string
  notes: NoteMetadataMap
}

export interface StoredState {
  elements: cytoscape.ElementDefinition[]
  zoom: number
  pan: Point
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isStoredState = (x: any): x is StoredState => {
  return (
    Array.isArray(x.elements) &&
    typeof x.zoom === 'number' &&
    typeof x.pan === 'object'
  )
}

// try to get elements from local storage, otherwise load default
export const getStateFromStorage = (): StoredState => {
  const fromLocalStorage = window.localStorage.getItem(LOCAL_STORAGE_KEY)
  return fromLocalStorage !== null
    ? JSON.parse(fromLocalStorage)
    : {
        elements: defaultElements,
        zoom: 1,
        pan: { x: 0, y: 0 },
      }
}

export const selectStoredState = (s: RootState): StoredState => {
  const core = requireCore(s)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const coreJson = core.json() as any
  const { zoom, pan, elements } = coreJson
  return {
    elements: [...(elements.nodes || []), ...(elements.edges || [])].map(
      ({ data, position, group, classes }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { editing, ...dataToSave } = data
        return {
          data: dataToSave,
          position,
          group,
          classes,
        }
      },
    ),
    zoom,
    pan,
  }
}

const serializeState = (s: RootState): string => {
  const storedState = selectStoredState(s)
  return JSON.stringify(storedState)
}

export const saveElementsToStorage = (s: RootState): void => {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, serializeState(s))
}

export const exportGraph = (s: RootState): void => {
  const serializedState = serializeState(s)
  const blob = new Blob([serializedState], { type: 'text/json;charset=utf-8' })
  saveAs(blob, `graphnote-${Math.floor(Date.now() / 1000)}.json`)
}

export const importGraph = (s: StoredState): void => {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(s))
  window.location.reload()
}

export const getUserData = async (uid: string): Promise<UserData> => {
  const snapshot = await firebase
    .database()
    .ref(`/users/${uid}`)
    .once('value')
  const userData: UserData = {
    lastModifiedNote: snapshot.child('lastModifiedNote').val(),
    notes: snapshot.child('notes').val() || [],
  }
  // console.log(userData)
  return userData
}

export const getPublicNoteUid = async (nid: string): Promise<string | null> => {
  const snapshot = await firebase
    .database()
    .ref(`/publicNotes/${nid}`)
    .once('value')
  const uid = snapshot.val()
  // console.log(`uid for public graph ${nid}? ${uid}`)
  if (typeof uid === 'string') return uid
  return null
}

export const getNoteMetadata = async (
  uid: string,
  nid: string,
): Promise<NoteMetadata> => {
  const metaSnapshot = await firebase
    .database()
    .ref(`/users/${uid}/notes/${nid}`)
    .once('value')
  const noteMetadata: NoteMetadata = {
    id: nid,
    title: metaSnapshot.child('title').val(),
    created: metaSnapshot.child('created').val(),
    modified: metaSnapshot.child('modified').val(),
  }
  return noteMetadata
}

export const getNoteData = async (
  uid: string,
  nid: string,
): Promise<StoredState> => {
  const dataSnapshot = await firebase
    .database()
    .ref(`/notes/${uid}/${nid}`)
    .once('value')
  const noteData: StoredState = {
    elements: dataSnapshot.child('elements').val() || [],
    zoom: dataSnapshot.child('zoom').val(),
    pan: dataSnapshot.child('pan').val(),
  }
  // console.log(`note data for ${nid}`)
  // console.log(noteData)
  return noteData
}

export const getPublicNoteData = async (
  uid: string,
  nid: string,
): Promise<StoredState & NoteMetadata> => {
  const noteMetadata = await getNoteMetadata(uid, nid)
  const noteData = await getNoteData(uid, nid)
  return {
    ...noteMetadata,
    ...noteData,
  }
}

export const makeNotePublic = (uid: string, nid: string): void => {
  firebase
    .database()
    .ref(`/publicNotes/${nid}`)
    .set(uid)
}

export const makeNotePrivate = (nid: string): void => {
  firebase
    .database()
    .ref(`/publicNotes/${nid}`)
    .remove()
}

export const getNid = (uid: string) =>
  firebase
    .database()
    .ref(`/users/${uid}/notes`)
    .push().key

export const createNote = async (
  uid: string,
  title: string,
  data: StoredState,
): Promise<string> => {
  // console.log(`create a new note: ${title}`)
  // console.log(data)
  const nid = getNid(uid)
  if (nid === null) throw new Error('could not create new note id')
  const now = Date.now()
  const noteMeta: NoteMetadata = {
    id: nid,
    title,
    created: now,
    modified: now,
  }
  firebase
    .database()
    .ref()
    .update({
      [`/users/${uid}/notes/${nid}`]: noteMeta,
      [`/users/${uid}/lastModifiedNote`]: nid,
      [`/notes/${uid}/${nid}`]: data,
    })
  return nid
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeEmpty = (obj: any) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key])
    else if (obj[key] === undefined) obj[key] = null
  })
  return obj
}

export const saveNote = async (
  uid: string,
  nid: string,
  data: StoredState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const now = Date.now()
  // console.log(`save note data ${uid} ${nid}`)
  return firebase
    .database()
    .ref()
    .update({
      [`/users/${uid}/notes/${nid}/modified`]: now,
      [`/users/${uid}/lastModifiedNote`]: nid,
      [`/notes/${uid}/${nid}`]: removeEmpty(data),
    })
}

export const saveNoteTitle = async (
  uid: string,
  nid: string,
  title: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const now = Date.now()
  // console.log(`save note title ${uid} ${nid}`)
  const partialNoteMeta: Partial<NoteMetadata> = {
    title,
    modified: now,
  }
  return firebase
    .database()
    .ref()
    .update({
      [`/users/${uid}/notes/${nid}`]: partialNoteMeta,
      [`/users/${uid}/lastModifiedNote`]: nid,
    })
}

export const deleteNote = async (
  uid: string,
  nid: string,
  nextNid: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  // console.log(`delete note ${uid} ${nid}`)
  return firebase
    .database()
    .ref()
    .update({
      [`/users/${uid}/notes/${nid}`]: null,
      [`/users/${uid}/lastModifiedNote`]: nextNid,
      [`/notes/${uid}/${nid}`]: null,
    })
}

export const uploadFile = (
  uid: string,
  f: File,
  onProgress: (progress: number) => void,
  onError: () => void,
  onSuccess: (url: string) => void,
): void => {
  const ref = fireStorage.ref().child(`uploads/${uid}/${nanoid(8)}-${f.name}`)
  const task = ref.put(f)
  task.on(
    'state_changed',
    snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      // console.log(`Upload is ${progress}% done`)
      onProgress(progress)
    },
    error => {
      console.error('Upload failed!', error)
      onError()
    },
    () => {
      task.snapshot.ref.getDownloadURL().then(onSuccess)
    },
  )
}
