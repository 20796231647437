import React, { useMemo } from 'react'
import styled from '../../config/theme'
import BtnBase from './BtnBase'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'
import { useDispatch, useSelector } from 'react-redux'
import { RootDispatch, RootState } from '../../store'
import { SerializedStyles } from '@emotion/core'
import { getCommandConfig } from '../../common/util'
import { CommandName } from '../../types'
import { selectCurrentContext } from '../../models/editor'
import { getKeybinding } from '../../config/contexts'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`
interface BtnProps {
  invert: boolean
}
const Btn = styled(BtnBase)<BtnProps>`
  padding: ${props => props.theme.spacing.m};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props =>
    props.invert ? props.theme.colors.primary : props.theme.colors.text};
  :hover {
    color: ${props => props.theme.colors.primary};
    & svg #f2,
    & svg #f2 * {
      fill: white;
    }
    & svg #s2,
    & svg #s2 * {
      stroke: white;
    }
  }
  & svg {
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
  & svg #f1,
  & svg #f1 * {
    fill: ${props =>
      props.invert ? props.theme.colors.primary : 'currentColor'};
  }
  & svg #s1,
  & svg #s1 * {
    stroke: ${props =>
      props.invert ? props.theme.colors.primary : 'currentColor'};
  }
  & svg #f2,
  & svg #f2 * {
    fill: ${props => props.theme.colors.primary};
  }
  & svg #s2,
  & svg #s2 * {
    stroke: ${props => props.theme.colors.primary};
  }
`
const KeybindLabel = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  color: ${props => props.theme.colors.subtext};
  padding: ${props => props.theme.spacing.xs};
  background: ${props => props.theme.colors.surface};
  font-size: 0.6em;
  text-align: right;
  max-width: ${props => props.theme.sizes.buttonSize};
  pointer-events: none;
  user-select: none;
`

interface Props {
  command: CommandName
  shiftCommand?: CommandName
  icon?: React.ReactNode
  innerRef?: React.RefObject<HTMLDivElement>
  innerCss?: SerializedStyles
  invert?: boolean
}

const CommandBtn: React.FC<Props> = ({
  command,
  shiftCommand,
  children,
  innerRef,
  innerCss,
  invert,
}) => {
  const dispatch = useDispatch<RootDispatch>()
  const commandConfig = getCommandConfig(command)
  const shiftCommandConfig = shiftCommand
    ? getCommandConfig(shiftCommand)
    : null
  const currentContext = useSelector(selectCurrentContext)
  const { showKeybinds } = useSelector((s: RootState) => s.editor)
  const keyBinding = useMemo(() => getKeybinding(currentContext, command), [
    currentContext,
    command,
  ])
  if (!children && !commandConfig.icon) throw new Error('SVG')
  return (
    <Tooltip
      arrowContent={null}
      mouseEnterDelay={0.1}
      mouseLeaveDelay={0}
      placement="top"
      trigger={['hover']}
      overlay={`${command} (${keyBinding})`}
    >
      <Container>
        <Btn
          css={innerCss}
          ref={innerRef}
          invert={!!invert}
          onMouseDown={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            if (shiftCommandConfig && e.shiftKey) {
              shiftCommandConfig.action(dispatch)
            } else {
              commandConfig.action(dispatch)
            }
          }}
        >
          {children ? children : commandConfig.icon}
        </Btn>
        {showKeybinds && <KeybindLabel>{keyBinding}</KeybindLabel>}
      </Container>
    </Tooltip>
  )
}

export default CommandBtn
