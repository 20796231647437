import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { useEffect } from 'react'
import { throttle } from 'lodash'
import { Point } from '../types'

export const useMiddleClickToDrag = (ref: React.RefObject<HTMLElement>) => {
  const { core } = useSelector((s: RootState) => s.editor)

  useEffect(() => {
    if (!core || !ref.current) return
    const ele = ref.current
    let p0: Point = { x: 0, y: 0 }
    let v0: Point = { x: 0, y: 0 }

    const dragViewport: cytoscape.EventHandler = throttle(e => {
      const p = e.renderedPosition
      core.pan({
        x: v0.x + (p.x - p0.x),
        y: v0.y + (p.y - p0.y),
      })
    }, 16)

    const handleDown = (e: MouseEvent): void => {
      if (e.button !== 1) return
      e.preventDefault()

      p0 = { x: e.offsetX, y: e.offsetY }
      const { x, y } = core.pan()
      v0 = { x, y }
      core.on('mousemove', dragViewport)
      document.body.style.cursor = 'grabbing'
    }

    const handleUp = (e: MouseEvent): void => {
      if (e.button !== 1) return
      e.preventDefault()

      core.off('mousemove', undefined, dragViewport)
      document.body.style.cursor = 'default'
    }

    const setup = () => {
      ele.addEventListener('mousedown', handleDown)
      ele.addEventListener('mouseup', handleUp)
    }
    const cleanup = () => {
      ele.removeEventListener('mousedown', handleDown)
      ele.removeEventListener('mouseup', handleUp)
    }

    setup()
    return () => {
      cleanup()
    }
  }, [core, ref])
}
