import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import ShapePickerMenu from './ShapePickerMenu'

const SourceShapePickerMenu: React.FC = () => {
  const { sourceShape } = useSelector((s: RootState) => s.selection)
  return (
    <ShapePickerMenu
      shape={sourceShape || 'none'}
      arrowCommand="pick source arrow"
      circleCommand="pick source circle"
      squareCommand="pick source square"
      noneCommand="pick source none"
    />
  )
}

export default SourceShapePickerMenu
