import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { useRematchDispatch } from '../../hooks/useRematchDispatcher'
import { RootDispatch, RootState } from '../../store'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { useSelector } from 'react-redux'
import CommandBtn from '../controls/CommandBtn'

//#region styles
const Container = styled.div`
  display: flex;
  flex-direction: row;
`
//#endregion

const LineStylePickerMenu: React.FC = () => {
  const { lineStyle } = useSelector((s: RootState) => s.selection)
  const { leavePickLineStyle } = useRematchDispatch(
    (d: RootDispatch) => d.editor,
  )
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, leavePickLineStyle)
  return (
    <Container ref={ref}>
      <CommandBtn command="pick normal" invert={lineStyle === 'normal'} />
      <CommandBtn command="pick dash" invert={lineStyle === 'dash'} />
      <CommandBtn command="pick thick" invert={lineStyle === 'thick'} />
      <CommandBtn command="cancel pick line style" />
    </Container>
  )
}

export default LineStylePickerMenu
