import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { useRematchDispatch } from '../../hooks/useRematchDispatcher'
import { RootDispatch } from '../../store'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import CommandBtn from '../controls/CommandBtn'

//#region styles
const Container = styled.div`
  display: flex;
  flex-direction: row;
`
//#endregion

const LayoutPickerMenu: React.FC = () => {
  const { leaveModifySelectionMode } = useRematchDispatch(
    (d: RootDispatch) => d.editor,
  )
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, leaveModifySelectionMode)
  return (
    <Container ref={ref}>
      <CommandBtn command="select successors" />
      <CommandBtn command="select predecessors" />
      <CommandBtn command="deselect nodes" />
      <CommandBtn command="deselect edges" />
      <CommandBtn command="cancel modify selection" />
    </Container>
  )
}

export default LayoutPickerMenu
