import {
  Point,
  EdgeRelationship,
  InvalidCommandStateError,
  GNEdge,
} from '../types'
import nanoid from 'nanoid'
import { theme, Lightness } from '../config/theme'
import { getUR } from '../common/graph'
import { createNewNode } from '../common/util'

let handleAddNodeMousemove: cytoscape.EventHandler | undefined
let handleAddNodeMousedown: cytoscape.EventHandler | undefined
let tempNode: cytoscape.CollectionReturnValue | undefined
let newNodes: string[] | undefined
let rel: EdgeRelationship | undefined
let core: cytoscape.Core | undefined
let selectedNodes: cytoscape.CollectionReturnValue | undefined

export const start = (_rel: EdgeRelationship, _core: cytoscape.Core): void => {
  core = _core
  rel = _rel

  selectedNodes = core.$(':selected')
  selectedNodes.deselect()

  core.boxSelectionEnabled(false)
  core.autoungrabify(true)
  core.autounselectify(true)

  newNodes = []
}

const createEdgesToTempNode = () => {
  if (!tempNode || !core || !selectedNodes) throw new InvalidCommandStateError()
  const id = tempNode.id()
  selectedNodes.forEach(node => {
    if (!core) throw new InvalidCommandStateError()
    const newEdge: GNEdge = {
      data: {
        id: nanoid(),
        title: '',
        textColor: theme.hues.gray[Lightness.LIGHT],
        edgeColor: theme.hues.gray[Lightness.DARK],
        textSize: 's',
        source: rel === 'source' ? id : node.id(),
        target: rel === 'target' ? id : node.id(),
        lineStyle: 'normal',
        targetShape: 'triangle',
        sourceShape: 'none',
        ctrlDist: '0',
        ctrlWeight: '0.5',
      },
    }
    core.add(newEdge)
  })
}

export const grabNewNode = (onMousedown: () => void) => {
  if (!core) throw new InvalidCommandStateError()
  const newNode = createNewNode({
    position: core.scratch('_mouse') as Point,
  })
  tempNode = core.add(newNode)
  if (rel) createEdgesToTempNode()

  handleAddNodeMousemove = e => {
    const { x, y } = e.renderedPosition
    tempNode?.renderedPosition({ x, y })
  }
  handleAddNodeMousedown = () => {
    onMousedown()
  }
  core.on('mousemove', handleAddNodeMousemove)
  core.on('click', handleAddNodeMousedown)
}

export const dropNewNode = (): void => {
  if (!core) throw new InvalidCommandStateError()
  core.off('mousemove', undefined, handleAddNodeMousemove)
  core.off('click', undefined, handleAddNodeMousedown)

  handleAddNodeMousemove = undefined
  handleAddNodeMousedown = undefined
  tempNode = undefined
}

export const removeNewNode = (): void => {
  if (tempNode !== undefined) tempNode.remove()
  dropNewNode()
}

export const end = (onComplete: () => void): string[] => {
  if (!core || !newNodes || !selectedNodes) throw new InvalidCommandStateError()

  const result = [...newNodes]

  core.boxSelectionEnabled(true)
  core.autoungrabify(false)
  core.autounselectify(false)

  if (result.length === 0) {
    selectedNodes.select()
  } else {
    const first = result.shift() as string
    core.$id(first).select()
    onComplete()

    const c = core
    result.forEach(n => {
      c.$id(n).unselectify()
    })
    setTimeout(() => {
      result.forEach(n => {
        c.$id(n).selectify()
      })
    }, 100)
  }

  handleAddNodeMousemove = undefined
  handleAddNodeMousedown = undefined
  tempNode = undefined
  newNodes = undefined
  rel = undefined
  core = undefined
  selectedNodes = undefined

  return result
}

export const commitNewNode = (core: cytoscape.Core): void => {
  if (!core || !newNodes || !tempNode) throw new InvalidCommandStateError()
  const ur = getUR(core)
  ur.do('add eles', { firstTime: true, eles: tempNode })
  newNodes.push(tempNode.id())
  dropNewNode()
}
