import React, { useRef, useCallback } from 'react'
import styled from '../config/theme'
import { useOnClickOutside } from '../hooks/useOnClickOutside'
import { useSelector } from 'react-redux'
import { selectNoteMetadata } from '../models/auth'
import NoteListItem from './NoteListItem'
import NewNoteButton from './NewNoteButton'
import { useRematchDispatch } from '../hooks/useRematchDispatcher'
import { RootDispatch } from '../store'
import { useHistory } from 'react-router-dom'

const Container = styled.div`
  position: absolute;
  top: 42px;
  left: 0;
  padding: 8px;
  background: ${props => props.theme.colors.overlay};
  border-radius: ${props => props.theme.borderRadius};
  z-index: 50;
  width: 400px;
  box-shadow: ${props => props.theme.shadows.m};
`

const ScrollContainer = styled.div`
  margin-top: 8px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
`

interface Props {
  toggle: () => void
}
const NotesMenu: React.FC<Props> = ({ toggle }) => {
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => {
    toggle()
  })

  const history = useHistory()
  const { createNewNote, openNote } = useRematchDispatch(
    (d: RootDispatch) => d.editor,
  )
  const handleNewNote = useCallback(() => {
    createNewNote(history)
    toggle()
  }, [createNewNote, history, toggle])
  const handleLoadNote = useCallback(
    (nid: string) => () => {
      openNote({ nid, history })
      toggle()
    },
    [openNote, history, toggle],
  )

  const noteMetadata = useSelector(selectNoteMetadata)
  return (
    <Container ref={ref}>
      <NewNoteButton onClick={handleNewNote}>New Note</NewNoteButton>
      <ScrollContainer>
        {noteMetadata.map(meta => (
          <NoteListItem
            key={meta.id}
            id={meta.id}
            title={meta.title}
            modified={meta.modified}
            onClick={handleLoadNote(meta.id)}
          />
        ))}
      </ScrollContainer>
    </Container>
  )
}

export default NotesMenu
