import { RootState, RootDispatch } from '../store'
import {
  User,
  selectStoredState,
  getUserData,
  getPublicNoteUid,
  getPublicNoteData,
  getNoteData,
  createNote,
  NoteMetadataMap,
  NoteMetadata,
} from '../common/storage'
import { selectNoteTitle } from './editor'
import { History } from 'history'

const initialState = {
  user: null as null | User,
  noteMetaById: {} as NoteMetadataMap,
  noteMetaIds: [] as string[],
}
type AuthState = typeof initialState

export const auth = {
  state: initialState,
  reducers: {
    setUser: (s: AuthState, p: User): AuthState => {
      s.user = p
      return s
    },
    setUserNotes: (s: AuthState, p: NoteMetadataMap): AuthState => {
      s.noteMetaById = p
      s.noteMetaIds = Object.keys(p).sort(
        (a, b) => p[b]?.modified - p[a]?.modified, // sort by most most recent first
      )
      return s
    },
    reset: (): AuthState => initialState,
  },
  effects: (d: RootDispatch) => ({
    async logout({ nid, history }: { nid: string | null; history: History }) {
      d.auth.reset()
      if (nid) {
        // console.log(`check if ${nid} is a public graph`)
        const uid = await getPublicNoteUid(nid)
        if (uid) {
          const publicNoteData = await getPublicNoteData(uid, nid)
          // console.log(publicNoteData)
          d.editor.loadGraphData(publicNoteData)
          d.editor.setTitle(publicNoteData.title)
          d.editor.setIsPublic(true)
          d.editor.setViewMode(true)
          d.editor.setNid(nid)
        } else {
          history.push('/')
        }
      }
    },
    async login(
      {
        nid,
        user,
        history,
      }: { nid: string | null; user: User; history: History },
      s: RootState,
    ) {
      d.auth.setUser(user)
      const { uid } = user
      const userData = await getUserData(uid)
      const currentData = selectStoredState(s)
      const currentTitle = selectNoteTitle(s)
      let activeNid = nid
      let viewMode = false
      let publicUid = null

      if (nid) {
        // check if this is our own graph
        const isOwnNote = Object.keys(userData.notes).includes(nid)
        if (isOwnNote) {
          const noteData = await getNoteData(uid, nid)
          const noteTitle = userData.notes[nid].title
          if (!noteTitle) throw new Error('expected note title to exist')
          d.editor.loadGraphData(noteData)
          d.editor.setTitle(noteTitle)
          history.push(`/note/${nid}`)
          // ⭐️ open and edit our own note
        } else {
          // otherwise check if it's a public note we can view
          publicUid = await getPublicNoteUid(nid)
          if (publicUid) {
            // view the public graph
            const publicNoteData = await getPublicNoteData(publicUid, nid)
            // console.log(publicNoteData)
            d.editor.loadGraphData(publicNoteData)
            d.editor.setTitle(publicNoteData.title)
            viewMode = true
            // ⭐️ open and view a public note
          } else {
            // we are at / or some invalid route
            const lastNid = userData.lastModifiedNote
            if (currentData.elements.length === 0 && lastNid) {
              // if there is a previous note to load
              // console.log(`load last modified note ${lastNid}`)
              const lastNoteTitle = userData.notes[lastNid].title
              if (!lastNoteTitle)
                throw new Error('expected last modified note title to exist')
              // fetch the note
              const noteData = await getNoteData(uid, lastNid)
              // load it in cytoscape
              d.editor.loadGraphData(noteData)
              d.editor.setTitle(lastNoteTitle)
              history.push(`/note/${lastNid}`)
              activeNid = lastNid
              // ⭐️ open an edit previous note
            } else {
              // otherwise make a new note
              // console.log(
              //   "make a new note because we don't have a last modified one or there is some data here",
              // )
              const newNid = await createNote(uid, currentTitle, currentData)
              history.push(`/note/${newNid}`)
              activeNid = newNid
              // ⭐️ open and edit a new note
            }
          }
        }
      } else {
        // we are at / or some invalid route
        const lastNid = userData.lastModifiedNote
        if (currentData.elements.length === 0 && lastNid) {
          // if there is a previous note to load
          // console.log(`load last modified note ${lastNid}`)
          const lastNoteTitle = userData.notes[lastNid].title
          if (!lastNoteTitle)
            throw new Error('expected last modified note title to exist')
          // fetch the note
          const noteData = await getNoteData(uid, lastNid)
          // load it in cytoscape
          d.editor.loadGraphData(noteData)
          d.editor.setTitle(lastNoteTitle)
          history.push(`/note/${lastNid}`)
          activeNid = lastNid
          // ⭐️ open an edit previous note
        } else {
          // otherwise make a new note
          // console.log(
          //   "make a new note because we don't have a last modified one or there is some data here",
          // )
          const newNid = await createNote(uid, currentTitle, currentData)
          history.push(`/note/${newNid}`)
          activeNid = newNid
          // ⭐️ open and edit a new note
        }
      }
      d.editor.setViewMode(viewMode)
      d.editor.setNid(activeNid)
      if (!activeNid) throw new Error('expected nid to exist')
      d.editor.setIsPublic((await getPublicNoteUid(activeNid)) !== null)
      d.auth.setUserNotes(userData.notes)
    },
  }),
}
export type AuthModel = typeof auth

export const selectLoggedIn = (s: RootState) => s.auth.user !== null
export const selectNoteMetadata = (s: RootState): NoteMetadata[] =>
  s.auth.noteMetaIds.map(id => {
    return {
      id,
      ...s.auth.noteMetaById[id],
    }
  })
