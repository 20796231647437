import styled from '../config/theme'

const NewNoteButton = styled.div`
  padding: 8px;
  user-select: none;
  font-weight: bold;
  cursor: pointer;
  border-radius: ${props => props.theme.borderRadius};
  color: ${props => props.theme.colors.primary};
  background: ${props => props.theme.colors.base};
  text-align: center;

  &:hover {
    color: ${props => props.theme.colors.base};
    background: ${props => props.theme.colors.primary};
  }
`
export default NewNoteButton
