import React, { useRef, memo } from 'react'
import { Theme } from '../../config/theme'
import { RootDispatch, RootState } from '../../store'
import { useDOMEffectFromState } from '../../hooks/useDOMEffectFromState'
import CommandBtn from './CommandBtn'
import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import { CommandName } from '../../types'
import { useSelector } from 'react-redux'

const COMMAND: CommandName = 'save'
const ANIMATION_CLASS = 'save-anim'

const SaveBtn: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const theme = useTheme<Theme>()
  useDOMEffectFromState(
    ref,
    (e: HTMLElement) => {
      e.classList.add(ANIMATION_CLASS)
      window.setTimeout(() => {
        e.classList.remove(ANIMATION_CLASS)
      }, 1100)
    },
    (s: RootState) => s.editor.shouldNotifySave,
    (d: RootDispatch) => d.editor.setShouldNotifySave,
  )
  const { dirty } = useSelector((s: RootState) => s.editor)
  return (
    <>
      <CommandBtn
        innerRef={ref}
        command={COMMAND}
        innerCss={css`
          &.${ANIMATION_CLASS} {
            animation: notify-save 1s forwards;
          }
          @keyframes notify-save {
            0% {
              color: ${theme.colors.primary};
              transform: scale(1);
            }
            25% {
              transform: scale(1.45);
            }
            35% {
              transform: scale(0.9);
            }
            45% {
              transform: scale(1.24);
            }
            55% {
              transform: scale(0.9);
            }
            65% {
              transform: scale(1.1);
            }
            75% {
              color: ${theme.colors.primary};
              transform: scale(1.03);
            }
            100% {
              transform: scale(1);
            }
          }
        `}
      />
      {dirty && (
        <div
          css={css`
            position: absolute;
            top: 4px;
            left: 4px;
            background: ${theme.colors.primary};
            width: 6px;
            height: 6px;
            border-radius: ${theme.borderRadius};
          `}
        />
      )}
    </>
  )
}

export default memo(SaveBtn)
