import { RootDispatch } from '../store'

const initialState = {
  doFocus: false,
  doBlur: false,
  ref: undefined as HTMLDivElement | undefined,
}
type TextEditorState = typeof initialState

export const textEditor = {
  state: initialState,
  reducers: {
    setDoFocus: (s: TextEditorState, p: boolean): TextEditorState => {
      s.doFocus = p
      return s
    },
    setDoBlur: (s: TextEditorState, p: boolean): TextEditorState => {
      s.doBlur = p
      return s
    },
    setRef: (s: TextEditorState, ref?: HTMLDivElement): TextEditorState => {
      s.ref = ref
      return s
    },
  },
  effects: (d: RootDispatch) => ({
    focus() {
      d.textEditor.setDoFocus(true)
    },
    blur() {
      d.textEditor.setDoBlur(true)
    },
  }),
}
export type TextEditorModel = typeof textEditor
