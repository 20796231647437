import cytoscape from 'cytoscape'
import { EleDataSnapshotMap } from './undoRedoCommands'
import { HAlign, InvalidCommandStateError } from '../types'
import { getUR } from '../common/graph'

let eles: cytoscape.CollectionReturnValue | undefined
let core: cytoscape.Core | undefined
let originalData: EleDataSnapshotMap | undefined

const getSelectedDataSnapshot = (
  eles: cytoscape.CollectionReturnValue,
): EleDataSnapshotMap => {
  return eles.reduce((acc, ele) => {
    const id = ele.id()
    acc[id] = {
      data: {
        textAlign: ele.data('textAlign'),
      },
    }
    return acc
  }, {} as EleDataSnapshotMap)
}

const start = (_core: cytoscape.Core) => {
  core = _core
  eles = core.$(':selected')
  originalData = getSelectedDataSnapshot(eles)
}

const set = (align: HAlign) => {
  if (!eles) throw new InvalidCommandStateError()
  eles.data('textAlign', align)
}

const end = () => {
  if (!core || !eles || !originalData) throw new InvalidCommandStateError()
  const ur = getUR(core)
  ur.do('set data', { firstTime: true, eles, lastState: originalData })
  core = undefined
  eles = undefined
  originalData = undefined
}

export { start, set, end }
