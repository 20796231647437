import { commandConfigs } from './config/commands'
import { contextConfigs } from './config/contexts'
import { RootDispatch } from './store'

export type ActionDispatcher = (d: RootDispatch) => void

export type SVGComponent = React.FC<React.SVGProps<SVGSVGElement>>

export type EdgeRelationship = 'target' | 'source'
export type LineStyle = 'normal' | 'dash' | 'thick'
export type LineEndShape = 'none' | 'circle' | 'square' | 'triangle'

export type VAlign = 'top' | 'bottom'
export type HAlign = 'left' | 'center' | 'right'
export type FontSize = 's' | 'm' | 'l' | 'xl'

export interface NodeStyle {
  nodeColor: string
  borderColor: string
  textColor: string
  textAlign: HAlign
  textSize: FontSize
  nodeWidth?: number
  nodeHeight?: number
}
export interface NodeData extends NodeStyle {
  id: string
  title: string
  width: number
  height: number
  imageWidth?: number
  imageHeight?: number
  url?: string
}
export interface GNNode {
  data: NodeData
  position: Point
}
export interface EdgeStyle {
  lineStyle: LineStyle
  targetShape: LineEndShape
  sourceShape: LineEndShape
  edgeColor: string
  textColor: string
  textSize: FontSize
  ctrlDist: string
  ctrlWeight: string
}
export interface EdgeData extends EdgeStyle {
  id: string
  title: string
  source: string
  target: string
  bend?: boolean
}
export interface GNEdge {
  data: EdgeData
}
export const isHAlign = (x: unknown): x is HAlign => {
  return typeof x === 'string' && ['left', 'center', 'right'].includes(x)
}
export const isFontSize = (x: unknown): x is FontSize => {
  return typeof x === 'string' && ['s', 'm', 'l', 'xl'].includes(x)
}
export const isNodeStyle = (data: unknown): data is NodeStyle =>
  typeof (data as NodeStyle).nodeColor === 'string' &&
  typeof (data as NodeStyle).borderColor === 'string' &&
  typeof (data as NodeStyle).textColor === 'string' &&
  isHAlign((data as NodeStyle).textAlign) &&
  isFontSize((data as NodeStyle).textSize)

export const isEdgeStyle = (data: unknown): data is EdgeStyle =>
  typeof (data as EdgeStyle).edgeColor === 'string' &&
  typeof (data as EdgeStyle).textColor === 'string' &&
  isFontSize((data as EdgeStyle).textSize)

//#region Command System
export interface CommandConfig {
  description?: string
  icon?: SVGComponent
  action: ActionDispatcher
}
export type CommandName = keyof typeof commandConfigs

export type KeyCombo = string
export type KeyEventType = 'keypress' | 'keydown' | 'keyup'
export interface KeyBinding {
  combo: KeyCombo
  type: KeyEventType
}

export interface CommandBinding {
  command: CommandName
  keyBinding?: KeyBinding
}

export enum SelectionType {
  NONE = 'NONE',
  NODE = 'NODE',
  EDGE = 'EDGE',
  NODES = 'NODES',
  EDGES = 'EDGES',
  MIXED = 'MIXED',
}

export type ContextVariantMap = {
  [type in SelectionType]?: CommandBinding[]
}

export interface ContextConfig {
  commands: CommandBinding[]
  variants: ContextVariantMap
}
export type ContextName = keyof typeof contextConfigs

export interface Command extends Pick<CommandConfig, 'description' | 'icon'> {
  name: CommandName
  keyBinding?: KeyBinding
  action: Function
}
//#endregion

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isNumber(x: any): boolean {
  return typeof x === 'number'
}

//#region Geometry
export interface Rect {
  x: number
  y: number
  w: number
  h: number
}
export type cyBoundingBox = cytoscape.BoundingBox12 & cytoscape.BoundingBoxWH

export type AnyRect = Rect | DOMRect | cyBoundingBox
export function isCyBB(x: AnyRect): x is cyBoundingBox {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return isNumber((x as any).x1)
}
export function isRect(x: AnyRect): x is Rect {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const a = x as any
  return isNumber(a.x) && isNumber(a.w)
}
export function isDOMRect(x: AnyRect): x is DOMRect {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const a = x as any
  return isNumber(a.x) && isNumber(a.width)
}

export interface Edges {
  top: number
  bottom: number
  left: number
  right: number
}

export interface Point {
  x: number
  y: number
}
//#endregion

//#region Errors
export class MissingCyCoreError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'MissingCyCoreError'

    Object.setPrototypeOf(this, MissingCyCoreError.prototype)
  }
}

export class MissingCyContainerError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'MissingCyContainerError'

    Object.setPrototypeOf(this, MissingCyContainerError.prototype)
  }
}

export class InvalidCommandStateError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'invalid command state'

    Object.setPrototypeOf(this, InvalidCommandStateError.prototype)
  }
}

export class AuthError extends Error {
  constructor(attemptedAction?: string) {
    const messageBase = 'You must be logged in'
    const message = attemptedAction
      ? `${messageBase} ${attemptedAction}`
      : messageBase
    super(message)
    this.name = 'AuthError'

    Object.setPrototypeOf(this, AuthError.prototype)
  }
}
//#endregion

//#region Selection
export interface SelectionUpdate {
  addNodes: string[]
  removeNodes: string[]
  addEdges: string[]
  removeEdges: string[]
}
//#endregion
