import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { RootDispatch } from '../store'

interface ClipData {
  type: 'CLIP'
  payload: string
}
interface BookmarkData {
  type: 'BOOKMARK'
  payload: {
    title: string
    url: string
  }
}

const isExtensionMessage = (e: MessageEvent): boolean => e.type === 'message'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isClipData = (data: any): data is ClipData => data.type === 'CLIP'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isBookmarkData = (data: any): data is BookmarkData =>
  data.type === 'BOOKMARK'

export const useExtension = () => {
  const dispatch = useDispatch<RootDispatch>()
  const handleMessage = useCallback(
    (e: MessageEvent) => {
      if (!isExtensionMessage(e)) return

      const { data } = e
      if (isClipData(data)) {
        dispatch.editor.clipNode(data.payload)
      } else if (isBookmarkData(data)) {
        dispatch.editor.bookmarkNode({
          text: data.payload.title,
          url: data.payload.url,
        })
      }
    },
    [dispatch],
  )

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [handleMessage])
}
