import { emptyRect, getCenter } from '../common/geometry'
import { Rect, Point } from '../types'
import { requireCore } from '../common/graph'
import { RootState } from '../store'

export const ZOOM_STEPS = [0.1, 0.25, 0.5, 1, 1.5, 2, 4, 8]
const getPrevZoomStep = (zoom: number) => {
  let i = 0
  for (; i < ZOOM_STEPS.length; i++) {
    if (ZOOM_STEPS[i] >= zoom) break
  }
  return ZOOM_STEPS[Math.max(0, i - 1)]
}
const getNextZoomStep = (zoom: number) => {
  let i = 0
  for (; i < ZOOM_STEPS.length; i++) {
    if (ZOOM_STEPS[i] > zoom) break
  }
  return ZOOM_STEPS[i]
}
export const formatZoom = (zoom: number) => `${Math.floor(zoom * 100)}%`

const initialState = {
  zoom: 1,
  pan: { x: 0, y: 0 } as Point,
  rect: emptyRect(),
}
type ViewportState = typeof initialState

export const viewport = {
  state: initialState,
  reducers: {
    setZoom: (s: ViewportState, p: number): ViewportState => {
      s.zoom = p
      return s
    },
    setPan: (s: ViewportState, p: Point): ViewportState => {
      s.pan = p
      return s
    },
    setRect: (s: ViewportState, p: Rect): ViewportState => {
      s.rect = p
      return s
    },
  },
  effects: () => ({
    async zoomIn(_: unknown, s: RootState) {
      const core = requireCore(s)
      core.zoom({
        level: getNextZoomStep(core.zoom()),
        position: getCenter(core.extent()),
      })
    },
    async zoomOut(_: unknown, s: RootState) {
      const core = requireCore(s)
      core.zoom({
        level: getPrevZoomStep(core.zoom()),
        position: getCenter(core.extent()),
      })
    },
    async resetZoom(_: unknown, s: RootState) {
      const core = requireCore(s)
      core.zoom({
        level: 1,
        position: getCenter(core.extent()),
      })
    },
    async zoomFit(_: unknown, s: RootState) {
      const core = requireCore(s)
      const selected = core.$(':selected')
      if (selected.length > 0) {
        core.fit(selected)
      } else {
        core.fit()
      }
    },
  }),
}
export type ViewportModel = typeof viewport
