import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

export const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyDlb7DFzLkUuhkfXV9esGo_wMI5MBQS-Ps',
  authDomain: 'graphnote-0.firebaseapp.com',
  databaseURL: 'https://graphnote-0.firebaseio.com',
  projectId: 'graphnote-0',
  storageBucket: 'graphnote-0.appspot.com',
  messagingSenderId: '234112134863',
})

export const authProvider = new firebase.auth.GoogleAuthProvider()

export const fireStorage = firebase.storage()
