import styled from '../config/theme'

const TitleButton = styled.div`
  background: transparent;
  cursor: pointer;
  user-select: none;
  padding: 4px 8px;
  border-radius: ${props => props.theme.borderRadius};

  &:hover {
    color: white;
    background: ${props => props.theme.colors.highlight};
  }
`
export default TitleButton
